import React from "react";
import { Button, Flex, useToast, Box } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";
import SelectComponent from "../../UI/Select";
import { useState } from "react";
import Modal from "../../overlay/Modal";
import Overlay from "../../overlay/OverLay";
import ReusableText from "../../UI/ReusableText";
import { AiOutlineClose } from "react-icons/ai";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import {
  listDepartments,
  listDesignations,
  updateEmployee,
} from "../../../util/http";
import { useEffect } from "react";

export default function EditEmployee({ show, employee }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [firstName, setFirstName] = useState(employee?.first_name);
  const [lastName, setLastName] = useState(employee?.last_name);
  const [email, setEmail] = useState(employee?.email);
  const [phone, setPhone] = useState(employee?.phone_number);
  const [department, setDepartment] = useState(employee?.department);
  const [designation, setDesignation] = useState(employee?.designation);
  const [secondaryDepartments, setSecondaryDepartments] = useState([]);
  const [role, setRole] = useState(employee?.job_role);
  const [address, setAddress] = useState(employee?.address);
  const [city, setCity] = useState(employee?.city);
  const [state, setState] = useState(employee?.state);
  const [code, setCode] = useState(employee?.postal_code);
  const [country, setCountry] = useState(employee?.country);
  const [kinFirstName, setKinFirstName] = useState(
    employee?.contact_first_name || ""
  );
  const [kinLastName, setKinLastName] = useState(
    employee?.contact_last_name || ""
  );
  const [kinCity, setKinCity] = useState(employee?.contact_city || "");
  const [kinCountry, setKinCountry] = useState(employee?.contact_country || "");
  const [kinState, setKinState] = useState(employee?.contact_state || "");
  const [kinCode, setKinCode] = useState(employee?.contact_code || "");
  const [kinAddress1, setKinAddress1] = useState(
    employee?.contact_address1 || ""
  );
  const [kinAddress2, setKinAddress2] = useState(
    employee?.contact_address2 || ""
  );
  const [kinPhone, setKinPhone] = useState(employee?.contact_phone || "");
  const [kinEmail, setKinEmail] = useState(employee?.contact_email || "");
  const [leave, setLeave] = useState(employee?.leave_count || "");

  const [dept, setDept] = useState([]);
  const [desig, setDesig] = useState([]);

  const { user } = useContext(AuthContext);

  const secondaryDept =
    employee?.secondary_department &&
    JSON.parse(employee?.secondary_department);

  const adminRoles = [
    "seed",
    "hr",
    "accountant",
    "hod",
    "hop",
    "lawyer",
    "user",
  ].map((a) => a.charAt(0).toUpperCase() + a.slice(1));
  const hrRoles = ["hod", "hop", "lawyer", "user"].map(
    (a) => a.charAt(0).toUpperCase() + a.slice(1)
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDepartments();
        setDept(
          response?.departments?.map(
            (el) =>
              el?.department?.charAt(0).toUpperCase() +
              el?.department?.toLowerCase().slice(1)
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDesignations();
        setDesig(
          response?.designations?.map(
            (el) =>
              el?.designation?.charAt(0).toUpperCase() +
              el?.designation?.toLowerCase().slice(1)
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function editHandler() {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phone,
      department,
      designation,
      secondary_department: JSON.stringify(secondaryDepartments),
      job_role: role,
      city,
      address,
      state,
      country,
      postal_code: code,
      contact_email: kinEmail,
      contact_first_name: kinFirstName,
      contact_last_name: kinLastName,
      contact_phone: kinPhone,
      contact_country: kinCountry,
      contact_city: kinCity,
      contact_state: kinState,
      contact_postal_code: kinCode,
      contact_address1: kinAddress1,
      contact_address2: kinAddress2,
      leave_count: leave,
    };

    try {
      setLoading(true);
      await updateEmployee(employee?.id, data);
      show(false);
      toast({
        title: `Successfully updated ${employee?.first_name}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      show(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={() => {}} />
      <Modal hide={() => show(false)} title="Edit Employee">
        <Flex align="start" w="100%" direction="column" gap="1rem" py="2rem">
          <Box
            onClick={() => show(false)}
            position="absolute"
            top="0"
            right="0"
            p="1rem"
          >
            <AiOutlineClose fontSize={24} color="red" cursor="pointer" />
          </Box>
          <ReusableText
            text={`Edit ${employee?.first_name} details`}
            size={24}
            color="#333"
            weight={600}
            align="center"
          />
          <ReusableText
            text="Personal Details"
            size={20}
            color="#333"
            weight={500}
          />
          <hr style={{ width: "100%", height: "2px", bg: "#ccc" }} />
          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            gap="1rem"
            w="100%"
          >
            <InputComponent
              label="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter First Name"
              value={firstName}
            />
            <InputComponent
              label="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter First Name"
              value={lastName}
            />
          </Flex>

          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            gap="1rem"
            w="100%"
          >
            <Flex align="start" w="100%" direction="column">
              <SelectComponent
                label="Department"
                onChange={(e) => setDepartment(e.value)}
                options={dept || []}
              />

              <ReusableText
                text={`Employee Department: ${employee?.department}`}
                align="start"
                size="10px"
                weight="medium"
                color="#333"
              />
            </Flex>

            <Flex align="start" w="100%" direction="column">
              <SelectComponent
                label="Role"
                onChange={(e) => setRole(e.value)}
                options={
                  user.job_role?.toLowerCase() === "hr" ? hrRoles : adminRoles
                }
              />
              <ReusableText
                text={`Employee Role: ${employee?.job_role}`}
                align="start"
                size="10px"
                weight="medium"
                color="#333"
              />
            </Flex>
          </Flex>

          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            gap="1rem"
            w="100%"
          >
            <Flex align="start" w="100%" direction="column">
              <SelectComponent
                label="Secondary Departments"
                onChange={(e) => {
                  const depts = e?.map((el) => el.value);
                  setSecondaryDepartments(depts);
                }}
                options={dept || []}
                multiple={true}
              />
              <Flex
                w="100%"
                align="center"
                direction={{ lg: "row", md: "row", base: "column" }}
                gap="8px"
                wrap="wrap"
                maxHeight="100px"
                overflow="scroll"
              >
                <ReusableText
                  text={`Secondary Departments: `}
                  align="start"
                  size="10px"
                  weight="medium"
                  color="#333"
                />

                {secondaryDept?.map((el) => (
                  <ReusableText
                    text={el}
                    align="start"
                    size="10px"
                    weight="medium"
                    color="#333"
                  />
                ))}
              </Flex>
            </Flex>

            <Flex align="start" w="100%" direction="column">
              <SelectComponent
                label="Designation"
                onChange={(e) => setDesignation(e.value)}
                options={desig || []}
              />
              <ReusableText
                text={`Employee Designation: ${employee?.designation}`}
                align="start"
                size="10px"
                weight="medium"
                color="#333"
              />
            </Flex>
          </Flex>

          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            gap="1rem"
            w="100%"
          >
            <InputComponent
              label="Phone Number"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter Phone Number"
              value={phone}
            />
            <InputComponent
              placeholder="Enter email address"
              label="Email"
              type="email"
              readOnly={true}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Flex>

          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            gap="1rem"
            w="100%"
          >
            <InputComponent
              label="Postal Code"
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter Postal Code"
              value={code}
            />
            <InputComponent
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Enter Country"
              label="Country"
              type="text"
            />
          </Flex>

          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            gap="1rem"
            w="100%"
          >
            <InputComponent
              onChange={(e) => setCity(e.target.value)}
              label="City"
              placeholder="Enter City"
              value={city}
            />
            <InputComponent
              label="State"
              onChange={(e) => setState(e.target.value)}
              placeholder="Enter State"
              value={state}
            />
          </Flex>
          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            gap="1rem"
            w="100%"
          >
            <InputComponent
              onChange={(e) => setLeave(e.target.value)}
              label="Leave"
              placeholder="Enter Leave"
              value={leave}
            />
            <InputComponent
              label="Address"
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter Address"
              value={address}
            />
          </Flex>
          <ReusableText
            text="Next of Kin"
            size={20}
            color="#333"
            weight={500}
          />
          <hr style={{ width: "100%", height: "2px", bg: "#ccc" }} />
          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            w="100%"
            justify="center"
            gap="1rem"
          >
            <InputComponent
              placeholder="Enter first name"
              label="First Name"
              type="text"
              onChange={(e) => setKinFirstName(e.target.value)}
              value={kinFirstName}
            />
            <InputComponent
              label="Last Name"
              placeholder="Enter last name"
              type="text"
              onChange={(e) => setKinLastName(e.target.value)}
              value={kinLastName}
            />
          </Flex>
          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            w="100%"
            justify="center"
            gap="1rem"
          >
            <InputComponent
              placeholder="Enter email address"
              label="Email"
              type="email"
              onChange={(e) => setKinEmail(e.target.value)}
              value={kinEmail}
            />

            <InputComponent
              placeholder="Enter phone number"
              label="Phone Number"
              type="number"
              onChange={(e) => setKinPhone(e.target.value)}
              value={kinPhone}
            />
          </Flex>
          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            w="100%"
            justify="center"
            gap="1rem"
          >
            <InputComponent
              placeholder="Enter address"
              label="Address Line 1"
              type="text"
              onChange={(e) => setKinAddress1(e.target.value)}
              value={kinAddress1}
            />
            <InputComponent
              label="Address Line 2"
              placeholder="Enter address"
              type="text"
              onChange={(e) => setKinAddress2(e.target.value)}
              value={kinAddress2}
            />
          </Flex>
          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            w="100%"
            justify="center"
            gap="1rem"
          >
            <InputComponent
              placeholder="Enter City"
              onChange={(e) => setKinCity(e.target.value)}
              value={kinCity}
              label="City"
              type="text"
            />
            <InputComponent
              placeholder="Enter Country"
              onChange={(e) => setKinCountry(e.target.value)}
              value={kinCountry}
              label="Country"
              type="text"
            />
          </Flex>

          <Flex
            align="center"
            direction={{ lg: "row", md: "row", base: "column" }}
            w="100%"
            justify="center"
            gap="1rem"
          >
            <InputComponent
              placeholder="Enter State"
              onChange={(e) => setKinState(e.target.value)}
              value={kinState}
              label="State"
              type="text"
            />
            <InputComponent
              placeholder="Enter Postal Code"
              label="Postal Code"
              type="text"
              onChange={(e) => setKinCode(e.target.value)}
              value={kinCode}
            />
          </Flex>

          <Flex align="center" mt="1rem" justify="space-between" w="100%">
            <Button
              variant="ghost"
              colorScheme="red"
              onClick={() => show(false)}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              bg="brand.900"
              color="white"
              isLoading={loading}
              loadingText="Processing"
              onClick={editHandler}
            >
              Save Update
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
