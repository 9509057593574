import { Box, Flex } from "@chakra-ui/react";
import ImageComponent from "./Image";

import authImage from "../images/auth-image.png";

export default function AuthComponent({ children }) {
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Box display={{ lg: "block", md: "none", base: "none" }} w="50%">
        <ImageComponent
          src={authImage}
          alt="authentication page"
          height="100vh"
        />
      </Box>
      <Flex
        align="center"
        justify="center"
        w={{ lg: "50%", md: "100%", base: "100%" }}
        height="100vh"
        bg="#F4F5F7"
      >
        <Flex
          align="center"
          bg="white"
          borderRadius="0.75rem"
          p={{ lg: "2rem", md: "1rem", base: "16px" }}
          direction="column"
          gap="1.5rem"
          w={{ lg: "60%", md: "70%", base: "90%" }}
          mx="auto"
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}
