import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import InputComponent from "../UI/Input";
import { useState } from "react";

export default function CreateLeaveType({ show }) {
  const [type, setType] = useState("");
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function leaveHandler() {
    const data = {
      leave_type: type,
      duration,
    };
    if (!type || !duration) {
      toast({
        title: `Details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      toast({
        title: `Leave type created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  function closeModal() {
    show(false);
  }
  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            size="md"
            color="#333"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Add Leave Type
          </Heading>
          <CloseButton onClick={closeModal} colorScheme="red" />
        </Flex>
        <InputComponent
          onChange={(e) => setType(e.target.value)}
          label="Leave Type"
          placeholder="Enter leave type"
        />
        <InputComponent
          onChange={(e) => setDuration(e.target.value)}
          label="Duration"
          placeholder="Enter leave duration"
          type="number"
        />
        <Button
          isLoading={loading}
          loadingText="Creatingg"
          onClick={leaveHandler}
          bg="brand.900"
          color="#fff"
          size="md"
          w="100%"
        >
          Create
        </Button>
      </Flex>
    </Modal>
  );
}
