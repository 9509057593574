import { Flex, useToast } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import { useParams } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import ViewTab from "./ViewTab";
import { Fragment, useEffect, useState } from "react";
import { addCommentBrief, viewBrief } from "../../../util/http";
import Loader from "../../UI/Loader";
import EditBrief from "./EditBrief";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import { getStoredItem } from "../../../util/lib";
import AddTags from "./AddTags";

export default function ViewBriefContainer() {
  const params = useParams();
  const { user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const [type, setType] = useState("update");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [brief, setBrief] = useState("");
  const [progress, setProgress] = useState(0);
  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        setLoading(true);
        const response = await viewBrief(params.id);
        setLoading(false);
        setProgress(80);
        setProgress(100);
        setBrief(response.brief);
      } catch (error) {
        setLoading(false);
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, [params]);

  async function reopenBrief() {
    try {
      // setPublishLoading(true);
      await addCommentBrief({ status: "active" }, brief?.brief_id);
      window.location.reload();
      // setPublishLoading(false);
      toast({
        title: `Brief re-opened`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function completeBrief() {
    try {
      setPublishLoading(true);
      await addCommentBrief({ status: "completed" }, brief?.brief_id);
      setPublishLoading(false);
      toast({
        title: `Brief Completed`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setPublishLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function publishBrief() {
    try {
      setPublishLoading(true);
      await addCommentBrief({ status: "pending" }, brief?.brief_id);
      window.location.reload();
      setPublishLoading(false);
      toast({
        title: `Brief Published`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setPublishLoading(false);

      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  function openUpdate() {
    setShow(true);
  }

  function openAdd() {
    setShow1(true);
  }

  return (
    <Fragment>
      {show1 && <AddTags show={setShow1} />}
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        align="start"
        direction="column"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title={brief?.brief_id}
          text="Manage your brief and their details here"
          btnText="Edit Brief"
          showDottedMenu={true}
          menu={[
            {
              name: "Add tags",
              icon: "",
              navigation: false,
              onClick: openAdd,
            },
            {
              name: "Update Current brief tag",
              icon: "",
              navigation: false,
              onClick: openUpdate,
            },
          ]}
          icon={<FiEdit2 />}
          show={
            user?.job_role?.toLowerCase() !== "lawyer" &&
            user?.job_role?.toLowerCase() !== "hr" &&
            true
          }
          onClick={() => {
            setType("update");
            setShowModal(true);
          }}
          showPublish={
            userType?.toLowerCase() !== "client" &&
            user?.job_role?.toLowerCase() !== "lawyer" &&
            user?.job_role?.toLowerCase() !== "hr"
          }
          btnTextPublish={
            brief?.status?.toLowerCase() === "draft"
              ? "Publish"
              : brief?.status?.toLowerCase() === "completed"
              ? "Re-open"
              : "Mark As Completed"
          }
          onClickPublish={
            brief?.status?.toLowerCase() === "draft"
              ? publishBrief
              : brief?.status?.toLowerCase() === "completed"
              ? reopenBrief
              : completeBrief
          }
        />
        <ViewTab
          show={show}
          setShow={setShow}
          brief={brief}
          loading={loading}
        />
      </Flex>
      {showModal && (
        <EditBrief editType={type} show={setShowModal} brief={brief} />
      )}
    </Fragment>
  );
}
