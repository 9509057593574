import { Flex } from "@chakra-ui/react";
import ReusableText from "./ReusableText";
import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";

export default function ReusableSupportingDocuments({ docs }) {
  return (
    <Flex w="100%" direction="column" gap="0.5rem">
      <ReusableText
        text="Supporting documents"
        color="#7F7E7E"
        size="14px"
        weight="medium"
      />
      {docs?.map((el) => (
        <Flex
          border="1px solid #364273"
          borderRadius="0.5rem"
          align="center"
          justify="space-between"
          p="0.4rem"
        >
          <Flex align="center" gap="1rem">
            <Flex
              align="center"
              p="1rem"
              borderRadius="100%"
              bg="rgba(238, 239, 243, 0.4)"
              justify="center"
            >
              <AiOutlineCopy color="#283156" />
            </Flex>
            <Flex align="start" direction="column" gap="0.5rem">
              <ReusableText
                text={el.file_name}
                color="#515050"
                size="14px"
                weight="medium"
              />
              <ReusableText
                text="100% uploaded"
                color="#7F7E7E"
                size="normal"
              />
            </Flex>
          </Flex>
          <AiOutlineDelete color="#696869" />
        </Flex>
      ))}
    </Flex>
  );
}
