import React from "react";
import Leave from "../../leave/Leave";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import { getStoredItem } from "../../../util/lib";
import Dashboard from "../../dashboard/Dashboard";

export default function LeavePage() {
  let view;
  const { isAuthenticated } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  if (userType === "employee") {
    view = <Leave />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated ? view : <Login />}</>;
}
