import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReusableText from "../../../UI/ReusableText";
import AllInvoices from "./AllInvoices";
import PaidInvoices from "./PaidInvoices";
import PartPaymentInvoices from "./PartPaymentInvoices";
import PendingPaymentInvoices from "./PendingPaymentInvoices";

export default function Invoices({ invoice }) {
  const [completed, setCompleted] = useState([]);
  const [pending, setPending] = useState([]);
  const [ongoing, setOngoing] = useState([]);

  useEffect(() => {
    const completedInvoices = invoice?.filter(
      (invoice) => invoice.status?.toLowerCase() === "paid"
    );
    const pendingInvoices = invoice?.filter(
      (invoice) => invoice.status?.toLowerCase() === "pending"
    );
    const ongoingInvoices = invoice?.filter(
      (invoice) => invoice.status?.toLowerCase() === "partial"
    );
    setCompleted(completedInvoices);
    setOngoing(ongoingInvoices);
    setPending(pendingInvoices);
  }, [invoice]);
  return (
    <Flex id="btn" w="100%" direction="column" gap="1rem">
      <ReusableText
        text="Invoices"
        size="18px"
        color="#101828"
        weight="medium"
      />
      <Tabs variant="solid-rounded" colorScheme="blue">
        <TabList borderBottom="1px solid #ccc" pb="1rem">
          <Tab>All</Tab>
          <Tab>Paid</Tab>
          <Tab>Part Payment</Tab>
          <Tab>Pending Payment</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AllInvoices invoice={invoice} />
          </TabPanel>
          <TabPanel>
            <PaidInvoices invoice={completed} />
          </TabPanel>
          <TabPanel>
            <PartPaymentInvoices invoice={ongoing} />
          </TabPanel>
          <TabPanel>
            <PendingPaymentInvoices invoice={pending} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
