import { Flex } from "@chakra-ui/react";
import ReusableText from "../../../UI/ReusableText";
import { Link } from "react-router-dom";
import LeaveTitle from "../../../leave/LeaveTitle";
import LeaveList from "../../../leave/LeaveList";

export default function RecentLeave({ list }) {
  return (
    <Flex w="100%" direction="column">
      <Flex py="1rem" align="center" w="100%" justify="space-between">
        <ReusableText
          text="Recent Leave(s)"
          color="#3F434A"
          size="18px"
          weight="medium"
        />
        <Link to="/leave" style={{}}>
          View all {">"}
        </Link>
      </Flex>
      <LeaveTitle />
      <LeaveList list={list?.slice(0, 7)} />
    </Flex>
  );
}
