import { Box, Flex } from "@chakra-ui/react";
import EmployeeDetails from "./EmployeeDetail";
import EmployeeAddress from "./EmployeeAddress";
import EmployeeContactPerson from "./EmployeeContactPerson";
import Updates from "../../profile/updates/Updates";
import ReusableText from "../../UI/ReusableText";

export default function ViewEmployeeDetails({ employee }) {
  return (
    <Flex
      align="center"
      height={{ lg: "80vh", md: "80vh", base: "100%" }}
      w="100%"
      p="1rem"
      direction="column"
    >
      <EmployeeDetails employee={employee} />
      <EmployeeAddress employee={employee} />
      <EmployeeContactPerson employee={employee} />
      <Flex
        align="center"
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
        direction={{ lg: "row", md: "column", base: "column" }}
        gap={{ lg: "0rem", md: "8px", base: "8px" }}
      >
        <Flex
          align="center"
          w={{ lg: "30%", md: "100%", base: "100%" }}
          height={{ lg: "400px", md: "100%", base: "100%" }}
          pt="2rem"
        >
          <ReusableText
            text="Employee Updates"
            size={{ lg: "20px", md: "18px", base: "15px" }}
            weight="600"
            color="#19191A"
          />
        </Flex>
        <Flex w="100%">
          <Updates
            showBtn={false}
            id={employee?.id}
            department={employee?.department}
            designation={employee?.designation}
            name={`${employee?.first_name} ${employee?.last_name}`}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
