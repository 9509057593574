import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import SelectComponent from "../../UI/Select";
import { useState } from "react";

export default function UpdateTag({ show }) {
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  function closeModal() {
    show(false);
  }

  async function updateHandler() {
    try {
      setLoading(true);

      window.location.reload();
      toast({
        title: `Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="3rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            size="md"
            color="#333"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Update Status
          </Heading>
          <CloseButton onClick={closeModal} colorScheme="red" />
        </Flex>
        <SelectComponent
          label="Select Tag"
          options={[
            "Undergoing preliminary legal analysis",
            "Awaiting financial assessment",
            "Awaiting payment from client",
            "Awaiting documents/ information from client",
            "completed",
            "adjourned",
            "Pre-trial stage",
            "Trial stage",
            "Awaiting Judgement",
          ]}
        />
        <Button
          isLoading={loading}
          loadingText="Submitting"
          onClick={updateHandler}
          bg="brand.900"
          color="#fff"
          size="md"
          w="100%"
        >
          Save Update
        </Button>
      </Flex>
    </Modal>
  );
}
