import { Flex } from "@chakra-ui/react";
import Loader from "../../../UI/Loader";
import StatItem from "../../StatItem";
import { useState } from "react";
import { FaTasks } from "react-icons/fa";
import { TfiBriefcase } from "react-icons/tfi";
import { MdRequestPage } from "react-icons/md";
import KPI from "./KPI";

export default function LawyerStat({
  activeBrief,
  completedBrief,
  ongoingTask,
  overDueTask,
  pendingRequisition,
}) {
  const [progress, setProgress] = useState(0);

  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        wrap="wrap"
        gap="1.5rem"
        align="center"
        justify="space-between"
        py="3rem"
        px="1rem"
        borderRadius="1rem"
        bg="#F7F7F7"
      >
        <StatItem
          icon={<FaTasks color="red" fontSize={24} />}
          title="Overdue Tasks"
          amount={overDueTask}
          bg="red.100"
        />
        <StatItem
          icon={<FaTasks color="orange" fontSize={24} />}
          title="Ongoing Tasks"
          amount={ongoingTask}
          bg="rgba(255, 213, 128, 0.4)"
        />
        <StatItem
          icon={<TfiBriefcase color="orange" fontSize={24} />}
          title="Active Briefs"
          amount={activeBrief}
          bg="rgba(255, 213, 128, 0.4)"
        />
        <StatItem
          icon={<TfiBriefcase color="green" fontSize={24} />}
          bg="rgba(144, 238, 144, 0.4)"
          title="Completed Briefs"
          amount={completedBrief}
        />
        <StatItem
          title="Pending Requisitions"
          icon={<MdRequestPage color="#333" fontSize={24} />}
          amount={pendingRequisition}
          bg="rgba(211, 211, 211, 0.4)"
        />
        <KPI />
      </Flex>
    </>
  );
}
