import { Box, Divider, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import ReusableText from "./ReusableText";
import ImageComponent from "./Image";

export default function PayslipTemplate({
  name,
  designation,
  department,
  amount,
  date,
}) {
  return (
    <Flex
      w="100%"
      position="relative"
      zIndex={80}
      align="start"
      direction="column"
      p="1rem"
    >
      <Box w="50px">
        <ImageComponent src="/logo.png" alt="logo" />
      </Box>
      <Flex w="100%" py="1rem" align="center" direction="column" gap="8px">
        <Heading size="md" color="#333">
          PAYSLIP
        </Heading>
        <ReusableText text="LOYA Inc" color="#333" size="15px" />
      </Flex>
      <Divider />
      <Flex w="100%" align="center" justify="space-between" wrap="wrap">
        <Flex w="45%" py="8px" align="center" justify="space-between">
          <ReusableText text="Date:" weight="bold" color="#333" size="16px" />
          <ReusableText
            text={new Date().toLocaleDateString()}
            color="#333"
            size="15px"
          />
        </Flex>
        <Flex w="45%" py="8px" align="center" justify="space-between">
          <ReusableText
            text="Employee Name:"
            weight="bold"
            color="#333"
            size="16px"
          />
          <ReusableText text={name} transform={true} color="#333" size="15px" />
        </Flex>

        <Flex w="45%" py="8px" align="center" justify="space-between">
          <ReusableText
            text="Pay Period:"
            weight="bold"
            color="#333"
            size="16px"
          />
          <ReusableText text={date} color="#333" size="15px" />
        </Flex>

        <Flex w="45%" py="8px" align="center" justify="space-between">
          <ReusableText
            text="Designation:"
            weight="bold"
            color="#333"
            size="16px"
          />
          <ReusableText text={designation} color="#333" size="15px" />
        </Flex>

        {false && (
          <Flex w="45%" py="8px" align="center" justify="space-between">
            <ReusableText
              text="Work Days:"
              weight="bold"
              color="#333"
              size="16px"
            />
            <ReusableText text="30" color="#333" size="15px" />
          </Flex>
        )}

        {false && (
          <Flex w="45%" py="8px" align="center" justify="space-between">
            <ReusableText
              text="Department:"
              weight="bold"
              color="#333"
              size="16px"
            />
            <ReusableText text={department} color="#333" size="15px" />
          </Flex>
        )}
      </Flex>
      <Divider />
      <Flex w="100%" py="16px" align="center" justify="space-between">
        <ReusableText text="Amount:" weight="bold" color="#333" size="20px" />
        <ReusableText text={amount} color="#333" size="18px" />
      </Flex>
      <Divider />
      <Flex w="100%" py="1rem" align="center" justify="center">
        <ReusableText
          text="This is a system generated slip"
          color="#333"
          size="18px"
          align="center"
        />
      </Flex>
    </Flex>
  );
}
