import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function JobKnowledge({ setJobKnowledge }) {
  const [response, setResponse] = useState({
    exhibitJob: 0,
    minimalSupervision: 0,
    regularReport: 0,
    recognize: 0,
    usesAll: 0,
    effectiveUse: 0,
    reduceWaste: 0,
    sharesInfo: 0,
    takesPride: 0,
  });

  useEffect(() => {
    setJobKnowledge(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="490px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="JOB KNOWLEDGE & PRODUCTIVITY (10%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="490px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="490px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, exhibitJob: e.target.value })
            }
            title="Exhibits job-relevant knowledge and skill needed to 
          perform the duties and requirements of the position"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, minimalSupervision: e.target.value })
            }
            title="Minimal supervision, determines appropriate course of action and takes same"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, regularReport: e.target.value })
            }
            title="Regularly reports work progress and problems"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, recognize: e.target.value })
            }
            title="Recognizes when circumstances dictate a change is necessary"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, usesAll: e.target.value })
            }
            title="Uses all available tools and software to ensure 
            seamless and faster results"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, effectiveUse: e.target.value })
            }
            title="Effective use of time, materials, and equipment"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, reduceWaste: e.target.value })
            }
            title="Works to reduce waste by identifying ways to be more 
          efficient with time, money, or processes"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, sharesInfo: e.target.value })
            }
            title="Shares information and knowledge with others"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, takesPride: e.target.value })
            }
            title="Takes pride in work, strives for excellence"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
