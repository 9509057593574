import { Button, Flex, layout, useToast, Badge } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import InputComponent from "../../UI/Input";
import SelectComponent from "../../UI/Select";
import TextareaComponent from "../../UI/Textarea";
import FileComponent from "../../UI/File";
import BriefInvoice from "./BriefInvoice";
import BriefInvoiceTitle from "./BriefInvoiceTitle";
import CreateInvoice from "./CreateInvoice";
import { AddIcon } from "@chakra-ui/icons";
import { useEffect, useState, useContext } from "react";
import InvoiceStat from "./InvoiceStat";
import PreviewDocument from "./PreviewDocument";
import { useNavigate } from "react-router-dom";
import {
  createBrief,
  getClient,
  getClients,
  getEmployees,
  getVAT,
  listDepartments,
} from "../../../util/http";
import ReusableRetainership from "../../UI/ReusableRetainership";
import { AuthContext } from "../../../util/context";

export default function BriefDetails() {
  const [inp, setInp] = useState([1]);
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [type, setType] = useState("");
  const [lawyer, setLawyer] = useState([]);
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [max, setMax] = useState("");
  const [empDetail, setEmpDetail] = useState([]);
  const [department, setDepartment] = useState("");
  const [teamLead, setTeamLead] = useState("");

  const [amount, setAmount] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [clientNames, setClientName] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [empNames, setEmpNames] = useState([]);
  const [retainership, setRetainership] = useState("");
  const [vat, setVat] = useState([]);
  const [vatVal, setVatVal] = useState(0);
  const [dept, setDept] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      const secDepts =
        user?.secondary_department && JSON.parse(user?.secondary_department);
      const deptArr = [user?.department, ...secDepts];
      if (user?.job_role?.toLowerCase() !== "seed") {
        setDept(() =>
          deptArr?.filter((item, index) => deptArr.indexOf(item) === index)
        );
      } else {
        try {
          const response = await listDepartments();
          setDept(
            response?.departments?.map(
              (el) =>
                el?.department?.charAt(0).toUpperCase() +
                el?.department?.toLowerCase().slice(1)
            )
          );
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchData();
  }, [user?.department, user?.job_role, user?.secondary_department]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVAT();

        setVatVal(+response?.vat);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getClients();
        const employeeResponse = await getEmployees();
        const employeeObj = employeeResponse?.employees
          ?.filter((el) => {
            const canAssign =
              el?.job_role?.toLowerCase() === "lawyer" ||
              el?.job_role?.toLowerCase() === "hod" ||
              el?.job_role?.toLowerCase() === "hop";
            return el.deleted_at === null && canAssign;
          })
          .map((el) => {
            return { name: `${el.first_name} ${el.last_name}`, id: el.id };
          });

        const employeeNames = employeeObj?.map((el) => el.name);

        const newClients = response?.clients
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return { name: el.client_name, id: el.client_id };
          });

        const names = newClients.map((el) => el.name);

        setEmpDetail(employeeObj);
        setAllClient(newClients);
        setEmpNames(employeeNames);
        setClientName(names);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const client_id = allClient?.find((el) => el.name == client)?.id;
      if (!client) {
        return;
      }
      try {
        const response = await getClient(client_id);
        setRetainership(response?.client[0].retainership);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [client]);

  async function createBriefHandler(e) {
    e.preventDefault();
    const client_id = allClient?.find((el) => el.name == client)?.id;

    const lawyer_id = lawyer.map((el, i) => {
      let arr = [];
      for (let key in empDetail) {
        if (empDetail[key]?.name == el) {
          arr.push(empDetail[key].id || "");
        }
      }
      return arr;
    });

    const data = {
      brief_title: title,
      client,
      client_id,
      brief_type: type,
      lawyer: JSON.stringify(lawyer),
      lawyer_id: JSON.stringify(lawyer_id?.flatMap((el) => el?.toString())),
      team_lead: teamLead,
      description,
      "documents[]": files,
      invoice: JSON.stringify(invoices),
      sub_total: subTotal,
      grand_total: totalAmount,
      maximum_spend: 20,
      invoice_type: retainership ? "retainer" : "invoice",
    };

    if (!type || !title || !client) {
      toast({
        title: "Missing brief details",
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createBrief(data);
      setLoading(false);
      navigate("/brief");
      toast({
        title: "Brief Created",
        description: `You have successfully created “${title}” brief`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      // toast({
      //   title: `Error`,
      //   description: `${
      //     error?.response?.data.message || "something went wrong"
      //   }`,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  const toast = useToast();
  const navigate = useNavigate();

  function handleAdd() {
    setInp((prev) => [...prev, []]);
  }
  function addDescription(e, i) {
    const inpData = [...inp];
    inpData[i] = e.target.value;
    setInp(inpData);

    const newArr = inpData.map((el, i) => {
      return {
        description: el,
        amount: amount[i],
        vat: vat[i],
      };
    });
    setInvoices(newArr);
  }

  function addAmount(e, i) {
    const inpData = [...amount];
    inpData[i] = +e.target.rawValue;
    setAmount(inpData);

    const newArr = inp.map((el, i) => {
      return {
        description: el,
        amount: inpData[i],
        vat: vat[i],
      };
    });
    setInvoices(newArr);
  }

  function addInput() {
    const newArr = inp.map((el, i) => {
      return {
        description: el,
        amount: amount[i],
        vat: vat[i] ? vat[i] : false,
      };
    });
    setInvoices(newArr);
  }

  function addVAT(e, i) {
    const inpData = [...vat];
    inpData[i] = e.target.checked;
    setVat(inpData);

    const newArr = inp.map((el, i) => {
      return {
        description: el,
        amount: amount[i],
        vat: inpData[i] ? inpData[i] : false,
      };
    });
    setInvoices(newArr);
  }

  useEffect(() => {
    const formattedAmount = invoices
      ?.map((el) => {
        return { amount: el.vat ? el.amount + el.amount * vatVal : el.amount };
      })
      .map((el) => el.amount)
      .reduce((acc, sum) => acc + sum, 0);

    const amountArr = invoices.map((el) => +el.amount);
    const total = amountArr.reduce((acc, sum) => acc + sum, 0);

    const vat = formattedAmount - total;

    setTotalAmount(formattedAmount);
    setSubTotal(total);
    setVatAmount(vat);
  }, [invoices]);

  return (
    <Flex align="start" w="100%" direction="column" gap="2rem">
      <Flex align="center" justify="space-between" w="100%">
        <ReusableText
          text="Briefs"
          align="start"
          size="16px"
          weight="medium"
          color="#515050"
        />
        <ReusableRetainership retainership={retainership} />
      </Flex>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
        }}
        onSubmit={createBriefHandler}
      >
        <Flex w="100%" align="center" gap="1rem">
          <InputComponent
            onChange={(e) => setTitle(e.target.value)}
            label="Brief Title"
            placeholder="Enter brief title"
          />
          <SelectComponent
            onChange={(e) => setClient(e.value)}
            label="Client"
            options={clientNames}
          />
        </Flex>

        <SelectComponent
          label="Brief Type"
          onChange={(e) => setType(e.value)}
          options={dept || []}
        />
        <Flex w="100%" align="center" gap="1rem">
          <SelectComponent
            onChange={(e) => {
              setTeamLead(e.value);
            }}
            label="Select Team Lead"
            options={[...empNames]}
          />
          <SelectComponent
            onChange={(e) => {
              const lawyers = e?.map((el) => el.value);
              setLawyer(lawyers);
            }}
            label="Select team members"
            options={[...empNames]}
            multiple={true}
          />
        </Flex>

        <TextareaComponent
          label="Brief Description"
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter a description"
        />
        <FileComponent
          onChange={(e) => setFiles((prev) => [e.target.files[0], ...prev])}
        />
        <PreviewDocument setFiles={setFiles} files={files} />
        <BriefInvoice max={max} setMax={setMax} />
        <BriefInvoiceTitle />
        <CreateInvoice
          onChange1={addDescription}
          onChange2={addAmount}
          onChange3={addVAT}
          onBlur={addInput}
          array={inp}
          setArray={setInp}
        />
        <Flex align="start" justify="end" w="100%">
          <Button onClick={handleAdd} variant="ghost" leftIcon={<AddIcon />}>
            Add Row
          </Button>
        </Flex>
        <InvoiceStat subTotal={subTotal} vat={vatAmount} total={totalAmount} />
        <Flex pb="3rem" align="center" justify="space-between" w="100%">
          <Button
            onClick={() => navigate("/brief")}
            variant="outline"
            colorScheme="red"
          >
            Cancel
          </Button>
          <Button
            isLoading={loading}
            loadingText="Processing"
            variant="solid"
            color="white"
            bg="brand.900"
            type="submit"
          >
            Create Brief
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}
