import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function Appearance({ setAppearance }) {
  const [response, setResponse] = useState({
    dress: 0,
    neat: 0,
    groom: 0,
  });

  useEffect(() => {
    setAppearance(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="165px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="APPEARANCE (5%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          height="165px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="165px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, dress: e.target.value })
            }
            title="Dresses appropriately, in line with company’s policy"
          />
          <ReusableForm
            onChange={(e) => setResponse({ ...response, neat: e.target.value })}
            title="Keeps workplace neat, clean and organized."
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, groom: e.target.value })
            }
            title="Excellent personal grooming"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
