import { Flex, Button, Divider, Heading } from "@chakra-ui/react";
import ReusableModal from "../../UI/ReusableModal";
import SelectComponent from "../../UI/Select";
import { ChevronDownIcon } from "@chakra-ui/icons";

import ReusableChart from "../../UI/ReusableChart";

export default function LoginDurationDetail({ isOpen, onClose }) {
  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Client">
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="#222" fontWeight={600}>
          Login Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent label="" options={["Select staff here"]} />
            <SelectComponent label="" options={["19 Aug – 25 Aug"]} />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
          >
            Generate Report
          </Button>
        </Flex>

        <ReusableChart />
      </Flex>
    </ReusableModal>
  );
}
