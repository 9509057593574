import { Avatar, Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import TaskComments from "./TaskComments";
import ReusableDownloadDoc from "../../UI/ReusableDownloadDoc";

export default function ViewDetail({ task }) {
  const assigneeArr = task?.assignee && JSON.parse(task?.assignee);
  const today = new Date(Date.now());
  const dueDate = new Date(task?.due_date);

  const disabled = today > dueDate;

  return (
    <>
      <Flex w="100%" pb="4rem" align="start" direction="column" gap="2rem">
        <ReusableText
          text={task?.title}
          color="#15516D"
          weight="bold"
          size={{ lg: "20px", md: "16px", base: "14px" }}
          transform={true}
        />
        <Flex
          w="100%"
          align="center"
          direction="row-reverse"
          justify="space-between"
        >
          <Flex w="50%" align="start" direction="column" gap="8px">
            <ReusableText
              text="ASSIGNED TO"
              color="#515050"
              weight="medium"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              transform={true}
            />

            <Flex
              align="center"
              gap="0.3rem"
              w="80%"
              justify="start"
              wrap="wrap"
            >
              {assigneeArr?.map((el, i) => (
                <ReusableText
                  key={i}
                  text={el + ","}
                  color="#515050"
                  weight="light"
                  size={{ lg: "14px", md: "12px", base: "10px" }}
                  transform={true}
                />
              ))}
            </Flex>
          </Flex>

          <Flex w="50%" align="start" direction="column" gap="8px">
            <ReusableText
              text="CREATED BY"
              color="#515050"
              weight="medium"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              transform={true}
            />
            <Flex align="center" gap="1rem">
              <Avatar size="xs" name={task?.assigned_by} />
              <ReusableText
                text={task?.assigned_by}
                color="#515050"
                weight="light"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                transform={true}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex w="50%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="DUE DATE"
            color="#515050"
            weight="light"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            transform={true}
          />
          <Flex
            align="center"
            border="1px solid #ccc"
            borderRadius="16px"
            bg="white"
            w="190px"
            p="0.5rem"
          >
            <Flex
              align="center"
              justify="center"
              w="40%"
              borderRight="1px solid #ccc"
            >
              <ReusableText
                text={
                  task?.due_date && new Date(task.due_date).toLocaleTimeString()
                }
                color="#515050"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                weight="normal"
                align="center"
              />
            </Flex>
            <Flex align="center" justify="center" w="60%">
              <ReusableText
                text={
                  task?.due_date && new Date(task.due_date).toLocaleDateString()
                }
                color="#515050"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                weight="normal"
                align="center"
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex w="100%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="Description"
            color="#515050"
            weight="medium"
            size={{ lg: "16px", md: "14px", base: "12px" }}
            transform={true}
          />
          <ReusableText
            text={task?.description}
            color="#7F7E7E"
            weight="normal"
            size={{ lg: "16px", md: "14px", base: "12px" }}
            w="70%"
          />
        </Flex>

        <Flex w="100%" align="start" direction="column" gap="1rem">
          <ReusableText
            text="Attachments"
            color="#515050"
            weight="medium"
            size={{ lg: "16px", md: "14px", base: "12px" }}
            transform={true}
          />
          <ReusableDownloadDoc docs={task?.attachments} />
        </Flex>
        <TaskComments
          disabled={disabled}
          comments={task?.comments}
          id={task?.id}
        />
      </Flex>
    </>
  );
}
