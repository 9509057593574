import React, { useState, useEffect } from "react";
import Loader from "../../../UI/Loader";
import { Flex } from "@chakra-ui/react";
import StatItem from "../../StatItem";
import {
  BsFillArrowUpCircleFill,
  BsFillArrowDownCircleFill,
  BsArrowDownRightCircleFill,
} from "react-icons/bs";
import { getExpenses, getIncome } from "../../../../util/http";

export default function AccountantStat() {
  const [progress, setProgress] = useState(0);
  const [income, setIncome] = useState(0);
  const [expenses, setExpenses] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getIncome();
        const totalIncome = response?.receipts
          ?.map((el) => +el?.amount)
          .reduce((acc, sum) => +acc + +sum, 0);
        setIncome(totalIncome);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getExpenses();
        setExpenses(response?.total_expenses);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        wrap="wrap"
        gap="1.5rem"
        align="center"
        justify="space-between"
        py="3rem"
        px="1rem"
        borderRadius="1rem"
        bg="#F7F7F7"
      >
        <StatItem
          icon={<BsFillArrowDownCircleFill color="green" fontSize={24} />}
          title="Total Income"
          amount={`₦${new Intl.NumberFormat().format(income)}.00`}
          bg="rgba(144, 238, 144, 0.4)"
        />
        <StatItem
          icon={<BsFillArrowUpCircleFill color="red" fontSize={24} />}
          title="Total Expenses"
          amount={`₦${new Intl.NumberFormat().format(expenses)}.00`}
          bg="red.100"
        />
        <StatItem
          title="Expected Income"
          icon={<BsArrowDownRightCircleFill color="orange" fontSize={24} />}
          amount="₦1,200,000"
          bg="rgba(255, 213, 128, 0.4)"
        />
      </Flex>
    </>
  );
}
