import { Box, Flex } from "@chakra-ui/react";
import { AiOutlineEye } from "react-icons/ai";
import ReusableText from "../UI/ReusableText";
import { useNavigate } from "react-router-dom";

export default function AppraisalItem() {
  const navigate = useNavigate();
  return (
    <Flex
      w="100%"
      align="center"
      px="1rem"
      py="1rem"
      cursor="pointer"
      _hover={{ opacity: "80%" }}
      borderBottom="1px solid #333"
    >
      <ReusableText
        text="John Doe"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="25%"
        align="center"
      />
      <ReusableText
        text="department"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="15%"
        align="center"
        transform={true}
      />
      <ReusableText
        text="2nd quarter appraisal"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="20%"
        align="center"
      />

      <ReusableText
        text="12/12/2022"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "5px" }}
        weight="medium"
        w="15%"
        align="start"
      />
      <Flex
        align="center"
        justify="center"
        w="15%"
        gap="0.5rem"
        borderRadius="1rem"
      >
        <Box
          w="0.3rem"
          height="0.3rem"
          borderRadius="100%"
          bg={"#1FA159"}
        ></Box>
        <ReusableText
          text="Submitted"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          weight="medium"
          transform={true}
        />
      </Flex>
      <Flex align="center" w="10%" gap="1rem" justify="center">
        <AiOutlineEye
          cursor="pointer"
          onClick={() => navigate(`/appraisal/hjfgdhgs`)}
        />
      </Flex>
    </Flex>
  );
}
