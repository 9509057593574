import { Flex, Box } from "@chakra-ui/react";

import ReusableText from "../../UI/ReusableText";

export default function EmployeeAddress({ employee }) {
  return (
    <Flex
      align="center"
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", md: "8px", base: "8px" }}
    >
      <Box
        w={{ lg: "30%", md: "100%", base: "100%" }}
        height={{ lg: "350px", md: "100%", base: "100%" }}
        pt={{ lg: "2rem" }}
      >
        <ReusableText
          text="Address Details"
          size={{ lg: "20px", md: "18px", base: "15px" }}
          weight="600"
          color="#19191A"
        />
      </Box>

      <Flex align="center" direction="column" gap="2rem" w="100%">
        <Flex
          align="center"
          w="100%"
          justify="space-between"
          gap="1rem"
          wrap="wrap"
        >
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Address Line 1</p>
            <p className="view-employee-data">{employee?.address}</p>
          </Flex>
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Country</p>
            <p className="view-employee-data">{employee?.country}</p>
          </Flex>
        </Flex>

        <Flex
          align="center"
          w="100%"
          justify="space-between"
          gap="1rem"
          wrap="wrap"
        >
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">City</p>
            <p className="view-employee-data">{employee?.city}</p>
          </Flex>
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">State</p>
            <p className="view-employee-data">{employee?.state}</p>
          </Flex>
        </Flex>

        <Flex align="start" w="100%" justify="start" wrap="wrap" gap="1rem">
          {false && (
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Employee ID</p>
              <p className="view-employee-data">{employee?.employee_id}</p>
            </Flex>
          )}
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Postal Code</p>
            <p className="view-employee-data">{employee?.postal_code}</p>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
