import { Button, Flex, useToast } from "@chakra-ui/react";
import TextareaComponent from "../../UI/Textarea";

import { GrAttachment } from "react-icons/gr";
import { BiImageAdd } from "react-icons/bi";
import CommentReplies from "../../brief/view brief/comments/CommentReplies";
import { useState } from "react";
import { updateTask } from "../../../util/http";
import PreviewDocument from "../../brief/create brief/PreviewDocument";

export default function TaskComments({ comments, id, disabled }) {
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  async function addComment() {
    if (!comment) {
      toast({
        title: `missing details`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await updateTask(id, {
        comment,
        "attachments[]": files,
      });
      window.location.reload();
      setLoading(false);
      toast({
        title: `Comment added`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  return (
    <Flex
      border="1px solid #ccc"
      w={{ lg: "70%", md: "100%", base: "100%" }}
      p="1rem"
      align="start"
      direction="column"
      gap="1rem"
    >
      <TextareaComponent
        border="none"
        required={false}
        label=""
        placeholder="Enter a description..."
        onChange={(e) => setComment(e.target.value)}
      />
      <PreviewDocument setFiles={setFiles} files={files} />
      <Flex align="center" justify="space-between" w="100%">
        <Flex align="center" gap="1rem">
          <input
            onChange={(e) => setFiles((prev) => [e.target.files[0], ...prev])}
            type="file"
            id="file"
            multiple
            style={{ display: "none" }}
          />
          <label htmlFor="file">
            <GrAttachment cursor="pointer" fontSize="22px" />
          </label>

          <label htmlFor="file">
            <BiImageAdd cursor="pointer" fontSize="22px" />
          </label>
        </Flex>
        <Button
          onClick={addComment}
          variant="solid"
          bg="brand.900"
          color="white"
          isLoading={loading}
          loadingText="Processing"
          isDisabled={disabled}
        >
          Comment
        </Button>
      </Flex>
      <CommentReplies comments={comments} />
    </Flex>
  );
}
