import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

export default function ClientDetails({ client }) {
  const contactPerson = client?.contact_person && client?.contact_person[0];
  return (
    <Flex align="center" w="100%" wrap="wrap">
      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text="Client ID"
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={client?.client_id}
          size={{ lg: "16px", md: "14px", base: "10px" }}
          color="#19191A"
          weight="medium"
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text="Location"
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text="Lagos, Nigeria"
          size={{ lg: "16px", md: "14px", base: "10px" }}
          color="#19191A"
          weight="medium"
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text="Business Type"
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={client?.business_type}
          size={{ lg: "16px", md: "14px", base: "10px" }}
          color="#19191A"
          weight="medium"
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text="Email"
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={client?.email?.slice(0, 20).padEnd(22, ".")}
          size={{ lg: "16px", md: "14px", base: "5px" }}
          color="#2BA2DA"
          title={client?.email}
          weight="medium"
          cursor="pointer"
          onClick={() => (window.location.href = `mailto:${client?.email}`)}
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text="Phone Number"
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={client?.phone_number}
          size={{ lg: "16px", md: "14px", base: "8px" }}
          color="#2BA2DA"
          weight="medium"
          cursor="pointer"
          onClick={() => (window.location.href = `tel:${client?.phone_number}`)}
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text={`${
            client?.client_type?.toLowerCase() === "individual"
              ? "Next Of Kin Name"
              : "Contact Name"
          }`}
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={`${contactPerson?.contact_first_name} ${contactPerson?.contact_last_name}`}
          size={{ lg: "16px", md: "14px", base: "8px" }}
          color="#2BA2DA"
          weight="medium"
          cursor="pointer"
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text={`${
            client?.client_type?.toLowerCase() === "individual"
              ? "Next Of Kin Phone Number"
              : "Contact Phone Number"
          }`}
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={contactPerson?.contact_phone}
          size={{ lg: "16px", md: "14px", base: "8px" }}
          color="#2BA2DA"
          weight="medium"
          cursor="pointer"
          onClick={() =>
            (window.location.href = `tel:${contactPerson?.contact_phone}`)
          }
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text={`${
            client?.client_type?.toLowerCase() === "individual"
              ? "Next Of Kin Email"
              : "Contact Email"
          }`}
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={contactPerson?.contact_email?.slice(0, 20).padEnd(22, ".")}
          title={contactPerson?.contact_email}
          size={{ lg: "16px", md: "14px", base: "8px" }}
          color="#2BA2DA"
          weight="medium"
          cursor="pointer"
          onClick={() =>
            (window.location.href = `mailto:${contactPerson?.contact_email}`)
          }
        />
      </Flex>

      <Flex w="20%" mb="1rem" direction="column" align="start" gap="0.5rem">
        <ReusableText
          text={`${
            client?.client_type?.toLowerCase() === "individual"
              ? "Next Of Kin Address"
              : "Contact Address"
          }`}
          size={{ lg: "12px", md: "10px", base: "8px" }}
          color="#7F7E7E"
          weight="medium"
        />
        <ReusableText
          text={contactPerson?.contact_address}
          size={{ lg: "16px", md: "14px", base: "8px" }}
          color="#2BA2DA"
          weight="medium"
          cursor="pointer"
        />
      </Flex>
    </Flex>
  );
}
