import { Flex } from "@chakra-ui/react";
import { Fragment } from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import ViewDetail from "./ViewDetail";

export default function ViewContainer({ requisition }) {
  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Requisition"
          text="Manage your requisition and their details here"
        />
        <ViewDetail requisition={requisition} />
      </Flex>
    </Fragment>
  );
}
