import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewTaskContainer from "./ViewTaskContainer";
import { useEffect } from "react";
import { useState } from "react";
import { getTask } from "../../../util/http";
import { useParams } from "react-router-dom";

export default function ViewTask() {
  const [task, setTask] = useState("");

  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTask(params.id);
        setTask(response.task[0]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <DashboardReusable>
      <ViewTaskContainer task={task} />
    </DashboardReusable>
  );
}
