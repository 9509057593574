import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function BrandConsistency({ setBrandConsistency }) {
  const [response, setResponse] = useState({
    workClosely: 0,
    ensureContent: 0,
    developBrand: 0,
  });

  useEffect(() => {
    setBrandConsistency(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="163px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="BRAND CONSISTENCY (10%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="163px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="163px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, workClosely: e.target.value })
            }
            title="Works closely with the marketing team to establish 
            brand style guide for brand consistency"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, ensureContent: e.target.value })
            }
            title="Ensures content execution in line with pre-established 
            brand style guide"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, developBrand: e.target.value })
            }
            title="Develops brand style guide documents for external 
            partners, affiliates and others"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
