import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { AiOutlineFile } from "react-icons/ai";
import ReusableText from "../UI/ReusableText";
import { Fragment } from "react";
import RequisitionReportDetail from "./report-chart-details/RequisitionReportDetail";
import { useState } from "react";

export default function RequisitionReportCard() {
  const [show, setShow] = useState(false);
  return (
    <Fragment>
      <Flex
        align="start"
        direction="column"
        w="30%"
        border="1px solid #ccc"
        borderRadius="8px"
        h="200px"
        gap="1rem"
        p="1rem"
      >
        <Flex align="center" gap="1rem">
          <Flex
            bg="#CCCFDC"
            align="center"
            justify="center"
            p="0.7rem"
            borderRadius="100%"
          >
            <AiOutlineFile />
          </Flex>
          <ReusableText
            text="requisition"
            weight="medium"
            size="16px"
            color="#101828"
            transform={true}
          />
        </Flex>
        <ReusableText
          text="View reports of requisitions raised for briefs being attended to"
          weight="light"
          size="14px"
          color="#7F7E7E"
          transform={true}
        />
        <Button
          onClick={() => setShow(true)}
          bg="#EEEFF3"
          color="#364273"
          variant="solid"
        >
          View Details
        </Button>
      </Flex>
      {show && <RequisitionReportDetail show={setShow} />}
    </Fragment>
  );
}
