import DashboardReusable from "../UI/DashboardReusable";
import AppraisalContainer from "./AppraisalContainer";

export default function Appraisal() {
  return (
    <DashboardReusable>
      <AppraisalContainer />
    </DashboardReusable>
  );
}
