import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";

export default function Address({ user }) {
  return (
    <Flex
      align="center"
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", m: "1rem", base: "1rem" }}
    >
      <Box
        w={{ lg: "30%", md: "100%", base: "100%" }}
        pt="2rem"
        h={{ lg: "350px", md: "100%", base: "100%" }}
      >
        <ReusableText
          text="Address Details"
          size="20px"
          weight="600"
          color="#19191A"
        />
      </Box>

      <Flex align="center" direction="column" gap="2rem" w="100%">
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <Flex w="50%" direction="column" gap="8px">
            <p className="view-employee">Address Line 1</p>
            <p className="view-employee-data">{user?.address}</p>
          </Flex>
          <Flex w="50%" direction="column" gap="8px">
            <p className="view-employee">Address Line 2</p>
            <p className="view-employee-data">{user?.address}</p>
          </Flex>
        </Flex>

        <Flex align="center" w="100%" justify="center" gap="1rem">
          <Flex w="50%" direction="column" gap="8px">
            <p className="view-employee">City</p>
            <p className="view-employee-data">{user?.city}</p>
          </Flex>
          <Flex w="50%" direction="column" gap="8px">
            <p className="view-employee">State</p>
            <p className="view-employee-data">{user?.state}</p>
          </Flex>
        </Flex>

        <Flex align="center" w="100%" justify="center" gap="1rem">
          <Flex w="50%" direction="column" gap="8px">
            <p className="view-employee">Country</p>
            <p className="view-employee-data">{user?.contact_country}</p>
          </Flex>
          <Flex w="50%" direction="column" gap="8px">
            <p className="view-employee">Postal Code</p>
            <p className="view-employee-data">{user?.postal_code}</p>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
