import {
  CloseButton,
  Divider,
  Flex,
  Heading,
  Button,
  useToast,
} from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import Overlay from "../overlay/OverLay";
import ReusableText from "../UI/ReusableText";
import { useState } from "react";
import { deleteEvent, editEvent } from "../../util/http";
import InputComponent from "../UI/Input";
import TextareaComponent from "../UI/Textarea";

export default function CalendarDetail({ show, event }) {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [title, setTitle] = useState(event?.event?.title || "");
  const [description, setDescription] = useState(
    event?.event?._def?.extendedProps?.description || ""
  );
  const [start, setStart] = useState(event?.event?.startStr || "");
  const [end, setEnd] = useState(event?.event?.endStr || "");
  const [url, setUrl] = useState(
    event?.event?._def?.extendedProps?.event_url || ""
  );

  const toast = useToast();

  function closeMenu() {
    show(false);
  }

  async function editEventHandler() {
    const startDate = new Date(start).getDate();
    const startMonth = new Date(start).getMonth() + 1;
    const startYear = new Date(start).getFullYear();
    const startHour = new Date(start).getHours();
    const startMin = new Date(start).getMinutes();
    const startSec = new Date(start).getSeconds();

    const endDate = new Date(end).getDate();
    const endMonth = new Date(end).getMonth() + 1;
    const endYear = new Date(end).getFullYear();
    const endHour = new Date(end).getHours();
    const endMin = new Date(end).getMinutes();
    const endSec = new Date(end).getSeconds();

    const data = {
      start_time: `${startYear}-${startMonth
        .toString()
        .padStart(2, 0)}-${startDate.toString().padStart(2, 0)} ${startHour
        .toString()
        .padStart(2, 0)}:${startMin.toString().padStart(2, 0)}:${startSec
        .toString()
        .padStart(2, 0)}`,
      end_time: `${endYear}-${endMonth.toString().padStart(2, 0)}-${endDate
        .toString()
        .padStart(2, 0)} ${endHour.toString().padStart(2, 0)}:${endMin
        .toString()
        .padStart(2, 0)}:${endSec.toString().padStart(2, 0)}`,
      title,
      description,
      url,
    };

    try {
      setLoading(true);
      await editEvent(event.event.id, data);
      window.location.reload();
      toast({
        title: `Event Updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setToggle(false);
      show(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function deleteEventHandler() {
    try {
      setLoading1(true);
      await deleteEvent(event.event.id);
      window.location.reload();
      toast({
        title: `Event deleted`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      show(false);
      setLoading1(false);
    } catch (error) {
      setLoading1(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" py="1rem" gap="1rem">
          {toggle && (
            <>
              <Flex w="100%" align="center" justify="space-between">
                <Heading
                  textTransform="capitalize"
                  size="md"
                  color="#333"
                  fontWeight="bold"
                >
                  Edit {event.event.title}
                </Heading>
                <CloseButton onClick={closeMenu} />
              </Flex>
              <InputComponent
                placeholder="Event Title"
                label="Event Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
              <Flex w="100%" align="center" gap="1rem" justify="space-between">
                <InputComponent
                  type="datetime-local"
                  label="Start Date"
                  onChange={(e) => setStart(e.target.value)}
                  value={start}
                />

                <InputComponent
                  type="datetime-local"
                  label="End Date"
                  onChange={(e) => setEnd(e.target.value)}
                  value={end}
                />
              </Flex>
              <TextareaComponent
                label="Event Description"
                placeholder="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              <InputComponent
                placeholder="Event Link"
                label="Event URL"
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
              <Flex w="100%" align="center" justify="space-between">
                <Button
                  variant="solid"
                  colorScheme="yellow"
                  onClick={() => setToggle(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="green"
                  isLoading={loading}
                  loadingText=""
                  onClick={editEventHandler}
                >
                  Update Event
                </Button>
              </Flex>
            </>
          )}
          {!toggle && (
            <>
              <Flex w="100%" align="center" justify="space-between">
                <Heading
                  textTransform="capitalize"
                  size="md"
                  color="#333"
                  fontWeight="bold"
                >
                  {event.event.title}
                </Heading>
                <CloseButton onClick={closeMenu} />
              </Flex>
              <Divider />
              <Flex w="100%" align="start" direction="column" gap="5px">
                <ReusableText text="Event Title" color="#333" weight="bold" />
                <ReusableText
                  textTransform="capitalize"
                  text={event.event.title}
                  color="#333"
                  weight="medium"
                />
              </Flex>
              <Flex w="100%" align="start" direction="column" gap="5px">
                <ReusableText
                  text="Event Description"
                  color="#333"
                  weight="bold"
                />
                <ReusableText
                  transform={true}
                  text={event?.event?._def?.extendedProps?.description}
                  color="#333"
                  weight="medium"
                />
              </Flex>

              <Flex w="100%" align="start" direction="column" gap="5px">
                <ReusableText text="Start Date" color="#333" weight="bold" />
                <ReusableText
                  text={`${new Date(
                    event.event.startStr
                  )?.toLocaleDateString()} ${new Date(
                    event.event.startStr
                  )?.toLocaleTimeString()}`}
                  color="#333"
                  weight="medium"
                />
              </Flex>

              <Flex w="100%" align="start" direction="column" gap="5px">
                <ReusableText text="Due Date" color="#333" weight="bold" />
                <ReusableText
                  text={`${new Date(
                    event.event.endStr
                  )?.toLocaleDateString()} ${new Date(
                    event.event.endStr
                  )?.toLocaleTimeString()}`}
                  color="#333"
                  weight="medium"
                />
              </Flex>
              <Flex w="100%" align="start" direction="column" gap="5px">
                <ReusableText text="URL" color="#333" weight="bold" />
                <a
                  href={event?.event?._def?.extendedProps?.event_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "blue", fontWeight: 600 }}
                >
                  {event?.event?._def?.extendedProps?.event_url}
                </a>
              </Flex>

              <Flex w="100%" align="center" justify="space-between">
                <Button
                  variant="solid"
                  colorScheme="green"
                  onClick={() => setToggle(true)}
                >
                  Edit Event
                </Button>
                <Button
                  variant="solid"
                  colorScheme="red"
                  isLoading={loading1}
                  loadingText=""
                  onClick={deleteEventHandler}
                >
                  Delete Event
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Modal>
    </>
  );
}
