import {
  Button,
  CloseButton,
  Divider,
  Fade,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import Overlay from "../overlay/OverLay";
import InputComponent from "../UI/Input";
import { useState } from "react";
import { createDepartment } from "../../util/http";

export default function CreateDepartment({ show }) {
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState("");
  const [description, setDescription] = useState("");
  const toast = useToast();

  function closeMenu() {
    show(false);
  }

  async function createHandler() {
    const data = { department, description };

    try {
      setLoading(true);
      await createDepartment(data);
      closeMenu();
      window.location.reload();
      toast({
        title: `New Department Created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="#333" fontWeight={600}>
              Create Department
            </Heading>
            <CloseButton colorScheme="red" onClick={closeMenu} />
          </Flex>
          <Divider />
          <InputComponent
            onChange={(e) => setDepartment(e.target.value)}
            value={department}
            label="Enter Department Name"
            placeholder="Enter Department Name"
          />
          <InputComponent
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            label="Enter Description"
            placeholder="Enter Description"
          />
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText=""
            onClick={createHandler}
          >
            Submit
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
