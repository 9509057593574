import { Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import InvoiceTitle from "../../invoice/allInvoices/InvoiceTitle";
import InvoiceList from "../../invoice/allInvoices/InvoiceList";
import DeleteModal from "../../UI/DeleteModal";
import ReusableText from "../../UI/ReusableText";
import { Link } from "react-router-dom";

export default function ClientInvoice({ list }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Flex w="100%" direction="column">
        <Flex py="1rem" align="center" w="100%" justify="space-between">
          <ReusableText
            text="Recent Invoices"
            color="#3F434A"
            size="18px"
            weight="medium"
          />
          <Link to="/invoicing" style={{}}>
            View all {">"}
          </Link>
        </Flex>
        <InvoiceTitle />
        <InvoiceList list={list} onOpen={onOpen} />
      </Flex>
      <DeleteModal
        title="Delete Invoice?"
        text="Are you sure you would like to delete “Invoice ID 2303/0085”?  "
        modalText="Invoice Deleted"
        modalTitle="You have successfully deleted “Invoice ID 2303/0085”"
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
}
