import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import FileList from "../FileList";

export default function DocumentDetailCenter({ list }) {
  return (
    <Flex
      align="start"
      p="1rem"
      flex="1"
      maxHeight="75vh"
      direction="column"
      gap="1rem"
      overflow="scroll"
      w="100%"
    >
      <ReusableText
        text="Files"
        color="#3F434A"
        size="18px"
        weight="medium"
        align="start"
      />
      <FileList list={list} />
    </Flex>
  );
}
