import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ImageComponent from "../UI/Image";
import Menu from "./Menu";

import logo from "../images/logo.png";
import SmallSearch from "../UI/SmallSearch";
import MenuFooter from "./MenuFooter";

export default function DashboardSide() {
  return (
    <Flex
      // mt="-7rem"
      display={{ lg: "flex", md: "none", base: "none" }}
      w="280px"
      bg="#101828"
      align="start"
      p="1rem"
      direction="column"
      height="100vh"
      overflow="scroll"
    >
      <Flex w="100%" align="start" gap="2rem" direction="column">
        <Flex align="center" direction="column" gap="1rem">
          <Box>
            <ImageComponent
              alt="employee management"
              height="64px"
              src={logo}
            />
          </Box>
        </Flex>
        <Flex
          w="100%"
          h="85vh"
          align="start"
          direction="column"
          justify="space-between"
        >
          <Menu />
          <MenuFooter />
        </Flex>
      </Flex>
    </Flex>
  );
}
