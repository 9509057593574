import { Flex, Button } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import { BiCloudDownload } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import DownloadPage from "../../UI/DownloadPage";
import ReceiptTemplate from "../../UI/ReceiptTemplate";
import { useRef, useState } from "react";

export default function ReceiptItem({ path, onOpen, item }) {
  const receiptRef = useRef();
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <>
          <Flex align="center" p="1rem" w="100" justify="space-between">
            <DownloadPage
              element={receiptRef}
              name={item?.client}
              setShow={setShow}
            />
            <Button colorScheme="red" onClick={() => setShow(false)}>
              <AiOutlineClose />
            </Button>
          </Flex>
          <Flex ref={receiptRef} w="100%" align="center" justify="center">
            <ReceiptTemplate
              client={item?.client}
              amount={`₦${new Intl.NumberFormat().format(item?.amount)}.00`}
              id={item?.receipt_id}
              channel={item?.channel}
              description={item?.description}
            />
          </Flex>
        </>
      )}
      <Flex
        px="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        py="1rem"
        align="center"
        w="100%"
      >
        <ReusableText
          text={item?.receipt_id}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="20%"
          align="center"
        />
        <ReusableText
          text={item?.client}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="20%"
          align="center"
        />
        <ReusableText
          text={`₦${new Intl.NumberFormat().format(item?.amount)}.00`}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="20%"
          align="center"
        />
        <ReusableText
          text={item?.date}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="20%"
          align="center"
        />
        <Flex
          onClick={() => setShow(true)}
          align="center"
          w="20%"
          justify="center"
          gap="1rem"
          size={{ lg: "20px", md: "18px", base: "12px" }}
        >
          <BiCloudDownload cursor="pointer" />
        </Flex>
      </Flex>
    </>
  );
}
