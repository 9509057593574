import { Flex } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import BriefDetails from "./BriefDetails";

export default function CreateBriefContainer() {
  return (
    <Flex w="100%" align="start" direction="column" gap="1rem" p="2rem">
      <ReusableEmployeeHeader
        title="Create Briefs"
        text="Manage your brief and their details here"
      />
      <BriefDetails />
    </Flex>
  );
}
