import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewBriefContainer from "./ViewBriefContainer";

export default function ViewBrief() {
  return (
    <DashboardReusable>
      <ViewBriefContainer />
    </DashboardReusable>
  );
}
