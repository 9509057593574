import {
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import Overlay from "../overlay/OverLay";
import InputComponent from "../UI/Input";
import { useState } from "react";
import { updateDepartment } from "../../util/http";

export default function EditDepartment({ show, id, dept }) {
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState(dept || "");
  const toast = useToast();

  function closeMenu() {
    show(false);
  }

  async function editHandler() {
    const data = { department };

    try {
      setLoading(true);
      await updateDepartment(id, data);
      closeMenu();
      window.location.reload()
      toast({
        title: `Department updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="#333" fontWeight="bold">
              Edit Department
            </Heading>
            <CloseButton onClick={closeMenu} />
          </Flex>
          <Divider />
          <InputComponent
            label="Department"
            onChange={(e) => setDepartment(e.target.value)}
            value={department}
          />
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText=""
            onClick={editHandler}
          >
            Save Update
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
