import { Button, Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import TextareaComponent from "../../UI/Textarea";

export default function Review() {
  return (
    <Flex
      py="1rem"
      borderBottom="1px solid #ccc"
      w="100%"
      align="center"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
    >
      <Flex
        w={{ lg: "30%", md: "100%", base: "100%" }}
        height={{ lg: "250px", md: "100%", base: "100%" }}
      >
        <ReusableText
          text="Tell us your experience"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          color="#515050"
          weight="bold"
        />
      </Flex>
      <Flex
        height={{ lg: "250px", md: "100%", base: "100%" }}
        w="100%"
        align="start"
        direction="column"
        gap="2rem"
        overflow="scroll"
      >
        <Flex
          bg="#eee"
          boxShadow="lg"
          p="1rem"
          borderRadius={16}
          w="100%"
          align="center"
          gap="1rem"
        >
          <ReusableText
            text={`${new Date().toLocaleDateString()} \n${new Date().toLocaleTimeString()}`}
            color="#333"
            size="14px"
            weight="normal"
          />
          <ReusableText
            text="Had a wonderful experience (JOHN DOE)"
            color="#333"
            weight="medium"
          />
        </Flex>
        <Flex w="100%" align="start" direction="column" gap="1rem">
          <TextareaComponent
            required={false}
            placeholder="Tell us your experience"
          />
          <Button
            //   onClick={addReportHandler}
            variant="solid"
            bg="brand.900"
            color="white"
            //   isLoading={loading}
            loadingText="Processing"
          >
            Send
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
