import React from "react";
import { Avatar, Flex, Box, Badge } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import Address from "./Address";
import { getStoredItem } from "../../util/lib";
import { useState } from "react";
import { useEffect } from "react";
import { getKPI } from "../../util/http";

export default function PersonalDetail({ user }) {
  const userType = getStoredItem("user-type");
  const [kpi, setKpi] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getKPI();
        setKpi(response?.kpi_point);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  let text;
  let color;

  if (+kpi < 0) {
    text = "Extremely bad";
    color = "red";
  } else if (+kpi > 0 && +kpi <= 20) {
    text = "Good";
    color = "yellow";
  } else if (+kpi > 20 && +kpi <= 50) {
    text = "great";
    color = "gray";
  } else if (+kpi > 50 && +kpi <= 70) {
    text = "Excellent";
    color = "whatsapp";
  } else {
    text = "BRAVO";
    color = "green";
  }

  const secDept =
    user?.secondary_department && JSON.parse(user?.secondary_department);

  return (
    <Flex align="start" direction="column">
      <Flex
        align="center"
        height={{
          lg: userType === "employee" ? "700px" : "400px",
          md: "100%",
          base: "100%",
        }}
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
        direction={{ lg: "row", md: "column", base: "column" }}
        gap={{ lg: "0rem", m: "1rem", base: "1rem" }}
      >
        <Box
          w={{ lg: "30%", md: "100%", base: "100%" }}
          height={{
            lg: userType === "employee" ? "700px" : "400px",
            md: "100%",
            base: "100%",
          }}
        >
          <ReusableText
            text="Personal Details"
            size="20px"
            weight="600"
            color="#19191A"
          />
        </Box>
        <Flex
          w="100%"
          height={{
            lg: userType === "employee" ? "700px" : "400px",
            md: "100%",
            base: "100%",
          }}
          direction="column"
          align="start"
          gap="2rem"
        >
          <Flex align="start" gap="1rem">
            <Avatar
              size={{ lg: "2xl", md: "xl", base: "lg" }}
              name={`${user?.first_name} ${user?.last_name}`}
              src={user.image_path}
            />

            <Badge colorScheme={color}>KPI: {text}</Badge>
          </Flex>

          <Flex align="center" direction="column" gap="2rem" w="100%">
            <Flex align="center" w="100%" justify="center" gap="1rem">
              <Flex w="50%" direction="column" gap="8px">
                <p className="view-employee">First Name</p>
                <p className="view-employee-data">{user?.first_name}</p>
              </Flex>
              <Flex w="50%" direction="column" gap="8px">
                <p className="view-employee">Last Name</p>
                <p className="view-employee-data">{user?.last_name}</p>
              </Flex>
            </Flex>
            <Flex align="center" w="100%" justify="center" gap="1rem">
              <Flex w="50%" direction="column" gap="8px">
                <p className="view-employee">Email</p>
                <p className="view-employee-data">{user?.email}</p>
              </Flex>
              <Flex w="50%" direction="column" gap="8px">
                <p className="view-employee">Phone Number</p>
                <p className="view-employee-data">{user?.phone_number}</p>
              </Flex>
            </Flex>

            {userType === "client" && (
              <Flex align="center" w="100%" justify="center" gap="1rem">
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Business Name</p>
                  <p className="view-employee-data">{user?.business_name}</p>
                </Flex>
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Client Type</p>
                  <p className="view-employee-data">{user?.business_type}</p>
                </Flex>
              </Flex>
            )}

            {userType === "employee" && (
              <Flex align="center" w="100%" justify="center" gap="1rem">
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Employee ID</p>
                  <p className="view-employee-data">{user?.employee_id}</p>
                </Flex>
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Permission Level</p>
                  <p className="view-employee-data">{user?.job_role}</p>
                </Flex>
              </Flex>
            )}

            {userType === "employee" && (
              <Flex align="center" w="100%" justify="center" gap="1rem">
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Leave Count</p>
                  <p className="view-employee-data">{user?.leave_count}</p>
                </Flex>
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Salary</p>
                  <p className="view-employee-data">{user?.salary}</p>
                </Flex>
              </Flex>
            )}

            {userType === "employee" && (
              <Flex align="center" w="100%" justify="center" gap="1rem">
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Primary Department</p>
                  <p className="view-employee-data">{user?.department}</p>
                </Flex>
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Secondary Departments</p>
                  <Flex
                    maxHeight="20vh"
                    overflow="scroll"
                    w="100%"
                    align="center"
                    gap="6px"
                    wrap="wrap"
                  >
                    {secDept?.map((el) => (
                      <p className="view-employee-data">{`${el},`}</p>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            )}

            {userType === "employee" && (
              <Flex align="start" w="100%" justify="start" gap="1rem">
                <Flex w="50%" direction="column" gap="8px">
                  <p className="view-employee">Designation</p>
                  <p className="view-employee-data">{user?.designation}</p>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Address user={user} />
      <Flex
        align="center"
        height={{ lg: "550px", md: "100%", base: "100%" }}
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
        direction={{ lg: "row", md: "column", base: "column" }}
        gap={{ lg: "0rem", m: "1rem", base: "1rem" }}
      >
        <Box
          w={{ lg: "30%", md: "100%", base: "100%" }}
          height={{ lg: "500px", md: "100%", base: "100%" }}
        >
          <ReusableText
            text="Next Of Kin"
            size="20px"
            weight="600"
            color="#19191A"
          />
        </Box>

        <Flex
          height={{ lg: "500px", md: "100%", base: "100%" }}
          align="center"
          direction="column"
          gap="2rem"
          w="100%"
        >
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">First Name</p>
              <p className="view-employee-data">{user?.contact_first_name}</p>
            </Flex>
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">Last Name</p>
              <p className="view-employee-data">{user?.contact_last_name}</p>
            </Flex>
          </Flex>

          <Flex align="center" w="100%" justify="center" gap="1rem">
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">Email</p>
              <p className="view-employee-data">{user?.contact_email}</p>
            </Flex>
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">Phone Number</p>
              <p className="view-employee-data">{user?.contact_phone}</p>
            </Flex>
          </Flex>

          <Flex align="center" w="100%" justify="center" gap="1rem">
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">Address Line 1</p>
              <p className="view-employee-data">{user?.contact_address}</p>
            </Flex>
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">Address Line 1</p>
              <p className="view-employee-data">{user?.contact_address}</p>
            </Flex>
          </Flex>

          <Flex align="center" w="100%" justify="center" gap="1rem">
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">City</p>
              <p className="view-employee-data">{user?.contact_city}</p>
            </Flex>
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">Country</p>
              <p className="view-employee-data">{user?.contact_country}</p>
            </Flex>
          </Flex>

          <Flex align="center" w="100%" justify="center" gap="1rem">
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">State</p>
              <p className="view-employee-data">{user?.contact_state}</p>
            </Flex>
            <Flex w="50%" direction="column" gap="8px">
              <p className="view-employee">Postal Code</p>
              <p className="view-employee-data">{user?.contact_postal_code}</p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
