import { Avatar, Box, Flex } from "@chakra-ui/react";
import ReusableText from "../../../UI/ReusableText";

export default function CommentReply({ item, index }) {
  const date = new Date(item?.updated_at)?.toLocaleDateString();
  const time = new Date(item?.updated_at)?.toLocaleTimeString();

  const isEven = index % 2;

  return (
    <Flex
      align={isEven !== 0 ? "end" : "center"}
      justify={isEven !== 0 ? "end" : ""}
      w="100%"
      gap="1rem"
      boxShadow={isEven === 0 ? "md" : "lg"}
      borderBottomLeftRadius={isEven === 0 ? "2rem" : ""}
      borderBottomRightRadius={isEven === 0 ? "" : "2rem"}
      bg={isEven === 0 ? "#eee" : ""}
      p="1rem"
    >
      <Box>
        <Avatar name={item?.user} src="" size="sm" />
      </Box>

      <Flex align="start" direction="column" gap="0.5rem">
        <Flex align="center" gap="1rem">
          <ReusableText
            text={item?.user}
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="#667085"
            weight="medium"
          />
          <ReusableText
            text={`${date} ${time}`}
            size={{ lg: "10px", md: "8px", base: "8px" }}
            color="#667085"
            weight="normal"
          />
        </Flex>

        <ReusableText
          size={{ lg: "14px", md: "12px", base: "10px" }}
          color="#111"
          weight="medium"
          text={item?.comment}
        />
      </Flex>
    </Flex>
  );
}
