import { Flex, Text } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

export default function AppraisalFormHeader() {
  return (
    <Flex border="1px solid #ccc" w="100%" align="center" justify="center">
      <ReusableText
        text="KEY RESULT AREAS"
        color="#7F7E7E"
        weight="bold"
        size={{ lg: "14px", md: "10px", base: "6px" }}
        p="0.4rem"
        bl="1px solid #ccc"
        w="10%"
        align="start"
        height="70px"
      />
      <ReusableText
        text="PERFORMANCE OBJECTIVE"
        color="#7F7E7E"
        weight="bold"
        size={{ lg: "14px", md: "10px", base: "6px" }}
        p="0.4rem"
        bl="1px solid #ccc"
        w="45%"
        align="start"
        height="70px"
      />
      <Flex
        p="0.4rem"
        borderLeft="1px solid #ccc"
        h="70px"
        w="35%"
        align="start"
        direction="column"
      >
        <Text
          color="#7F7E7E"
          fontWeight="bold"
          h="25px"
          fontSize={{ lg: "14px", md: "10px", base: "6px" }}
        >
          Score:
        </Text>
        <Flex
          w="100%"
          align="center"
          justify="space-between"
          borderTop="1px solid #000"
          h="45px"
        >
          <Text
            w="50%"
            color="#7F7E7E"
            fontWeight="bold"
            h="100%"
            fontSize={{ lg: "14px", md: "10px", base: "6px" }}
          >
            SELF SCORE
          </Text>
          <Text
            w="50%"
            color="#7F7E7E"
            fontWeight="bold"
            h="100%"
            fontSize={{ lg: "14px", md: "10px", base: "6px" }}
            borderLeft="1px solid #000"
          >
            SUPERVISOR’S SCORE
          </Text>
        </Flex>
      </Flex>

      <ReusableText
        text="TOTAL (TO BE DONE 
            BY HR) "
        color="#7F7E7E"
        weight="bold"
        size={{ lg: "14px", md: "10px", base: "6px" }}
        p="0.4rem"
        bl="1px solid #ccc"
        w="10%"
        align="start"
        height="70px"
      />
    </Flex>
  );
}
