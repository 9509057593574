import DashboardCharts from "../../DashboardCharts";
import DashboardFooter from "../../DashboardFooter";
import AccountantStat from "./AccountantStat";

export default function Accountant() {
  return (
    <>
      <AccountantStat />
      <DashboardCharts />
      <DashboardFooter />
    </>
  );
}
