import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function BrandPerception({ setBrandPerception }) {
  const [response, setResponse] = useState({
    businessDevelopment: 0,
    trackMentions: 0,
    conductsBrand: 0,
  });

  useEffect(() => {
    setBrandPerception(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="163px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="BRAND & PERCEPTION (10%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="163px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="163px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, businessDevelopment: e.target.value })
            }
            title="Work with Business development team to develop and 
          administer questionnaires on clients’ perception of 
          company business"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, trackMentions: e.target.value })
            }
            title="Track mentions and reactions to company brand across 
            all social media platform"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, conductsBrand: e.target.value })
            }
            title="Conducts brand audit and makes recommendations 
          where necessary"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
