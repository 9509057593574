import { Button, Flex, useToast } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import AppraisalHeader from "./AppraisalHeader";
import AppraisalRating from "./AppraisalRating";
import AppraisalFormHeader from "./AppraisalFormHeader";
import Accountability from "./Accountability";
import TeamWork from "./TeamWork";
import Attendance from "./Attendance";
import JobKnowledge from "./JobKnowledge";
import BrandPerception from "./BrandPerception";
import BrandConsistency from "./BrandConsistency";
import CommunityEngagement from "./CommunityEngagement";
import ContentManagement from "./ContentManagement";
import Professional from "./Professional";
import Appearance from "./Appearance";
import TextareaComponent from "../../UI/Textarea";
import OverallPerformance from "./OverallPerformance";
import PerformanceAndReward from "./PerformanceAndReward";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import { submitAppraisal } from "../../../util/http";

export default function AppraisalContainer() {
  const [accountability, setAccountability] = useState([]);
  const [team_work, setTeamwork] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [job_knowledge, setJobKnowledge] = useState([]);
  const [brand_perception, setBrandPerception] = useState([]);
  const [brand_consistency, setBrandConsistency] = useState([]);
  const [community_engagement, setCommunityEngagement] = useState([]);
  const [content_management, setContentManagement] = useState([]);
  const [pro_personal_development, setProPersonalDevelopment] = useState([]);
  const [appearance, setAppearance] = useState([]);
  const [challenges, setChallenges] = useState("");
  const [possible_solutions, setPossibleSolution] = useState("");
  const [supervisor_comment, setSupervisorComment] = useState("");
  const [hr_comment, setHrComment] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const { user } = useContext(AuthContext);

  async function appraisalHandler() {
    const data = {
      accountability: JSON.stringify(accountability),
      team_work: JSON.stringify(team_work),
      attendance: JSON.stringify(attendance),
      job_knowledge: JSON.stringify(job_knowledge),
      brand_perception: JSON.stringify(brand_perception),
      brand_consistency: JSON.stringify(brand_consistency),
      community_engagement: JSON.stringify(community_engagement),
      content_management: JSON.stringify(content_management),
      pro_personal_development: JSON.stringify(pro_personal_development),
      appearance: JSON.stringify(appearance),
      challenges,
      possible_solutions,
      supervisor_comment,
      hr_comment,
      // date_of_appraisal: new Date(),
      position: user?.job_role,
      department: user?.department,
    };
    console.log(data);

    try {
      setLoading(true);
      await submitAppraisal(data);
      toast({
        title: `Submitted successfully`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
      mb="5rem"
    >
      <ReusableText
        text="PERFORMANCE APPRAISAL FOR EMPLOYEE"
        color="#7F7E7E"
        weight="bold"
        align="center"
        size={24}
      />
      <AppraisalHeader />
      <ReusableText
        text="PERFORMANCE RATING"
        color="#7F7E7E"
        weight="bold"
        align="center"
        size={20}
      />
      <AppraisalRating />
      <Flex w="100%" border="1px solid #ccc" align="start" direction="column">
        <AppraisalFormHeader />
        <Accountability setAccountability={setAccountability} />
        <TeamWork setTeamwork={setTeamwork} />
        <Attendance setAttendance={setAttendance} />
        <JobKnowledge setJobKnowledge={setJobKnowledge} />
        <BrandPerception setBrandPerception={setBrandPerception} />
        <BrandConsistency setBrandConsistency={setBrandConsistency} />
        <CommunityEngagement setCommunityEngagement={setCommunityEngagement} />
        <ContentManagement setContentManagement={setContentManagement} />
        <Professional setProPersonalDevelopment={setProPersonalDevelopment} />
        <Appearance setAppearance={setAppearance} />
      </Flex>
      <TextareaComponent
        onChange={(e) => setChallenges(e.target.value)}
        value={challenges}
        label="List any challenges/ problems encountered in the course of discharging your duties"
        rows={4}
      />
      <TextareaComponent
        onChange={(e) => setPossibleSolution(e.target.value)}
        value={possible_solutions}
        label="Suggest Possible Solutions"
        rows={4}
      />
      <TextareaComponent
        onChange={(e) => setSupervisorComment(e.target.value)}
        value={supervisor_comment}
        label="Supervisor’s Comment and Recommendation"
        rows={4}
      />
      <ReusableText
        text="TO BE COMPLETED BY PEOPLE MANAGEMENT DEPARTMENT"
        color="#7F7E7E"
        weight="bold"
        align="center"
        size={20}
      />
      <OverallPerformance />
      <ReusableText
        text="PERFORMANCE AND REWARD"
        color="#7F7E7E"
        weight="bold"
        align="center"
        size={20}
      />
      <PerformanceAndReward />
      <TextareaComponent
        onChange={(e) => setHrComment(e.target.value)}
        value={hr_comment}
        label="HR’s Comment"
        rows={4}
      />
      <Flex w="100%" align="end" justify="end" pb="4rem">
        <Button
          isLoading={loading}
          loadingText=""
          onClick={appraisalHandler}
          variant="solid"
          bg="green.500"
          color="white"
        >
          Submit
        </Button>
      </Flex>
    </Flex>
  );
}
