import React, { useState } from "react";
import ReusableModal from "../../UI/ReusableModal";
import InputComponent from "../../UI/Input";
import { Button, Flex, useToast } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import TextareaComponent from "../../UI/Textarea";
import { createReceipt, getClient } from "../../../util/http";
import ReusableAmountInput from "../../UI/ReusableAmountInput";
import { useEffect } from "react";
import ReusableRetainership from "../../UI/ReusableRetainership";

export default function CreateReceipt({ onClose, isOpen, invoice }) {
  const toast = useToast();
  const [sender, setSender] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [channel, setChannel] = useState("");
  const [detail, setDetail] = useState("");
  const [retainership, setRetainership] = useState("");
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const todayDate = new Date();

  const year = todayDate.getFullYear();
  const month = todayDate.getMonth() + 1;
  const day = todayDate.getDate();

  useEffect(() => {
    async function fetchData() {
      const client_id = invoice?.client?.client_id;
      try {
        const response = await getClient(client_id);
        setClient(response?.client[0]);
        setRetainership(response?.client[0]?.retainership);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [channel, invoice]);

  useEffect(() => {
    if (
      new Date(retainership?.valid_till) < new Date(Date.now()) &&
      channel?.toLowerCase() === "Retainership"
    ) {
      toast({
        title: `Client retainership has expired, select another payment channel`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setAmount("");
    }
  }, [retainership, channel, client]);

  useEffect(() => {
    if (
      channel?.toLowerCase() === "retainership" &&
      +amount > +client?.retainership?.available_balance
    ) {
      toast({
        title: `Insufficient retainership balance`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setAmount(+client?.retainership?.available_balance);
    }
  }, [amount, channel, client]);

  async function createReceiptHandler() {
    if (!sender) {
      toast({
        title: `Select a client`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (!amount) {
      toast({
        title: `Enter an amount`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (!channel) {
      toast({
        title: `Select a payment channel`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (!date) {
      toast({
        title: `Select a date`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    const data = {
      client: sender,
      amount,
      date,
      channel: channel,
      description: detail,
      invoice_id: invoice.invoice_id,
    };

    try {
      setLoading(true);
      await createReceipt(data);
      window.location.reload();
      setLoading(false);
      onClose();
      toast({
        title: `You have successfully created receipt for “Invoice No. ${invoice?.invoice_id}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal title="Receipt" isOpen={isOpen} onClose={onClose}>
      <Flex py="1rem" direction="column" gap="20px">
        {channel?.toLowerCase() === "retainership" && retainership && (
          <Flex w="100%" align="end" justify="end">
            <ReusableRetainership w="100%" retainership={retainership} />
          </Flex>
        )}
        <InputComponent
          onChange={(e) => setSender(e.target.value)}
          label="Sender Name"
          placeholder="Enter Sender Name"
        />
        <ReusableAmountInput
          label="Amount"
          placeholder="₦ Type amount here"
          type="number"
          onChange={(e) => {
            if (+e.target.rawValue > +invoice?.pending_balance) {
              setAmount("");
              toast({
                title: `Receipt amount cannot be greater than invoice amount`,
                description: ``,
                status: "warning",
                duration: 3000,
                isClosable: true,
                position: "top-right",
              });
              return;
            } else {
              setAmount(e.target.rawValue);
            }
          }}
          value={amount}
        />

        <InputComponent
          label="Date"
          onChange={(e) => setDate(e.target.value)}
          placeholder="₦ Type amount here"
          type="date"
          max={`${year}-${month.toString().padStart(2, 0)}-${day
            .toString()
            .padStart(2, 0)}`}
          min=""
        />
        <SelectComponent
          label="Channel Of Payment"
          onChange={(e) => setChannel(e.value)}
          options={[
            "Select the channel of payment that was used",
            "Cheque",
            "Cash",
            "Bank Transfer",
            `${client?.retainership ? "Retainership" : ""}`,
          ]}
        />
        <TextareaComponent
          label="Details"
          onChange={(e) => setDetail(e.target.value)}
          placeholder="Enter a description..."
        />
        <Flex w="100%" align="end" justify="end">
          <Button
            isLoading={loading}
            loadingText="Processing"
            onClick={createReceiptHandler}
            bg="brand.900"
            color="white"
          >
            Reconcile Payment
          </Button>
        </Flex>
      </Flex>
    </ReusableModal>
  );
}
