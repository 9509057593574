import { useContext } from "react";
import ReusableFilter from "../UI/ReusableFilter";
import { AuthContext } from "../../util/context";

export default function AppraisalSubheader({ searchHandler, tab }) {
  const { user } = useContext(AuthContext);
  let menu = [];

  if (tab === "employee" || tab === "hod") {
    menu = [
      { text: "Pending", onClick: "" },
      { text: "Submitted", onClick: "" },
      { text: "Expired", onClick: "" },
    ];
  } else if (tab === "hr" || tab === "seed") {
    menu = [
      { text: "Select Department", onClick: "" },
      { text: "Department of Administration", onClick: "" },
      { onClick: "", text: "Department of Practice" },
      { onClick: "", text: "Administration " },
      { onClick: "", text: "Business Development " },
      { onClick: "", text: "Corporate and Commercial" },
      { onClick: "", text: "Dispute Management and Resolution" },
      { onClick: "", text: "Employment and Labour Law" },
      { onClick: "", text: "ICT and Media" },
      { onClick: "", text: "Maritime and Aviation" },
      { onClick: "", text: "Property Law" },
    ];
  }

  return <ReusableFilter onSearch={searchHandler} menu={menu} />;
}
