import React, { Fragment, useContext } from "react";
import CreateBrief from "../../brief/create brief/CreateBrief";
import { AuthContext } from "../../../util/context";
import Login from "../../auth/Login";
import { getStoredItem } from "../../../util/lib";
import Dashboard from "../../dashboard/Dashboard";

export default function CreateBriefPage() {
  let view;
  const { isAuthenticated, user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const viewBrief =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    userType === "client";

  if (viewBrief) {
    view = <CreateBrief />;
  } else {
    view = <Dashboard />;
  }
  return <Fragment>{isAuthenticated ? view : <Login />}</Fragment>;
}
