import { useState } from "react";
import LawyerStat from "./LawyerStat";
import RecentBriefs from "./RecentBriefs";
import { useEffect } from "react";
import { getBriefs, getRequisitions, getTasks } from "../../../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../../../util/context";

export default function Lawyer() {
  const [list, setList] = useState([]);
  const [ongoingTask, setOngoingTask] = useState(0);
  const [activeBrief, setActiveBrief] = useState(0);
  const [completedBrief, setCompletedBrief] = useState(0);
  const [overDueTask, setOverDueTask] = useState(0);
  const [pendingRequisition, setPendingRequisition] = useState(0);

  const { user } = useContext(AuthContext);
  useEffect(() => {
    async function fetchdata() {
      try {
        const response = await getBriefs();
        const filtBrief = response?.briefs?.reverse().filter((el, i) => {
          const lawyer = JSON.parse(el.lawyer_id);
          return lawyer?.includes(String(user?.id));
        });
        setList(filtBrief?.slice(0, 5));
        setActiveBrief(
          filtBrief?.filter((el) => el.status?.toLowerCase() === "active")
            .length
        );
        setCompletedBrief(
          filtBrief?.filter((el) => el.status?.toLowerCase() === "completed")
            .length
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchdata();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTasks();
        setOngoingTask(
          response?.tasks?.filter(
            (el) =>
              +el.user_id === user?.id && el.status?.toLowerCase() === "ongoing"
          ).length
        );
        setOverDueTask(
          response?.tasks?.filter(
            (el) =>
              +el.user_id === user?.id &&
              el.status?.toLowerCase() === "ongoing" &&
              new Date() > new Date(el.due_date)
          ).length
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getRequisitions();
        setPendingRequisition(
          response?.requisitions?.filter(
            (el) =>
              +el.initiator?.initiator_id === +user?.id && +el.status === 0
          ).length
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  return (
    <>
      <LawyerStat
        ongoingTask={ongoingTask}
        completedBrief={completedBrief}
        activeBrief={activeBrief}
        overDueTask={overDueTask}
        pendingRequisition={pendingRequisition}
      />
      <RecentBriefs list={list} />
    </>
  );
}
