import React, { useContext, useState } from "react";
import { Avatar, useToast, Button, Flex, Tooltip } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import SelectComponent from "../UI/Select";
import TextareaComponent from "../UI/Textarea";
import ReusableSupportingDocuments from "../UI/ReusableSupportingDocuments";
import { approveQueries, editQueries } from "../../util/http";
import { AuthContext } from "../../util/context";

export default function QueryDetail({ query }) {
  const toast = useToast();
  const [decision, setDecision] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const { user } = useContext(AuthContext);

  const statusOpt =
    query?.decision?.toLowerCase() !== "pending" &&
    query?.decision?.toLowerCase() !== "reopen";

  const roleOpt =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed";

  const canReopen = roleOpt && statusOpt;

  const isAllowed =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "partner" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hod" ||
    user?.job_role?.toLowerCase() === "hop";

  async function approveQueryHandler(status) {
    const data = {
      decision: status,
    };

    try {
      setLoading1(true);
      await approveQueries(query?.query_id, data);
      toast({
        title: "Query decision updated",
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
      setLoading1(false);
    } catch (error) {
      setLoading1(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function addComment() {
    const data = {
      comment,
    };

    try {
      setLoading(true);
      await editQueries(query?.query_id, data);
      toast({
        title: "Comment added",
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast({
      //   title: `Error`,
      //   description: `${
      //     error?.response?.data.message || "something went wrong"
      //   }`,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  return (
    <Flex direction="column" gap="1rem" w="100%">
      <Flex align="center" w="100%" justify="space-between">
        <Flex w="50%" direction="column" align="start">
          <ReusableText
            text="Due date"
            color="#7F7E7E"
            size="14px"
            weight="medium"
          />
          <ReusableText
            text={query?.due_date}
            color="#515050"
            size={{ lg: "18px", md: "16px", base: "14px" }}
            weight="medium"
          />
        </Flex>
        <Flex w="50%" direction="column" align="start">
          <ReusableText
            text="Priority Level"
            color="#7F7E7E"
            size="14px"
            weight="medium"
          />
          <ReusableText
            text={query?.priority}
            color="#515050"
            size={{ lg: "18px", md: "16px", base: "14px" }}
            weight="medium"
          />
        </Flex>
      </Flex>
      <Flex align="start" direction="column" gap="0.5rem">
        <ReusableText
          text="Query description"
          color="#7F7E7E"
          size="14px"
          weight="medium"
        />
        <ReusableText
          text={query?.description}
          color="#515050"
          size={{ lg: "18px", md: "16px", base: "14px" }}
          weight="medium"
        />
      </Flex>
      <Flex
        w={{ lg: "80%", md: "100%", base: "100%" }}
        align="center"
        justify="center"
      >
        <ReusableSupportingDocuments docs={query?.documents} />
      </Flex>

      <Flex w="100%" align="center" gap="1rem">
        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="HOD Decision"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="#7F7E7E"
            weight="medium"
          />
          <ReusableText
            text={query?.hod_decision || "Pending"}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="#19191A"
            weight="medium"
          />
        </Flex>

        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="HOP Decision"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="#7F7E7E"
            weight="medium"
          />
          <ReusableText
            text={query?.decision || "Pending"}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="#19191A"
            weight="medium"
          />
        </Flex>
      </Flex>

      <Flex w="100%" align="center" gap="1rem">
        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="HR Decision"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="#7F7E7E"
            weight="medium"
          />
          <ReusableText
            text={query?.hr_decision || "Pending"}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="#19191A"
            weight="medium"
          />
        </Flex>

        {false && (
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="MANAGEMENT Decision"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="#7F7E7E"
              weight="medium"
            />
            <ReusableText
              text={query?.decision || "Pending"}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="#19191A"
              weight="medium"
            />
          </Flex>
        )}
      </Flex>

      <Flex
        w={{ lg: "80%", md: "100%", base: "100%" }}
        direction="column"
        gap="1rem"
        align="start"
        justify="center"
        maxHeight="50vh"
        overflow="scroll"
      >
        {query?.comments?.map((el) => (
          <Flex
            bg="rgba(238, 239, 243, 0.45)"
            p="1rem"
            borderRadius="8px"
            align="start"
            direction="column"
            gap="1rem"
            w="100%"
          >
            <Flex w="100%" align="start" direction="column">
              <ReusableText
                text={el.employee}
                color="#7F7E7E"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                weight="medium"
                align="start"
              />
              <ReusableText
                text={el.comment}
                color="#515050"
                size={{ lg: "16px", md: "13px", base: "10px" }}
                weight="medium"
                align="start"
              />
            </Flex>
            <Flex align="end" justify="end" w="100%">
              <Flex align="center" gap="1rem">
                <Avatar size="sm" name={el.employee} src="" />
                <Flex align="start" direction="column">
                  <ReusableText
                    text={el.employee}
                    color="#3F434A"
                    size={{ lg: "16px", md: "13px", base: "10px" }}
                    weight="medium"
                    align="start"
                  />
                  <ReusableText
                    text={`${new Date(
                      el.updated_at
                    )?.toLocaleDateString()} ${new Date(
                      el.updated_at
                    )?.toLocaleTimeString()}`}
                    color="#3F434A"
                    size={{ lg: "16px", md: "13px", base: "10px" }}
                    weight="normal"
                    align="start"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Flex
        w={{ lg: "80%", md: "100%", base: "100%" }}
        align="start"
        gap="1rem"
        direction="column"
        justify="center"
      >
        <TextareaComponent
          label="Comment"
          placeholder="Add comment..."
          onChange={(e) => setComment(e.target.value)}
        />
      </Flex>
      <Flex
        align="end"
        justify="end"
        w={{ lg: "80%", md: "100%", base: "100%" }}
      >
        <Button
          isLoading={loading}
          loadingText="Processing"
          onClick={addComment}
          bg="brand.900"
          variant="solid"
          color="white"
          size="sm"
        >
          Send
        </Button>
      </Flex>
      {query?.initiator_id === user?.employee_id && (
        <Flex w="60%">
          <SelectComponent
            label="Select Decision"
            options={["Resolve", "Reject"]}
            onChange={(e) => setDecision(e.value)}
          />
        </Flex>
      )}
      <Flex w="100%" align="center" py="3rem" justify="space-between">
        {query?.initiator_id === user?.employee_id && (
          <Button
            isLoading={loading1}
            loadingText="Processing"
            onClick={() => approveQueryHandler(decision)}
            bg="green.500"
            variant="solid"
            color="white"
          >
            Update
          </Button>
        )}

        {canReopen && (
          <Button
            isLoading={loading1}
            loadingText="Processing"
            onClick={() => approveQueryHandler("reopen")}
            bg="red.500"
            variant="solid"
            color="white"
            isDisabled={statusOpt}
          >
            Re-Open
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
