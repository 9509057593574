import { Button, Flex, useToast } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import InputComponent from "../UI/Input";
import { useState } from "react";
import { updateSetting } from "../../util/http";

export default function MoreSettings({ list }) {
  const [vat, setVat] = useState("");
  const [storage, setStorage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const toast = useToast();

  async function storageHandler() {
    const data = { key: "storage", value: storage };

    try {
      setLoading1(true);
      await updateSetting(data);
      toast({
        title: `Storage updated updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setVat("");
      setLoading1(false);
    } catch (error) {
      setLoading1(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function updateVatHandler() {
    const data = { key: "vat", value: vat };

    try {
      setLoading(true);
      await updateSetting(data);
      toast({
        title: `VAT value updated to ${vat}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setVat("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex
      align="center"
      direction="column"
      gap="1rem"
      w="100%"
      bg="white"
      py="5rem"
    >
      <Flex align="center" w="100%" bg="white">
        <Flex
          height="100px"
          align="start"
          w="20%"
          direction="column"
          gap="1rem"
        >
          <ReusableText text="VAT" size="20px" color="#19191A" weight="bold" />
        </Flex>
        <Flex
          maxHeight="100px"
          align="start"
          direction="column"
          gap="1rem"
          w="80%"
        >
          <InputComponent
            required={false}
            onChange={(e) => setVat(e.target.value)}
            value={vat}
            placeholder="Enter VAT value"
          />
          <Flex align="end" justify="end" w="100%">
            <Button
              isLoading={loading}
              loadingText=""
              onClick={updateVatHandler}
              variant="solid"
              p="1rem"
              bg="brand.900"
              color="white"
            >
              Save Update
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex align="center" w="100%" bg="white">
        <Flex
          height="100px"
          align="start"
          w="20%"
          direction="column"
          gap="1rem"
        >
          <ReusableText
            text="Storage Setting"
            size="20px"
            color="#19191A"
            weight="bold"
          />
        </Flex>
        <Flex
          maxHeight="100px"
          align="start"
          direction="column"
          gap="1rem"
          w="80%"
        >
          <Flex align="center" gap="1rem" w="100%">
            <InputComponent
              onChange={(e) => setStorage(e.target.value)}
              value={storage}
              required={false}
              placeholder="Enter value"
            />
          </Flex>
          <Flex align="end" justify="end" w="100%">
            <Button
              isLoading={loading1}
              loadingText=""
              onClick={storageHandler}
              variant="solid"
              p="1rem"
              bg="brand.900"
              color="white"
            >
              Save Update
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
