import { Divider, Flex } from "@chakra-ui/react";
import SettingHeader from "./SettingHeader";
import SettingCenter from "./SettingCenter";
import MoreSettings from "./MoreSettings";
import { useEffect, useState } from "react";
import { getEmployees } from "../../util/http";
import CreateAppraisal from "./CreateAppraisal";
import CreateDepartment from "./CreateDepartment";
import CreateDesignation from "./CreateDesignation";
import DepartmentList from "./DepartmentList";
import DesignationList from "./DesignationList";
import CreateLeaveType from "./CreateLeaveType";

export default function SettingContainer() {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        setList(
          response?.employees?.map((el) => `${el.first_name} ${el.last_name}`)
        );
      } catch (error) {
        console.log(error);
      }
    }
  });
  return (
    <>
      {show3 && <CreateLeaveType show={setShow3} />}
      {show2 && <CreateDesignation show={setShow2} />}
      {show1 && <CreateDepartment show={setShow1} />}
      {show && <CreateAppraisal show={setShow} />}
      <Flex w="100%" align="center" direction="column" gap="1rem" p="2rem">
        <SettingHeader
          show3={setShow3}
          show1={setShow1}
          show={setShow}
          show2={setShow2}
        />
        <SettingCenter />
        <Divider />
        <MoreSettings list={list} />
        <Divider />
        <DepartmentList />
        <Divider />
        <DesignationList />
      </Flex>
    </>
  );
}
