import { Flex } from "@chakra-ui/react";
import React from "react";
import InvoiceItem from "./InvoiceItem";

export default function InvoiceList({ invoices, brief }) {
  return (
    <Flex
      align="start"
      border="1px solid #ccc"
      borderBottomRadius="0.5rem"
      w="100%"
      direction="column"
    >
      {invoices?.map((item, i) => (
        <InvoiceItem
          title={brief?.brief_title}
          path="invoicing"
          item={item}
          key={i}
          brief={brief}
        />
      ))}
    </Flex>
  );
}
