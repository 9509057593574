import { HStack, PinInput, PinInputField, Box } from "@chakra-ui/react";
export default function PinInputComponent({
  setPin1,
  setPin2,
  setPin3,
  setPin4,
  setPin5,
  setPin6,
  valid,
}) {
  return (
    <HStack>
      <PinInput isInvalid={valid} size="lg" otp placeholder="0">
        <PinInputField onChange={(e) => setPin1(e.target.value)} />
        <PinInputField onChange={(e) => setPin2(e.target.value)} />
        <PinInputField onChange={(e) => setPin3(e.target.value)} />
        <Box w="10px" height="5px" bg="#A8A8A7"></Box>
        <PinInputField onChange={(e) => setPin4(e.target.value)} />
        <PinInputField onChange={(e) => setPin5(e.target.value)} />
        <PinInputField onChange={(e) => setPin6(e.target.value)} />
      </PinInput>
    </HStack>
  );
}
