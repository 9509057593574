import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function TeamWork({ setTeamwork }) {
  const [response, setResponse] = useState({
    placesTeamObjective: 0,
    strongPersonalExample: 0,
    demonstratesTolerance: 0,
    knowsWhen: 0,
    demonstrateAbility: 0,
    listens: 0,
  });

  useEffect(() => {
    setTeamwork(() => [response]);
  });

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="330px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="TEAM WORK & INTERACTION (10%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="330px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="330px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, placesTeamObjective: e.target.value })
            }
            title="Places team objectives ahead of personal agenda "
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({
                ...response,
                strongPersonalExample: e.target.value,
              })
            }
            title="Sets a strong personal example by
          demonstrating respect for others and their ideas"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({
                ...response,
                demonstratesTolerance: e.target.value,
              })
            }
            title="Demonstrates tolerance in working with coworkers, others and with changes in job conditions"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, knowsWhen: e.target.value })
            }
            title="Knows when to ask for assistance"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, demonstrateAbility: e.target.value })
            }
            title="Demonstrates ability to communicate effectively in 
            both oral and written expression with peers and 
            superiors"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, listens: e.target.value })
            }
            title="Listens and responds in a manner that demonstrates 
            understanding of ideas, information and opinions"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
