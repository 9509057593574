import { Flex, useDisclosure } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import ReportList from "./ReportList";
import { Fragment } from "react";
import BriefReport from "./report-chart-details/BriefReport";

export default function ReportContainer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p="2rem"
      >
        <ReusableEmployeeHeader
          title="Reports"
          text="Overview of all platform reports"
        />
        <ReportList onOpen={onOpen} />
      </Flex>
      {false && (
        <BriefReport isOpen={isOpen} onClose={onClose} onopen={onOpen} />
      )}
    </Fragment>
  );
}
