import React from "react";
import ReusableText from "../../UI/ReusableText";
import { Flex } from "@chakra-ui/react";

export default function OverallPerformance() {
  return (
    <Flex w="100%" align="start" direction="column">
      <Flex
        border="1px solid #ccc"
        fontSize={18}
        fontWeight="bold"
        w="100%"
        p="0.4rem"
        align="start"
        color="#7F7E7E"
      >
        OVERALL PERFORMANCE
      </Flex>
      <Flex border="1px solid #ccc" w="100%" align="start" wrap="wrap">
        <Flex
          w="50%"
          align="center"
          justify="space-between"
          borderBottom="1px solid #ccc"
          borderLeft="1px solid #ccc"
          p="6px"
        >
          <ReusableText
            text="Excellent (90-100%)"
            color="#7F7E7E"
            size="18px"
            weight="medium"
          />
          <ReusableText text="" color="#7F7E7E" size="15px" weight="normal" />
        </Flex>

        <Flex
          w="50%"
          align="center"
          justify="space-between"
          borderBottom="1px solid #ccc"
          borderLeft="1px solid #ccc"
          p="6px"
        >
          <ReusableText
            text="Satisfactory (71-89%) "
            color="#7F7E7E"
            size="18px"
            weight="medium"
          />
          <ReusableText text="" color="#7F7E7E" size="15px" weight="normal" />
        </Flex>

        <Flex
          w="50%"
          align="center"
          justify="space-between"
          borderBottom="1px solid #ccc"
          borderLeft="1px solid #ccc"
          p="6px"
        >
          <ReusableText
            text="Average (50 -70%)"
            color="#7F7E7E"
            size="18px"
            weight="medium"
          />
          <ReusableText text="" color="#7F7E7E" size="15px" weight="normal" />
        </Flex>

        <Flex
          w="50%"
          align="center"
          justify="space-between"
          borderBottom="1px solid #ccc"
          borderLeft="1px solid #ccc"
          p="6px"
        >
          <ReusableText
            text="Below Average (30%-49%)"
            color="#7F7E7E"
            size="18px"
            weight="medium"
          />
          <ReusableText text="" color="#7F7E7E" size="15px" weight="normal" />
        </Flex>

        <Flex
          w="50%"
          align="center"
          justify="space-between"
          borderBottom="1px solid #ccc"
          borderLeft="1px solid #ccc"
          p="6px"
        >
          <ReusableText
            text="Unsatisfactory (Under 29%)"
            color="#7F7E7E"
            size="18px"
            weight="medium"
          />
          <ReusableText text="" color="#7F7E7E" size="15px" weight="normal" />
        </Flex>
        <Flex
          w="50%"
          align="center"
          justify="space-between"
          borderBottom="1px solid #ccc"
          borderLeft="1px solid #ccc"
          p="6px"
        >
          <ReusableText
            text="Others"
            color="#7F7E7E"
            size="18px"
            weight="medium"
          />
          <ReusableText text="" color="#7F7E7E" size="15px" weight="normal" />
        </Flex>
      </Flex>
    </Flex>
  );
}
