import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import InputComponent from "../../UI/Input";
import TextareaComponent from "../../UI/Textarea";
import FileComponent from "../../UI/File";
import PreviewDocument from "../../brief/create brief/PreviewDocument";
import TaskDate from "./TaskDate";
import { useState } from "react";
import { createTask, getClient } from "../../../util/http";
import { useEffect } from "react";

export default function CreateTask({
  isOpen,
  onClose,
  clientList,
  allClients,
  employeeList,
  empDetail,
}) {
  const toast = useToast();

  const [client, setClient] = useState("");
  const [brief, setBrief] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [member, setMember] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [loading, setLoading] = useState(false);

  const [briefList, setBriefList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const clientId = allClients.find((el) => el.name == client)?.id;
        const response = await getClient(clientId);
        const list =
          response?.client[0]?.briefs?.map((el) => el.brief_title) || [];
        setBriefList(list);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [client]);

  async function taskHandler() {
    const assignee_id = member.map((el, i) => {
      let arr = [];
      for (let key in empDetail) {
        if (empDetail[key]?.name == el) {
          arr.push(empDetail[key].id || "");
        }
      }
      return arr;
    });

    const start = new Date(startTime);
    const end = new Date(endTime);

    const data = {
      client,
      brief,
      priority: priority,
      description,
      "attachments[]": files,
      start_date:
        startTime || startDate ? `${startTime + " " + startDate + ":00"}` : "",
      due_date: endTime || endDate ? `${endTime + " " + endDate + ":00"}` : "",
      assignee: JSON.stringify(member),
      assignee_id: JSON.stringify(assignee_id?.flatMap((el) => el?.toString())),
      title,
    };
    if (!title) {
      toast({
        title: "Missing task title",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (client && !brief) {
      toast({
        title: "Select a brief for a client",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (member?.length <= 0) {
      toast({
        title: "Assign an employee",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!data.start_date) {
      toast({
        title: "Enter start date",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!data.due_date) {
      toast({
        title: "Enter due date",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (end < start) {
      toast({
        title: "Enter a valid due date",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createTask(data);
      window.location.reload();
      setLoading(false);
      toast({
        title: "Task Created",
        description: "You have successfully created a new task",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Task</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w="100%" align="start" direction="column" gap="1rem">
            <InputComponent
              label="Task Title"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter task title here"
            />
            <SelectComponent
              onChange={(e) => setClient(e.value)}
              label="Client"
              options={["Select Client", ...clientList]}
            />
            <SelectComponent
              onChange={(e) => setBrief(e.value)}
              label="Brief"
              options={["Select Brief", ...briefList]}
            />

            <SelectComponent
              label="Priority level"
              onChange={(e) => setPriority(e.value)}
              options={["Select priority level", "medium", "low", "high"]}
            />
            <TextareaComponent
              label="Description"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter a description..."
            />
            <FileComponent
              onChange={(e) => setFiles((prev) => [e.target.files[0], ...prev])}
            />
            <PreviewDocument setFiles={setFiles} files={files} />
            <SelectComponent
              label="Assigned Member"
              multiple={true}
              onChange={(e) => {
                const lawyers = e?.map((el) => el.value);
                setMember(lawyers);
              }}
              // onChange={(e) => setMember(e.value)}
              options={["Select Employee", ...employeeList]}
            />

            <Flex align="center" gap="0.5rem" w="100%" justify="center">
              <TaskDate
                onChange1={(e) => setStartDate(e.target.value)}
                onChange2={(e) => setStartTime(e.target.value)}
                title1="Start Date"
              />

              <TaskDate
                onChange1={(e) => setEndDate(e.target.value)}
                onChange2={(e) => setEndTime(e.target.value)}
                title1="End Date"
              />
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter
          display="flex"
          align="center"
          justify="space-between"
          w="100%"
        >
          <Button
            isLoading={loading}
            loadingText="Processing"
            onClick={taskHandler}
            bg="brand.900"
            color="white"
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
