import { Avatar, Flex, Box } from "@chakra-ui/react";
import React from "react";

import ReusableText from "../../UI/ReusableText";

export default function EmployeeDetails({ employee }) {
  const secondaryDept =
    employee?.secondary_department &&
    JSON.parse(employee?.secondary_department);

  return (
    <Flex
      align="center"
      height={{ lg: "600px", md: "100%", base: "150vh" }}
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", md: "8px", base: "8px" }}
    >
      <Box
        w={{ lg: "30%", md: "100%", base: "100%" }}
        height={{ lg: "600px", md: "100%", base: "100%" }}
      >
        <ReusableText
          text="Personal Details"
          size={{ lg: "20px", md: "18px", base: "15px" }}
          weight="600"
          color="#19191A"
        />
      </Box>
      <Flex
        w="100%"
        height={{ lg: "600px", md: "100%", base: "100%" }}
        direction="column"
        align="start"
        gap="2rem"
      >
        <Avatar
          size={{ lg: "2xl", md: "xl", base: "lg" }}
          name={`${employee?.first_name} ${employee?.last_name}`}
          src={employee?.image_path}
        />
        <Flex align="center" direction="column" gap="2rem" w="100%">
          <Flex
            align="center"
            wrap="wrap"
            w="100%"
            justify="space-between"
            gap="1rem"
          >
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">First Name</p>
              <p className="view-employee-data">{employee?.first_name}</p>
            </Flex>
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Last Name</p>
              <p className="view-employee-data">{employee?.last_name}</p>
            </Flex>
          </Flex>

          <Flex
            align="center"
            w="100%"
            justify="space-between"
            gap="1rem"
            wrap="wrap"
          >
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Email</p>
              <p className="view-employee-data">{employee?.email}</p>
            </Flex>
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Phone Number</p>
              <p className="view-employee-data">{employee?.phone_number}</p>
            </Flex>
          </Flex>

          <Flex
            align="center"
            w="100%"
            justify="space-between"
            gap="1rem"
            wrap="wrap"
          >
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Employee ID</p>
              <p className="view-employee-data">{employee?.employee_id}</p>
            </Flex>
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Permission Level</p>
              <p className="view-employee-data">{employee?.job_role}</p>
            </Flex>
          </Flex>

          <Flex
            align="center"
            w="100%"
            justify="space-between"
            gap="1rem"
            wrap="wrap"
          >
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Primary Department</p>
              <p className="view-employee-data">{employee?.department}</p>
            </Flex>
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Secondary Department(s)</p>
              <Flex
                w="100%"
                wrap="wrap"
                maxHeight="20vh"
                overflow="scroll"
                align="center"
                gap="8px"
              >
                {secondaryDept?.map((el) => (
                  <p className="view-employee-data">{`${el},`}</p>
                ))}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            align="center"
            w="100%"
            justify="space-between"
            gap="1rem"
            wrap="wrap"
          >
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Leave Count</p>
              <p className="view-employee-data">{employee?.leave_count}</p>
            </Flex>
            <Flex
              w={{ lg: "45%", md: "45%", base: "100%" }}
              direction="column"
              gap="8px"
            >
              <p className="view-employee">Designation</p>
              <p className="view-employee-data">{employee?.designation}</p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
