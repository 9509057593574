import axios from "axios";
import { getStoredItem } from "./lib";

const API_URL = "https://loya-api.ritan360dev.com.ng/api";

export async function completeSetup(data) {
  const response = await axios.post(`${API_URL}/employees/setup`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response.data;
}

export async function login(data) {
  const response = await axios.post(`${API_URL}/login`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response.data;
}

export async function verifyLogin(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/login-verification`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function resendOtp(data) {
  const token = getStoredItem("token");
  const response = await axios.post(
    `${API_URL}/login-verification-resend`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function forgotPassword(data) {
  const response = await axios.post(`${API_URL}/forget-password`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response.data;
}

export async function resetPassword(data) {
  const response = await axios.post(`${API_URL}/reset-password`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response.data;
}

export async function logoutFn() {
  const token = getStoredItem("token");
  const response = await axios.post(
    `${API_URL}/logout`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function createBrief(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/briefs`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getBriefs() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/briefs`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function viewBrief(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/briefs/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function addCommentBrief(data, id) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/briefs/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteBrief(id) {
  const token = getStoredItem("token");
  const response = await axios.delete(`${API_URL}/briefs/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getEmployees() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getEmployee(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/view/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateEmployee(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(`${API_URL}/employees/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function suspendEmployee(id) {
  const token = getStoredItem("token");
  const response = await axios.patch(
    `${API_URL}/employees/suspend/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function archiveEmployee(id) {
  const token = getStoredItem("token");
  const response = await axios.delete(`${API_URL}/employees/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createNewEmployee(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/employees`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getRoles() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/roles`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getRoleDetail(role) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/roles/${role}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getClients() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/clients`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getCorporateClients(filter) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/clients?filter=${filter}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getClient(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/clients/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function suspendClient(id) {
  const token = getStoredItem("token");
  const response = await axios.patch(
    `${API_URL}/clients/suspend/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function archiveClient(id) {
  const token = getStoredItem("token");
  const response = await axios.delete(`${API_URL}/clients/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createClient(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/clients`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function editClient(id, data) {
  const token = getStoredItem("token");

  const response = await axios.patch(`${API_URL}/clients/${id}`, data, {
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getAvailablePermissions(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/permissions`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createRole(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/employees/roles`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

// invoicing
export async function createInvoice(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/invoices`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createReceipt(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/invoices/receipt`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteInvoice(data) {
  const token = getStoredItem("token");
  const response = await axios.delete(`${API_URL}/invoices`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInvoices(filter) {
  const token = getStoredItem("token");
  const response = await axios.get(
    `${API_URL}/invoices${filter ? "?filter=" + filter : ""}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getInvoice(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/invoices/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getIncome() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/invoices/receipt`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getExpenses() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/requisitions/list/total`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

////// tasks

export async function getTasks() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/tasks`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getTask(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/tasks/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateTask(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/tasks/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createTask(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/tasks`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function requestExtension(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/tasks/due-date/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteTask(id) {
  const token = getStoredItem("token");
  const response = await axios.delete(`${API_URL}/tasks/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteTaskAttachment(id, data) {
  const token = getStoredItem("token");
  const response = await axios.delete(
    `${API_URL}/tasks/attachment/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

/////////////////staff notifications
export async function getNotifications() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/notifications`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateNotifications(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(
    `${API_URL}/employees/notifications/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function updatePassword(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(
    `${API_URL}/employees/update-password/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function createRequisitions(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/requisitions`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

//// requisition
export async function getRequisitions() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/requisitions`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getRequisition(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/requisitions/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteRequisition(id) {
  const token = getStoredItem("token");
  const response = await axios.delete(`${API_URL}/requisitions/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateRequisition(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(`${API_URL}/requisitions/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function sendBuzz(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/requisitions/buzz/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getCountries() {
  const response = await axios.get(
    "https://countriesnow.space/api/v0.1/countries"
  );

  return response.data.data;
}

export async function getState(data) {
  const response = await axios.post(
    "https://countriesnow.space/api/v0.1/countries/states",
    data
  );

  return response.data.data;
}

export async function createRetainer(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/retainers`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getRetainerships() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/retainers`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateRetainerships(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(`${API_URL}/retainers/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getVAT() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/vat`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateSetting(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/settings`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateClient(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(`${API_URL}/clients/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

//////// query
export async function getQueries() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/queries`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getQuery(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/queries/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createQueries(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/queries`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function editQueries(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/queries/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function approveQueries(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/queries/approve/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

/////////////
// leave
export async function getLeaves() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/leaves`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getLeave(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/leaves/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createLeave(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/leaves`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateLeave(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/leaves/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function approveLeave(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/leaves/approve/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

/////////////
// calender
export async function createEvent(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/calendar`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getEvents() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/calendar`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getUserEvents(id) {
  const token = getStoredItem("token");
  const response = await axios.get(
    `${API_URL}/calendar/user?assignee_id=${id}&filter=month`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function deleteEvent(id) {
  const token = getStoredItem("token");
  const response = await axios.delete(`${API_URL}/calendar/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function editEvent(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(`${API_URL}/calendar/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

/////////////
// documents
export async function getFolders() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/documents`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getFolderFiles(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/documents/folder`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function uploadDocument(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/documents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteDocument(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/documents/delete/file`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function shareDocument(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/documents/share/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

/////////////////////
// update salary
export async function updateSalary(id, data) {
  const token = getStoredItem("token");
  const response = await axios.post(
    `${API_URL}/employees/update-salary/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function salaryReport(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/salary-report/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function createDesignation(data) {
  const token = getStoredItem("token");
  const response = await axios.post(
    `${API_URL}/employees/designation/create`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function listDesignations() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/designation/list`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateDesignation(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(
    `${API_URL}/employees/designation/update/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function createDepartment(data) {
  const token = getStoredItem("token");
  const response = await axios.post(
    `${API_URL}/employees/department/create`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function listDepartments() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/employees/department/list`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function updateDepartment(id, data) {
  const token = getStoredItem("token");
  const response = await axios.patch(
    `${API_URL}/employees/department/update/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

///////////////////////
// employee update
export async function addUpdates(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/documents/updates`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function listUpdates(id) {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/documents/updates/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function sharedDocuments() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/documents/shared`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

////////////
// device and storage info
export async function getDeviceHistory() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/device/history`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getStorageCapacity() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/documents/capacity`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getKPI() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/kpi`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getKPIHistory() {
  const token = getStoredItem("token");
  const response = await axios.get(`${API_URL}/kpi/history`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

/////////////////////
// appraisal
export async function submitAppraisal(data) {
  const token = getStoredItem("token");
  const response = await axios.post(`${API_URL}/appraisal`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
