import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillFolder } from "react-icons/ai";
import ShareDocument from "./ShareDocument";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function DocumentItem({ item }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  return (
    <Fragment>
      {show && <ShareDocument show={setShow} />}

      <Flex
        w="100%"
        py="1rem"
        borderBottom="1px solid #ccc"
        align="center"
        justify="space-between"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          if (e.target.localName === "button") {
            return;
          } else {
            navigate(`/document/${item}`);
          }
        }}
      >
        <Flex align="center" gap="1rem">
          <AiFillFolder cursor="pointer" fontSize="30px" />
          <ReusableText
            text={item}
            size="18px"
            color="#3F434A"
            weight="medium"
            title={item}
            transform={true}
          />
        </Flex>
        <Menu>
          <MenuButton bg="transparent" as={Button} cursor="pointer">
            <BsThreeDotsVertical />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => navigate(`/document/${item}`)}>
              View
            </MenuItem>
            {false && <MenuItem onClick={() => setShow(true)}>Share</MenuItem>}
          </MenuList>
        </Menu>
      </Flex>
    </Fragment>
  );
}
