import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { AiOutlineFile } from "react-icons/ai";
import ReusableText from "../UI/ReusableText";
import { Fragment } from "react";
import LoginDurationDetail from "./report-chart-details/LoginDurationDetail";

export default function LoginDurationReportCard() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Fragment>
      <Flex
        align="start"
        direction="column"
        w="30%"
        border="1px solid #ccc"
        borderRadius="8px"
        h="200px"
        gap="1rem"
        p="1rem"
      >
        <Flex align="center" gap="1rem">
          <Flex
            bg="#CCCFDC"
            align="center"
            justify="center"
            p="0.7rem"
            borderRadius="100%"
          >
            <AiOutlineFile />
          </Flex>
          <ReusableText
            text="Login Duration"
            weight="medium"
            size="16px"
            color="#101828"
            transform={true}
          />
        </Flex>
        <ReusableText
          text="View login duration report for employees throughout the year"
          weight="light"
          size="14px"
          color="#7F7E7E"
          transform={true}
        />
        <Button onClick={onOpen} bg="#EEEFF3" color="#364273" variant="solid">
          View Details
        </Button>
      </Flex>
      <LoginDurationDetail isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}
