import DashboardReusable from "../../UI/DashboardReusable";
import AppraisalContainer from "./AppraisalContainer";

export default function AppraisalDetail() {
  return (
    <DashboardReusable>
      <AppraisalContainer />
    </DashboardReusable>
  );
}
