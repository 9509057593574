import React from "react";
import ReusableText from "../../UI/ReusableText";
import {
  Box,
  Flex,
  FormLabel,
  FormControl,
  Select,
  Input,
} from "@chakra-ui/react";
import DetailForm from "./DetailForm";
import InputComponent from "../../UI/Input";
import EditAddress from "./EditAddress";

export default function PersonalDetail({
  setEmail,
  setEmployeeId,
  setFirstName,
  setLastName,
  setPhone,
  setRole,
  email,
  employeeId,
  firstName,
  lastName,
  phone,
  role,
  setImage,
  kinFirstName,
  setKinFirstname,
  setKinLastName,
  kinLastName,
  kinCity,
  setKinCity,
  kinCode,
  setKinCode,
  kinState,
  setKinState,
  kinCountry,
  setKinCountry,
  kinAddress1,
  kinPhone,
  setKinPhone,
  setKinAddress1,
  kinAddress2,
  setKinAddress2,
  kinEmail,
  setKinEmail,
  setAddress1,
  setAddress2,
  setCode,
  setCity,
  setState,
  setCountry,
  address1,
  address2,
  code,
  state,
  country,
  city,
}) {
  return (
    <Flex align="center" direction="column">
      <Flex
        align="center"
        height={{ lg: "500px", md: "100%", base: "100%" }}
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
        direction={{ lg: "row", md: "column", base: "column" }}
        gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
      >
        <Box
          w={{ lg: "30%", md: "100%", base: "100%" }}
          h={{ lg: "500px", md: "100%", base: "100%" }}
        >
          <ReusableText
            text="Personal Details"
            size="20px"
            weight="600"
            color="#19191A"
          />
        </Box>

        <DetailForm
          setEmail={setEmail}
          setPhone={setPhone}
          setEmployeeId={setEmployeeId}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setRole={setRole}
          email={email}
          phone={phone}
          lastName={lastName}
          firstName={firstName}
          role={role}
          employeeId={employeeId}
          setImage={setImage}
        />
      </Flex>

      <EditAddress
        setAddress1={setAddress1}
        setAddress2={setAddress2}
        setCity={setCity}
        setCode={setCode}
        setCountry={setCountry}
        setState={setState}
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        code={code}
        country={country}
      />

      <Flex
        align="center"
        height={{ lg: "550px", md: "100%", base: "100%" }}
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
        direction={{ lg: "row", md: "column", base: "column" }}
        gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
      >
        <Box
          w={{ lg: "30%", md: "100%", base: "100%" }}
          height={{ lg: "500px", md: "100%", base: "100%" }}
        >
          <ReusableText
            text="Next Of Kin"
            size="20px"
            weight="600"
            color="#19191A"
          />
        </Box>

        <Flex
          height={{ lg: "500px", md: "100%", base: "100%" }}
          align="center"
          direction="column"
          gap="2rem"
          w="100%"
        >
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              placeholder="Enter first name"
              label="First Name"
              type="text"
              onChange={(e) => setKinFirstname(e.target.value)}
              value={kinFirstName}
              readOnly={true}
            />
            <InputComponent
              label="Last Name"
              placeholder="Enter last name"
              type="text"
              onChange={(e) => setKinLastName(e.target.value)}
              value={kinLastName}
              readOnly={true}
            />
          </Flex>
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              placeholder="Enter email address"
              label="Email"
              type="email"
              onChange={(e) => setKinEmail(e.target.value)}
              value={kinEmail}
              readOnly={true}
            />
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Flex
                border="1px solid #ccc"
                borderRadius="0.5rem"
                align="center"
              >
                <Select border="none" w="30%">
                  <option value="234">+234</option>
                </Select>
                <Input
                  type="number"
                  border="none"
                  onChange={(e) => setKinPhone(e.target.value)}
                  value={kinPhone}
                  placeholder="XXX XXX XXXX"
                  focusBorderColor="transparent"
                  isReadOnly={true}
                />
              </Flex>
            </FormControl>
          </Flex>
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              placeholder="Enter address"
              label="Address Line 1"
              type="text"
              onChange={(e) => setKinAddress1(e.target.value)}
              value={kinAddress1}
              readOnly={true}
            />
            <InputComponent
              label="Address Line 2"
              placeholder="Enter address"
              type="text"
              onChange={(e) => setKinAddress2(e.target.value)}
              value={kinAddress2}
              readOnly={true}
            />
          </Flex>
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              placeholder="Enter City"
              onChange={(e) => setKinCity(e.target.value)}
              value={kinCity}
              label="City"
              type="text"
              readOnly={true}
            />
            <InputComponent
              placeholder="Enter Country"
              onChange={(e) => setKinCountry(e.target.value)}
              value={kinCountry}
              label="Country"
              type="text"
              readOnly={true}
            />
          </Flex>

          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              placeholder="Enter State"
              onChange={(e) => setKinState(e.target.value)}
              value={kinState}
              label="State"
              type="text"
              readOnly={true}
            />
            <InputComponent
              placeholder="Enter Postal Code"
              label="Postal Code"
              type="text"
              onChange={(e) => setKinCode(e.target.value)}
              value={kinCode}
              readOnly={true}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
