import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function Accountability({ setAccountability }) {
  const [response, setResponse] = useState({
    timelyService: 0,
    takesOwnership: 0,
    responseAppropiately: 0,
    useTimeWell: 0,
    willingToLearnQuickly: 0,
    demonstratesOrganizationalValue: 0,
    demonstratesHighValue: 0,
    followTask: 0,
    AdheresToDeadlines: 0,
  });

  useEffect(() => {
    setAccountability(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="500px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="ACCOUNTABILITY & WORK ATTITUDE (15%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="500px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="500px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, timelyService: e.target.value })
            }
            title="Offers timely, effective and courteous service or 
      assistance directly addressing clients needs"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, takesOwnership: e.target.value })
            }
            title="Takes ownership for responsibilities and is accountable for actions"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, responseAppropiately: e.target.value })
            }
            title="Responds appropriately to adverse and stressful situations"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, useTimeWell: e.target.value })
            }
            title="Uses available working time well. plans and prioritizes 
        work, sets and accomplishes goals"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({
                ...response,
                willingToLearnQuickly: e.target.value,
              })
            }
            title="Willingness to learn quickly, to adapt to changes in job 
          assignments, methods, personnel, or surroundings"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({
                ...response,
                demonstratesOrganizationalValue: e.target.value,
              })
            }
            title="Demonstrates organizational values; Integrity, Respect 
          and Excellence"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({
                ...response,
                demonstratesHighValue: e.target.value,
              })
            }
            title="Demonstrates high level of initiative, drive, creativity 
          and objectivity"
          />{" "}
          <ReusableForm
            onChange={(e) =>
              setResponse({
                ...response,
                followTask: e.target.value,
              })
            }
            title="Follows through on tasks, duties and responsibilities"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({
                ...response,
                AdheresToDeadlines: e.target.value,
              })
            }
            title="Adheres to project deadlines"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
