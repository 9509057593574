import { Flex, useDisclosure } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import ViewTaskSubHeader from "./ViewTaskSubHeader";
import ViewDetail from "./ViewDetail";
import EditTask from "../edit-task/EditTask";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import { useState } from "react";

export default function ViewTaskContainer({ task }) {
  const [show, setShow] = useState(false);
  const { user } = useContext(AuthContext);

  function openMenu() {
    setShow(true);
  }

  return (
    <>
      <Flex
        w="100%"
        align="start"
        direction="column"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Tasks"
          text="Manage your task and their details here"
          show={
            user?.job_role?.toLowerCase() !== "lawyer" &&
            user?.job_role?.toLowerCase() !== "hr" &&
            user?.job_role?.toLowerCase() !== "user"
          }
          btnText="Edit Task"
          onClick={() =>
            user?.job_role?.toLowerCase() !== "lawyer" &&
            user?.job_role?.toLowerCase() !== "hr" &&
            user?.job_role?.toLowerCase() !== "user" &&
            openMenu()
          }
        />
        <ViewTaskSubHeader task={task} />
        <ViewDetail task={task} />
      </Flex>
      {show && <EditTask show={setShow} task={task} />}
    </>
  );
}
