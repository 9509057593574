import { Flex } from "@chakra-ui/react";
import React from "react";
import FolderList from "./FolderList";
import ReusableText from "../UI/ReusableText";
import FileList from "./FileList";
import SmallSearch from "../UI/SmallSearch";
import { getFolders } from "../../util/http";

export default function DocumentList({ setShow, list, setList }) {
  async function searchHandler(e) {
    try {
      const response = await getFolders();
      const filtered = response?.folders?.filter((el) =>
        el?.toLowerCase()?.includes(e.target.value.trim()?.toLowerCase())
      );
      setList(filtered);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Flex
      align="start"
      p="1rem"
      flex="1"
      height="75vh"
      direction="column"
      gap="1rem"
      overflow="scroll"
    >
      <SmallSearch onSearch={searchHandler} w="90%" border="1px solid #ccc" />
      <ReusableText
        text="Folder"
        color="#3F434A"
        size="18px"
        weight="medium"
        align="start"
      />
      <FolderList list={list} setShow={setShow} />
      {false && (
        <ReusableText
          text="File"
          color="#3F434A"
          size="18px"
          weight="medium"
          align="start"
        />
      )}
      {false && <FileList setShow={setShow} />}
    </Flex>
  );
}
