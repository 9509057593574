import React from "react";
import UserStat from "./UserStat";
import RecentLeave from "./RecentLeave";
import { useEffect } from "react";
import { getLeaves } from "../../../../util/http";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../../util/context";

export default function User() {
  const [list, setList] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getLeaves();
        setList(
          response?.leaves?.filter((el) => el.employee_id === user?.employee_id)
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);
  return (
    <>
      <UserStat />
      <RecentLeave list={list} />
    </>
  );
}
