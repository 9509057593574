import { Flex, Select } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

import SelectComponent from "../../UI/Select";

export default function ReusableForm({ title, onChange }) {
  const options = [1, 2, 3, 4, 5];
  return (
    <Flex w="100%" borderBottom="1px solid #ccc">
      <Flex
        borderRight="1px solid #ccc"
        borderLeft="1px solid #ccc"
        w="90%"
        align="center"
        justify="space-between"
      >
        <ReusableText
          text={title}
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          p="0.4rem"
          w="55%"
          align="start"
        />
        <Flex w="44.5%" borderLeft="1px solid #ccc" align="center">
          <Select
            placeholder="1"
            onChange={onChange}
            w="30%"
            mx="auto"
            borderRadius="0px"
          >
            {options.map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
          </Select>

          <Select w="30%" mx="auto" borderRadius="0px">
            {options.map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <ReusableText
        text="3"
        color="#7F7E7E"
        weight="bold"
        size={{ lg: "14px", md: "10px", base: "6px" }}
        p="0.4rem"
        w="10%"
        align="start"
      />
    </Flex>
  );
}
