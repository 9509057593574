import React from "react";
import ReusableListTitle from "../../UI/ReusableListTitle";

export default function InvoiceTitle() {
  return (
    <ReusableListTitle
      title1="Invoice ID"
      title2="Client Name"
      title3="Brief Title"
      title4="Amount"
      title5="Status"
      title6="Actions"
      w1="20%"
      w2="25%"
      w3="15%"
      w4="20%"
      w5="15%"
      w6="5%"
      align1="center"
      align4="center"
      align5="center"
      align6="center"
      show={false}
    />
  );
}
