import { Flex, useDisclosure } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import SmallSearch from "../UI/SmallSearch";
import Todo from "./to-do/Todo";
import Ongoing from "./on-going/Ongoing";
import Completed from "./completed/Completed";
import { Fragment, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import CreateTask from "./create-task/CreateTask";
import { useEffect } from "react";
import { getBriefs, getClients, getEmployees, getTasks } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function TaskContainer({ open, list, setList }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [todoList, setTodoList] = useState([]);
  const [ongoingList, setOngoingList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [clientName, setClientName] = useState([]);
  const [briefList, setBriefList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [allClient, setAllClients] = useState([]);
  const [empDetail, setEmpDetail] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const clientList = await getClients();
        const briefList = await getBriefs();
        const employeeList = await getEmployees();
        const briefTitles = briefList?.briefs?.map((el) => el.brief_title);
        const clientNames = clientList?.clients
          ?.filter((el) => el.deleted_at === null)
          .map((el) => el.client_name);
        const employeeObj = employeeList?.employees
          ?.filter((el) => {
            const canAssign =
              el?.job_role?.toLowerCase() === "lawyer" ||
              el?.job_role?.toLowerCase() === "hod" ||
              el?.job_role?.toLowerCase() === "hop" ||
              el?.job_role?.toLowerCase() === "user" ||
              el?.job_role?.toLowerCase() === "accountant" ||
              el?.job_role?.toLowerCase() === "hr";
            return el.deleted_at === null && canAssign;
          })
          .map((el) => {
            return { name: `${el.first_name} ${el.last_name}`, id: el.id };
          });

        const employeeNames = employeeObj.map((el) => el.name);

        const allClient = clientList?.clients
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return { name: el.client_name, id: el.client_id };
          });

        setAllClients(allClient);
        setEmpDetail(employeeObj);
        setEmployeeList(employeeNames);
        setClientName(clientNames);
        setBriefList(briefTitles);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const todo = list?.filter((el) => el.status === "todo");
    const ongoing = list?.filter((el) => el.status === "ongoing");
    const completed = list?.filter((el) => el.status === "completed");
    setTodoList(todo);
    setOngoingList(ongoing);
    setCompletedList(completed);
  }, [list]);

  async function searchHandler(e) {
    const response = await getTasks();
    const filteredData = response?.tasks.filter((el) => {
      if (user?.job_role?.toLowerCase() === "seed") {
        return el.title
          ?.toLowerCase()
          .includes(e.target.value.trim().toLowerCase());
      } else {
        return (
          +el.user_id === user?.id &&
          el.title?.toLowerCase().includes(e.target.value.trim().toLowerCase())
        );
      }
    });
    setList(filteredData);
  }

  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Tasks"
          text="Manage your task and their details here"
          btnText="Add"
          icon={user?.job_role?.toLowerCase() !== "lawyer" && <AddIcon />}
          show={
            user.job_role?.toLowerCase() !== "lawyer" &&
            user.job_role?.toLowerCase() !== "user"
          }
          onClick={
            user.job_role?.toLowerCase() !== "lawyer" &&
            user.job_role?.toLowerCase() !== "user" &&
            onOpen
          }
        />
        <Flex align="start" justify="start" w="30%">
          <SmallSearch
            w="100%"
            border="1px solid #ccc"
            onSearch={searchHandler}
          />
        </Flex>
        <Flex
          align="center"
          mt="1rem"
          justify="space-between"
          w="100%"
          bg="white"
        >
          <DndProvider backend={HTML5Backend}>
            <Todo list={todoList} />
            <Ongoing list={ongoingList} />
            <Completed list={completedList} />
          </DndProvider>
        </Flex>
      </Flex>
      <CreateTask
        clientList={clientName}
        briefList={briefList}
        employeeList={employeeList}
        isOpen={isOpen}
        onClose={onClose}
        allClients={allClient}
        empDetail={empDetail}
      />
    </Fragment>
  );
}
