import { Fragment, useState, useEffect } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { Flex, Button, useToast } from "@chakra-ui/react";
import SelectTab from "./SelectTab";
import ClientInvoiceDetails from "./ClientDetail";
import CreateInvoice from "./CreateInvoice";
import InvoiceForm from "./InvoiceForm";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import InvoiceStat from "../brief/create brief/InvoiceStat";
import {
  createInvoice,
  createRetainer,
  getBriefs,
  getClients,
  getVAT,
  getClient,
} from "../../util/http";
import CreateRetainerShip from "./CreateRetainerShip";
import ReusableText from "../UI/ReusableText";
import ReusableRetainership from "../UI/ReusableRetainership";

export default function InvoiceContainer() {
  const [array, setArray] = useState([1]);
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams();

  const [client, setClient] = useState("");

  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [vat, setVat] = useState([]);
  const [max, setMax] = useState("");
  const [clientNames, setClientNames] = useState([]);
  const [briefTitles, setBriefTitles] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [allBriefs, setAllBriefs] = useState([]);
  const [clientId, setClientId] = useState("");
  const [loading, setLoading] = useState(false);
  const [retAmount, setRetAmount] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [briefList, setBriefList] = useState([]);
  const [vatVal, setVatVal] = useState(0);
  const [retainership, setRetainership] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const client_id = allClient?.find((el) => el.name == client)?.id;
        const response = await getClient(client_id);
        setRetainership(response?.client[0]?.retainership);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [client]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVAT();
        setVatVal(+response?.vat);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getClients();
        const response2 = await getBriefs();
        const newClients = response?.clients?.map((el) => {
          return { name: el.client_name, id: el.client_id };
        });
        const newBriefs = response2?.briefs?.map((el) => {
          return { name: el.brief_title, id: el.brief_id };
        });

        setAllClient(newClients);
        setAllBriefs(newBriefs);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const clientName = allClient.map((el) => el.name);
    const briefTitle = allBriefs.map((el) => el.name);
    setClientNames(clientName);
    setBriefTitles(briefTitle);
  }, [allBriefs, allClient]);

  async function createRetainerHandler(e) {
    e.preventDefault();
    const client_id = allClient?.find((el) => el.name == client)?.id;
    const data = {
      client_id,
      client,
      maximum_spend: max,
      validity: dueDate,
      amount: retAmount,
    };

    if (!client || !retAmount || !max || !dueDate) {
      toast({
        title: "Retainership details missing",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createRetainer(data);
      setLoading(false);
      navigate("/invoicing");
      toast({
        title: "Invoice Created",
        description: `You have successfully created ${
          params.type === "invoice" ? "an invoice" : "a retainership"
        }`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  function handleAdd() {
    setArray((prev) => [...prev, []]);
  }

  function addDescription(e, i) {
    const inpData = [...array];

    inpData[i] = e.target.value;
    setArray(inpData);
  }

  function addAmount(e, i) {
    const inpData = [...amount];
    inpData[i] = +e.target.rawValue;
    setAmount(inpData);

    const newArr = inpData.map((el, i) => {
      return {
        description: array[i],
        amount: inpData[i],
        vat: vat[i],
      };
    });
    setInvoices(newArr);
  }
  function addVat(e, i) {
    const inpData = [...vat];
    inpData[i] = e.target.checked;
    setVat(inpData);

    const newArr = inpData.map((el, i) => {
      return {
        description: array[i],
        amount: amount[i],
        vat: inpData[i] ? inpData[i] : false,
      };
    });
    setInvoices(newArr);
  }

  function addInput() {
    const newArr = array.map((el, i) => {
      return {
        description: el,
        amount: amount[i],
        vat: vat[i] ? vat[i] : false,
      };
    });
    setInvoices(newArr);
  }

  useEffect(() => {
    const formattedAmount = invoices
      ?.map((el) => {
        return { amount: el.vat ? el.amount + el.amount * vatVal : el.amount };
      })
      .map((el) => el.amount)
      .reduce((acc, sum) => acc + sum, 0);

    const amountArr = invoices.map((el) => +el.amount);
    const total = amountArr.reduce((acc, sum) => acc + sum, 0);

    const vat = formattedAmount - total;

    setTotalAmount(formattedAmount);
    setSubTotal(total);
    setVatAmount(vat);
  }, [invoices]);

  async function createHandler(e) {
    e.preventDefault();

    if (!title) {
      toast({
        title: "Select a brief",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!client) {
      toast({
        title: "Select a client",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const client_id = allClient?.find((el) => el.name == client)?.id;
    const brief_id = allBriefs?.find((el) => el.name == title)?.id;

    const data = {
      client_id,
      brief_id,
      client,
      brief_title: title,
      // invoice_type: params.type,
      grand_total: totalAmount,
      sub_total: subTotal,
      maximum_spend: 20,
      invoice: invoices.length > 0 ? JSON.stringify(invoices) : [],
      invoice_type: retainership ? "retainer" : "invoice",
    };

    try {
      setLoading(true);
      await createInvoice(data);
      setLoading(false);
      navigate("/invoicing");
      toast({
        title: "Invoice Created",
        description: `Invoice created for ${title} brief`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Create Invoice"
          text="Manage your invoice and their details here"
        />
        {params.type === "invoice" ? (
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
            onSubmit={createHandler}
          >
            <Flex align="center" justify="space-between" w="100%">
              <ReusableText
                text="Briefs"
                align="start"
                size="16px"
                weight="medium"
                color="#515050"
              />
              <ReusableRetainership retainership={retainership} />
            </Flex>
            <SelectTab
              clients={clientNames}
              briefs={briefList}
              setClient={setClient}
              client={client}
              setTitle={setTitle}
              setClientId={setClientId}
              allClient={allClient}
              type="invoice"
            />
            {clientId && (
              <ClientInvoiceDetails
                setBriefList={setBriefList}
                clientId={clientId}
              />
            )}
            {false && <CreateInvoice max={max} setMax={setMax} />}
            <InvoiceForm
              onChange1={addDescription}
              onChange2={addAmount}
              onChange3={addVat}
              onBlur={addInput}
              array={array}
              setArray={setArray}
            />
            <Flex align="start" justify="end" w="100%">
              <Button
                onClick={handleAdd}
                variant="ghost"
                leftIcon={<AddIcon />}
              >
                Add Row
              </Button>
            </Flex>
            <InvoiceStat
              subTotal={subTotal}
              vat={vatAmount}
              total={totalAmount}
            />
            <Flex pb="3rem" align="center" justify="space-between" w="100%">
              <Button
                onClick={() => navigate("/invoicing")}
                variant="outline"
                colorScheme="red"
              >
                Cancel
              </Button>
              <Button
                isLoading={loading}
                loadingText="Processing"
                type="submit"
                variant="solid"
                color="white"
                bg="brand.900"
              >
                Create Invoice
              </Button>
            </Flex>
          </form>
        ) : (
          <CreateRetainerShip
            setDueDate={setDueDate}
            setRetAmount={setRetAmount}
            setClient={setClient}
            setTitle={setTitle}
            setMax={setMax}
            max={max}
            loading={loading}
            setBriefList={setBriefList}
            createHandler={createRetainerHandler}
          />
        )}
      </Flex>
    </Fragment>
  );
}
