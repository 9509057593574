import { Flex } from "@chakra-ui/react";
import React from "react";
import DocumentItem from "./DocumentItem";

export default function FolderList({ setShow, list }) {
  return (
    <Flex align="start" direction="column" w="100%">
      {list?.length > 0 ? (
        <>
          {list?.map((item, i) => (
            <DocumentItem type="folder" item={item} setShow={setShow} key={i} />
          ))}
        </>
      ) : (
        <Flex w="100%" align="center" justify="center" h="300px">
          No folder found...
        </Flex>
      )}
    </Flex>
  );
}
