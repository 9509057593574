import { Flex } from "@chakra-ui/react";
import React from "react";
import CommentReply from "./CommentReply";

export default function CommentReplies({ comments }) {
  return (
    <Flex
      w="100%"
      align="center"
      direction="column"
      maxHeight="80vh"
      overflow="scroll"
      gap="1.5rem"
      p="1rem"
    >
      {comments?.map((item, i) => (
        <CommentReply key={i} index={i} item={item} />
      ))}
    </Flex>
  );
}
