import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import BriefDetail from "./BriefDetail";
import Documents from "./Documents";
import Comments from "./comments/Comments";
import Invoices from "./Invoices";
import TextSkeleton from "../../UI/TextSkeleton";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import BriefReport from "./BriefReport";
import Review from "./Review";

export default function ViewTab({ brief, loading, show, setShow }) {
  const { user } = useContext(AuthContext);
  return (
    <Tabs w="100%" variant="solid-rounded">
      <TabList p="0.2rem" borderRadius="8px" w="100%" bg="#E8E9EB">
        <Tab
          borderRadius="8px"
          _selected={{ color: "#515050", bg: "white" }}
          w="50%"
        >
          Brief Details
        </Tab>
        <Tab
          borderRadius="8px"
          _selected={{ color: "#515050", bg: "white" }}
          w="50%"
        >
          Invoices
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          {loading ? (
            <TextSkeleton />
          ) : (
            <>
              <BriefDetail
                show={show}
                setShow={setShow}
                loading={loading}
                brief={brief}
              />
              <Review />
              <BriefReport />
              <Documents loading={loading} docs={brief?.documents} />
              <Comments
                brief={brief}
                loading={loading}
                comments={brief?.comments}
                id={brief?.brief_id}
              />
            </>
          )}
        </TabPanel>
        <TabPanel>
          {loading ? (
            <TextSkeleton />
          ) : (
            <Invoices
              loading={loading}
              brief={brief}
              invoices={brief?.invoices}
            />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
