import { Flex, Button, Heading, Divider } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Modal from "../../overlay/Modal";
import AreaChart from "../../UI/AreaChart";
import { useState } from "react";
import { useEffect } from "react";
import { getBriefs, getRequisitions } from "../../../util/http";
import { filterDate } from "../../../util/lib";

function monthFilter(data, month) {
  return data?.filter((el) => {
    const createdMonth = new Date(el.created_at).getMonth() + 1;
    return +createdMonth === +month;
  }).length;
}

export default function RequisitionReportDetail({ show }) {
  const [filter, setFilter] = useState("weekly");
  const [days, setDays] = useState(0);
  const [briefTitles, setBriefTitles] = useState([]);
  const [allBriefs, setAllBriefs] = useState([]);
  const [brief, setBrief] = useState("");

  const [data, setData] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });

  useEffect(() => {
    if (filter?.toLowerCase() === "daily") {
      setDays(1);
    } else if (filter?.toLowerCase() === "weekly") {
      setDays(7);
    } else if (filter?.toLowerCase() === "monthly") {
      setDays(30);
    } else {
      setDays("");
    }
  }, [filter]);

  function closeMenu() {
    show(false);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response2 = await getBriefs();
        const filteredBrief = response2.briefs?.filter(
          (el, i) => el.status?.toLowerCase() === "active"
        );
        const newBriefs = filteredBrief?.map((el) => {
          return { name: el.brief_title, id: el.brief_id, type: el.brief_type };
        });
        const briefTitle = newBriefs.map((el) => el.name);
        setBriefTitles(briefTitle);
        setAllBriefs(newBriefs);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const brief_id = allBriefs?.find(
        (el) => el.name?.toLowerCase() === brief?.toLowerCase()
      )?.id;
      try {
        const response = await getRequisitions();
        const briefs = response?.requisitions?.filter((el) => {
          if (!days) {
            return el.brief_id === brief_id;
          }
          const milliSec = +days * 24 * 60 * 60 * 1000;
          const startDate = filterDate(milliSec);
          const createdDate = new Date(el?.created_at);
          return startDate < createdDate && el.brief_id === brief_id;
        });
        setData({
          January: monthFilter(briefs, 1),
          February: monthFilter(briefs, 2),
          March: monthFilter(briefs, 3),
          April: monthFilter(briefs, 4),
          May: monthFilter(briefs, 5),
          June: monthFilter(briefs, 6),
          July: monthFilter(briefs, 7),
          August: monthFilter(briefs, 8),
          September: monthFilter(briefs, 9),
          October: monthFilter(briefs, 10),
          November: monthFilter(briefs, 11),
          December: monthFilter(briefs, 12),
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [allBriefs, brief, days]);

  console.log(data);

  return (
    <Modal hide={closeMenu}>
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="#222" fontWeight={600}>
          Requisition Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent
              label=" Select Brief"
              onChange={(e) => setBrief(e.value)}
              options={["Select Brief", ...briefTitles]}
            />
            <SelectComponent
              label="Duration"
              onChange={(e) => setFilter(e.value)}
              options={["Daily", "Weekly", "Monthly", "All Time"]}
            />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
          >
            Generate Report
          </Button>
        </Flex>
        <AreaChart name="Briefs" data={Object.values(data)} />
      </Flex>
    </Modal>
  );
}
