import React from "react";
import AuthComponent from "../UI/AuthComponent";
import { Flex, Heading, Text } from "@chakra-ui/react";
import ButtonComponent from "../UI/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";

export default function PasswordResetSuccess() {
  const navigate = useNavigate();
  return (
    <AuthComponent>
      <Heading
        textAlign="center"
        color="#3F434A"
        fontSize="24px"
        fontWeight="medium"
      >
        Password Reset
      </Heading>
      <Text
        fontSize="14px"
        color="#949493"
        fontWeight="light"
        textAlign="center"
      >
        Your password has been successfully reset. Click below to continue to
        dashboard
      </Text>
      <ButtonComponent
        onClick={() => navigate("/login")}
        title="Continue"
        width="100%"
      />
      <Flex align="center" justify="center" gap="0.5rem">
        <ArrowBackIcon />
        <Link
          style={{
            color: "#2BA2DA",
            fontWeight: "medium",
          }}
          to="/login"
        >
          Back to log in
        </Link>
      </Flex>
    </AuthComponent>
  );
}
