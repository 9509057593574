import { useEffect, useState } from "react";
import Loader from "../../../UI/Loader";
import { Flex } from "@chakra-ui/react";
import StatItem from "../../StatItem";
import { MdQueryBuilder } from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { FcLeave } from "react-icons/fc";
import { getEmployees, getLeaves, getQueries } from "../../../../util/http";

export default function HRStat({ employees }) {
  const [progress, setProgress] = useState();
  const [activeQuery, setActiveQuery] = useState(0);
  const [totalStaff, setTotalStaff] = useState(0);
  const [approvedLeave, setApprovedLeave] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getQueries();
        setProgress(80);
        setProgress(100);
        setActiveQuery(
          response?.queries?.filter(
            (el) => el.status?.toLowerCase() === "active"
          ).length
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setTotalStaff(employees?.length);
    }
    fetchData();
  }, [employees]);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getLeaves();

        setProgress(80);
        setProgress(100);
        setApprovedLeave(
          response?.leaves?.filter(
            (el) => el.status?.toLowerCase() === "approved"
          ).length
        );
      } catch (error) {
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        wrap="wrap"
        gap="1.5rem"
        align="center"
        justify="space-between"
        py="3rem"
        px="1rem"
        borderRadius="1rem"
        bg="#F7F7F7"
      >
        <StatItem
          icon={<BsFillPeopleFill color="green" fontSize={24} />}
          title="TOTAL STAFF"
          amount={totalStaff}
          bg="rgba(144, 238, 144, 0.4)"
        />
        <StatItem
          icon={<MdQueryBuilder color="orange" fontSize={24} />}
          title="Active Query"
          amount={activeQuery}
          bg="rgba(255, 213, 128, 0.4)"
        />
        <StatItem
          icon={<FcLeave color="green" fontSize={24} />}
          bg="rgba(144, 238, 144, 0.4)"
          title="Approved Leave"
          amount={approvedLeave}
        />
      </Flex>
    </>
  );
}
