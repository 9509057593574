import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function ContentManagement({ setContentManagement }) {
  const [response, setResponse] = useState({
    develops: 0,
    submits: 0,
    creates: 0,
    review: 0,
    writes: 0,
    regularReviews: 0,
    shares: 0,
  });

  useEffect(() => {
    setContentManagement(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="385px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="CONTENT MANAGEMENT (15%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="385px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="385px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, develops: e.target.value })
            }
            title="Develops monthly content calendar"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, submits: e.target.value })
            }
            title="Submits quarterly report on current benchmark trends 
            and audience preferences"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, creates: e.target.value })
            }
            title="Always creates engaging and shareable content for 
            social media pages
            "
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, review: e.target.value })
            }
            title="Quarterly review of social media content strategy"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, writes: e.target.value })
            }
            title="Writes at least 5 evergreen articles on the benefits of 
            organization's services
            "
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, regularReviews: e.target.value })
            }
            title="Regularly reviews existing blog posts and increases 
            organic traffic by at least 10% across all updated posts"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, shares: e.target.value })
            }
            title="Shares the blog / post with highest engagement with 
            business development team monthly"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
