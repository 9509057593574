import { Box, Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

export default function EmployeeContactPerson({ employee }) {
  return (
    <Flex
      align="center"
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", md: "8px", base: "8px" }}
    >
      <Box
        w={{ lg: "30%", md: "100%", base: "100%" }}
        height={{ lg: "400px", md: "100%", base: "100%" }}
        pt="2rem"
      >
        <ReusableText
          text="Contact Person Details"
          size={{ lg: "20px", md: "18px", base: "15px" }}
          weight="600"
          color="#19191A"
        />
      </Box>
      <Flex align="center" direction="column" gap="2rem" w="100%">
        <Flex
          align="center"
          w="100%"
          justify="space-between"
          gap="1rem"
          wrap="wrap"
        >
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Contact First Name</p>
            <p className="view-employee-data">{employee?.contact_first_name}</p>
          </Flex>
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Contact Last Name</p>
            <p className="view-employee-data">{employee?.contact_last_name}</p>
          </Flex>
        </Flex>

        <Flex
          align="center"
          w="100%"
          justify="space-between"
          gap="1rem"
          wrap="wrap"
        >
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Email</p>
            <p className="view-employee-data">{employee?.contact_email}</p>
          </Flex>
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Contact Phone Number</p>
            <p className="view-employee-data">{employee?.contact_phone}</p>
          </Flex>
        </Flex>

        <Flex
          align="center"
          w="100%"
          justify="space-between"
          gap="1rem"
          wrap="wrap"
        >
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Contact Country</p>
            <p className="view-employee-data">{employee?.contact_country}</p>
          </Flex>
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Contact State</p>
            <p className="view-employee-data">{employee?.contact_state}</p>
          </Flex>
        </Flex>

        <Flex
          align="center"
          w="100%"
          justify="space-between"
          gap="1rem"
          wrap="wrap"
        >
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Contact Postal Code</p>
            <p className="view-employee-data">
              {employee?.contact_postal_code}
            </p>
          </Flex>
          <Flex
            w={{ lg: "45%", md: "45%", base: "100%" }}
            direction="column"
            gap="8px"
          >
            <p className="view-employee">Contact Address</p>
            <p className="view-employee-data">{employee?.contact_address}</p>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
