import { Skeleton, Stack } from "@chakra-ui/react";

export default function TextSkeleton() {
  return (
    <Stack gap="1rem">
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
      <Skeleton isLoaded={false} height="30px" />
    </Stack>
  );
}
