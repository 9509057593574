import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function CommunityEngagement({ setCommunityEngagement }) {
  const [response, setResponse] = useState({
    increaseFollowership: 0,
    increaseMentions: 0,
    researches: 0,
    writes: 0,
    explores: 0,
    reports: 0,
  });

  useEffect(() => {
    setCommunityEngagement(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="380px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="COMMUNITY ENGAGEMENT & GROWTH (15%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="380px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="380px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, increaseFollowership: e.target.value })
            }
            title="Increase followership across social media platform by 
          at least 20% quarterly"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, increaseMentions: e.target.value })
            }
            title="Increase mentions, shares and retweets by at-least 
            20% quarterly"
          />

          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, researches: e.target.value })
            }
            title="Researches and identifies at-least one suitable 
          influencers quarterly"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, writes: e.target.value })
            }
            title="Writes a blog/post targeting identified influencers 
            quarterly"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, explores: e.target.value })
            }
            title="Explore and incorporate a new social media channel 
            every quarter"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, reports: e.target.value })
            }
            title="Reports month by month metrics; engagement, 
            followership, traffic etc."
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
