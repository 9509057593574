import React from "react";
import Employee from "../../employee/Employee";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function EmployeePage() {
  let view;

  const { isAuthenticated, user } = useContext(AuthContext);
  const viewEmployee =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed";

  if (viewEmployee) {
    view = <Employee />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated ? view : <Login />}</>;
}
