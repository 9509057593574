import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";
import { CheckIcon } from "@chakra-ui/icons";

export default function PermissionItem({ item }) {
  return (
    <Flex align="center" justify="center" gap="1rem" px="1.5rem" py="1rem">
      <CheckIcon color="green.600" />
      <ReusableText
        text={item}
        color="#7F7E7E"
        size="16px"
        transform={true}
        weight="normal"
      />
    </Flex>
  );
}
