import { Flex, Box, Button, useToast } from "@chakra-ui/react";
import ReusableText from "../../../UI/ReusableText";
import CommentStyles from "./CommentStyles";
import TextareaComponent from "../../../UI/Textarea";
import SelectComponent from "../../../UI/Select";
import CommentReplies from "./CommentReplies";
import { useState } from "react";
import FileComponent from "../../../UI/File";
import PreviewDocument from "../../create brief/PreviewDocument";
import { addCommentBrief } from "../../../../util/http";

export default function Comments({ comments, id, brief }) {
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  async function addComment() {
    const data = {
      comment,
      "documents[]": files,
    };

    if (!comment) {
      toast({
        title: `Add a comment`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await addCommentBrief(data, id);

      toast({
        title: `Comment added`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
      py="1rem"
      borderBottom="1px solid #ccc"
      w="100%"
      align="center"
    >
      <Flex
        w={{ lg: "30%", md: "100%", base: "100%" }}
        height={{ lg: "900px", md: "100%", base: "100%" }}
      >
        <ReusableText
          text="Comments"
          size="16px"
          color="#515050"
          weight="bold"
        />
      </Flex>
      <Flex
        height={{ lg: "900px", md: "100%", base: "100%" }}
        overflow="scroll"
        align="start"
        direction="column"
        w="100%"
        gap="1rem"
      >
        <TextareaComponent
          label="Add Comment"
          onChange={(e) => setComment(e.target.value)}
          placeholder="Enter a comment..."
        />
        <FileComponent
          onChange={(e) => setFiles((prev) => [e.target.files[0], ...prev])}
        />
        <PreviewDocument files={files} setFiles={setFiles} />
        <Flex w="100%" align="center" justify="space-between">
          <Button
            isDisabled={
              brief?.status?.toLowerCase() === "completed" ||
              brief?.status?.toLowerCase() === "draft"
            }
            isLoading={loading}
            loadingText="Processing"
            bg="brand.900"
            color="white"
            onClick={addComment}
          >
            Send
          </Button>
        </Flex>
        <CommentReplies comments={comments} />
      </Flex>
    </Flex>
  );
}
