import { Flex } from "@chakra-ui/react";
import React from "react";
import BriefItem from "./BriefItem";

export default function AllBriefs({ brief }) {
  return (
    <Flex align="center" w="100%" wrap="wrap">
      {brief?.length > 0 ? (
        <>
          {brief?.map((item, i) => (
            <BriefItem item={item} key={i} />
          ))}
        </>
      ) : (
        <Flex align="center" h="20vh" justify="center" w="100%">
          No Brief found
        </Flex>
      )}
    </Flex>
  );
}
