import { Button, Flex } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import EditDepartment from "./EditDepartment";
import { useState } from "react";
import { useEffect } from "react";
import { listDepartments } from "../../util/http";

export default function DepartmentList() {
  const data = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [id, setId] = useState("");
  const [dept, setDept] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDepartments();
        setList(response?.departments);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Flex align="center" w="100%" bg="white">
        <Flex
          height="300px"
          align="start"
          w="20%"
          direction="column"
          gap="1rem"
        >
          <ReusableText
            text="Departments"
            size="20px"
            color="#19191A"
            weight="bold"
          />
        </Flex>
        <Flex
          height="300px"
          align="start"
          direction="column"
          gap="1rem"
          w="80%"
          overflow="scroll"
        >
          {list?.map((el) => (
            <Flex
              borderBottom="1px solid #ccc"
              w="100%"
              align="center"
              justify="space-between"
            >
              <ReusableText
                text={el?.department}
                title={el?.description}
                transform={true}
                color="#333"
                size="15px"
                weight="medium"
              />
              <Flex align="center" gap="1rem">
                <Button
                  onClick={() => {
                    setId(el.id);
                    setDept(el.department);
                    setShow(true);
                  }}
                  variant="ghost"
                  colorScheme="green"
                >
                  <AiFillEdit size="18px" />
                </Button>
                {false && (
                  <Button variant="ghost" colorScheme="red">
                    <AiFillDelete size="18px" />
                  </Button>
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      {show && <EditDepartment id={id} dept={dept} show={setShow} />}
    </>
  );
}
