import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

export default function InvoiceTitle() {
  return (
    <Flex
      bg="#EEEFF3"
      borderTopRadius="0.5rem"
      w="100%"
      px="0.5rem"
      py="1rem"
      align="center"
      justify="center"
    >
      <ReusableText
        text="Invoice ID"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />
      <ReusableText
        text="Client Name"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="30%"
        align="center"
      />

      <ReusableText
        text="Brief Title"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Amount"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />

      <ReusableText
        w="15%"
        text="Status"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        align="center"
      />
      <ReusableText
        w="15%"
        text="Actions"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        align="center"
      />
    </Flex>
  );
}
