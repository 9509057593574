import { Box, Flex } from "@chakra-ui/react";
import ReusableChart from "../UI/ReusableChart";
import AreaChart from "../UI/AreaChart";
import { useEffect } from "react";
import { getClients, getIncome } from "../../util/http";
import { useState } from "react";

function monthFilter(data, month) {
  return data?.filter((el) => {
    const createdMonth = new Date(el.created_at).getMonth() + 1;
    return +createdMonth === +month;
  }).length;
}

function incomeFilter(data, month) {
  return data
    ?.filter((el) => {
      const createdMonth = new Date(el.created_at).getMonth() + 1;
      return +createdMonth === +month;
    })
    .map((el) => +el?.amount)
    .reduce((acc, sum) => +acc + +sum, 0);
}

export default function DashboardCharts() {
  const [january, setJanuary] = useState(0);
  const [february, setFebruary] = useState(0);
  const [march, setMarch] = useState(0);
  const [april, setApril] = useState(0);
  const [may, setMay] = useState(0);
  const [june, setJune] = useState(0);
  const [july, setJuly] = useState(0);
  const [august, setAugust] = useState(0);
  const [september, setSeptember] = useState(0);
  const [october, setOctober] = useState(0);
  const [november, setNovember] = useState(0);
  const [december, setDecember] = useState(0);

  const [income, setIncome] = useState([]);

  const [months, setMonths] = useState([]);

  useEffect(() => {
    const months = [
      january,
      february,
      march,
      april,
      may,
      june,
      july,
      august,
      september,
      october,
      november,
      december,
    ];
    setMonths(months);
  }, [
    january,
    february,
    march,
    april,
    may,
    june,
    july,
    august,
    october,
    november,
    december,
    september,
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getClients();
        const clients = response?.clients;
        setJanuary(monthFilter(clients, 1));
        setFebruary(monthFilter(clients, 2));
        setMarch(monthFilter(clients, 3));
        setApril(monthFilter(clients, 4));
        setMay(monthFilter(clients, 5));
        setJune(monthFilter(clients, 6));
        setJuly(monthFilter(clients, 7));
        setAugust(monthFilter(clients, 8));
        setSeptember(monthFilter(clients, 9));
        setOctober(monthFilter(clients, 10));
        setNovember(monthFilter(clients, 11));
        setDecember(monthFilter(clients, 12));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getIncome();
        const income = response?.receipts;
        const jan = incomeFilter(income, 1);
        const feb = incomeFilter(income, 2);
        const mar = incomeFilter(income, 3);
        const apr = incomeFilter(income, 4);
        const may = incomeFilter(income, 5);
        const jun = incomeFilter(income, 6);
        const jul = incomeFilter(income, 7);
        const aug = incomeFilter(income, 8);
        const sept = incomeFilter(income, 9);
        const oct = incomeFilter(income, 10);
        const nov = incomeFilter(income, 11);
        const dec = incomeFilter(income, 12);
        setIncome([
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sept,
          oct,
          nov,
          dec,
        ]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Flex w="100%" wrap="wrap" align="center" justify="space-between">
      <Box w={{ lg: "45%", md: "40%", base: "90%" }} mx="auto">
        <ReusableChart name="Clients" data={months} />
      </Box>
      <Box w={{ lg: "45%", md: "40%", base: "90%" }} mx="auto">
        <AreaChart name="Income" data={income} />
      </Box>
    </Flex>
  );
}
