import { Flex } from "@chakra-ui/react";
import React, { useContext } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { getStoredItem } from "../../util/lib";
import Client from "./client/Client";
import { AuthContext } from "../../util/context";
import HR from "./roles-stats/hr/HR";
import Accountant from "./roles-stats/accountant/Accountant";
import Heads from "./roles-stats/head-of-department/Heads";
import Lawyer from "./roles-stats/lawyer/Lawyer";
import Stats from "./Stats";
import DashboardCharts from "./DashboardCharts";
import DashboardFooter from "./DashboardFooter";
import User from "./roles-stats/user/User";

export default function DashboardCenter() {
  const userType = getStoredItem("user-type");
  const { user } = useContext(AuthContext);

  let view;

  if (user?.job_role?.toLowerCase() === "hr") {
    view = <HR />;
  } else if (user?.job_role?.toLowerCase() === "accountant") {
    view = <Accountant />;
  } else if (
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop"
  ) {
    view = <Heads />;
  } else if (user?.job_role?.toLowerCase() === "lawyer") {
    view = <Lawyer />;
  } else if (userType === "client") {
    view = <Client />;
  } else if (user?.job_role?.toLowerCase() === "user") {
    view = <User />;
  } else {
    view = (
      <>
        <Stats />
        <DashboardCharts />
        <DashboardFooter />
      </>
    );
  }

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      h="100%"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
      pb="4rem"
    >
      <ReusableEmployeeHeader
        title="Dashboard"
        text="Overview of all platform reports "
      />
      {view}
    </Flex>
  );
}
