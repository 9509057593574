import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
} from "@chakra-ui/react";
import React from "react";
import SmallSearch from "../UI/SmallSearch";
import { BsFilter } from "react-icons/bs";

export default function ReusableFilter({ show = true, menu, onSearch }) {
  return (
    <Flex align="center" justify="space-between" w="100%">
      {show && (
        <Menu>
          <MenuButton
            bg="transparent"
            color="brand.900"
            as={Button}
            leftIcon={<BsFilter />}
            _hover={{ bg: "transparent" }}
            _expanded={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            variant="outline"
          >
            Filters
          </MenuButton>
          <MenuList>
            {menu?.map((item, i) => (
              <MenuItem
                _focus={{ bg: "#fff" }}
                _hover={{ bg: "#fff" }}
                _expanded={{ bg: "#fff" }}
                onClick={() => item.onClick()}
              >
                {item.text}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
      <SmallSearch
        onSearch={onSearch}
        border="1px solid #ccc"
        w={{ lg: "30%", md: "50%", base: "60%" }}
      />
    </Flex>
  );
}
