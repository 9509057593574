import { Flex } from "@chakra-ui/react";
import AppraisalTitle from "./AppraisalTitle";
import AppraisalList from "./AppraisalList";

export default function CustomList() {
  return (
    <Flex w="100%" direction="column">
      <AppraisalTitle />
      <AppraisalList />
    </Flex>
  );
}
