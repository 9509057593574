import { Badge, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import TextSkeleton from "../../UI/TextSkeleton";
import UpdateTag from "./UpdateTag";

export default function BriefDetail({ brief, loading, show, setShow }) {
  const lawyers = brief?.lawyer && JSON.parse(brief?.lawyer || "");

  const lawyersArr = lawyers?.map((el) => el);

  return (
    <>
      {show && <UpdateTag show={setShow} />}
      <Flex
        py="1rem"
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        direction={{ lg: "row", md: "column", base: "column" }}
        gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
      >
        <Flex
          w={{ lg: "30%", md: "100%", base: "100%" }}
          height={{ lg: "400px", md: "100%", base: "100%" }}
        >
          <ReusableText
            text="Brief Details"
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="#515050"
            weight="bold"
          />
        </Flex>
        <Flex
          height={{ lg: "400px", md: "100%", base: "100%" }}
          w="100%"
          direction="column"
          gap="2rem"
        >
          <Flex w="100%" align="center" gap="1rem">
            <Flex w="50%" align="start" direction="column" gap="0.5rem">
              <Flex align="center" gap="1rem">
                <ReusableText
                  text="Brief Title"
                  size={{ lg: "14px", md: "12px", base: "10px" }}
                  color="#7F7E7E"
                  weight="medium"
                />
                <Badge
                  cursor="pointer"
                  onClick={() => setShow(true)}
                  colorScheme="green"
                >
                  Awaiting Judgement
                </Badge>
              </Flex>
              {loading ? (
                <TextSkeleton />
              ) : (
                <Flex align="center" gap="1rem">
                  <ReusableText
                    text={brief?.brief_title}
                    size={{ lg: "16px", md: "14px", base: "12px" }}
                    color="#515050"
                    weight="medium"
                  />
                </Flex>
              )}
            </Flex>

            <Flex w="50%" align="start" direction="column" gap="0.5rem">
              <ReusableText
                text="Client"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="#7F7E7E"
                weight="medium"
              />
              <ReusableText
                text={brief?.client}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="#515050"
                weight="medium"
              />
            </Flex>
          </Flex>

          <Flex w="100%" align="center" gap="1rem">
            <Flex w="50%" align="start" direction="column" gap="0.5rem">
              <ReusableText
                text="Brief Type"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="#7F7E7E"
                weight="medium"
              />
              <ReusableText
                text={brief?.brief_type}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="#515050"
                weight="medium"
              />
            </Flex>

            <Flex w="50%" align="start" direction="column" gap="0.5rem">
              <ReusableText
                text="Assign Lawyer"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="#7F7E7E"
                weight="medium"
              />
              <Flex
                maxHeight="20vh"
                overflow="scroll"
                align="center"
                wrap="wrap"
                gap="0.5rem"
              >
                {lawyersArr?.map((el, i) => (
                  <ReusableText
                    text={`${el},`}
                    size={{ lg: "16px", md: "14px", base: "12px" }}
                    color="#515050"
                    weight="medium"
                    transform={true}
                  />
                ))}
              </Flex>
            </Flex>
          </Flex>

          <Flex w="100%" align="start" direction="column" gap="0.5rem">
            <ReusableText
              text="Brief Description"
              size="14px"
              color="#7F7E7E"
              weight="medium"
            />
            <Flex
              w="100%"
              align="center"
              height="20vh"
              overflow="scroll"
              gap="1rem"
            >
              <ReusableText
                text={brief?.description}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="#515050"
                weight="medium"
                transform={true}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
