import React from "react";
import EmployeeHeader from "./EmployeeHeader";
import { Box, Flex } from "@chakra-ui/react";
import EmployeeTitle from "./EmployeeTitle";
import EmployeeList from "./EmployeeList";
import EmployeeFooter from "./EmployeeFooter";
import EmployeeSubHeader from "./EmployeeSubHeader";

export default function EmployeeContainer({
  onOpen,
  list,
  total,
  perPage,
  setCurrentPage,
  currentPage,
  setList,
  loading,
}) {
  return (
    <Flex
      w="100%"
      align="center"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <EmployeeHeader />
      <Flex w="100%" align="start">
        <EmployeeSubHeader setList={setList} />
      </Flex>
      <Box w="100%">
        <EmployeeTitle />
        <EmployeeList loading={loading} list={list} onOpen={onOpen} />
        <EmployeeFooter
          total={total}
          perPage={perPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </Flex>
  );
}
