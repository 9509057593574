import React from "react";
import ViewClient from "../../clients/view client/ViewClient";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function ViewClientPage() {
  let view;
  const { isAuthenticated, user } = useContext(AuthContext);

  const viewClient =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase() === "seed";

  if (viewClient) {
    view = <ViewClient />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated ? view : <Login />}</>;
}
