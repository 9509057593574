import { Flex, useDisclosure } from "@chakra-ui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import ReusableFilter from "../UI/ReusableFilter";
import RequestLeave from "./RequestLeave";
import LeaveTitle from "./LeaveTitle";
import LeaveList from "./LeaveList";
import ReusableFooter from "../UI/ReusableFooter";
import { getLeaves } from "../../util/http";
import { AuthContext } from "../../util/context";

export default function LeaveContainer({
  list,
  total,
  perPage,
  currentPage,
  setCurrentPage,
  setList,
  loading,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState("");
  const { user } = useContext(AuthContext);

  async function searchHandler(e) {
    const response = await getLeaves();
    let filteredData;
    if (
      user.job_role?.toLowerCase() === "lawyer" ||
      user.job_role?.toLowerCase() === "user"
    ) {
      filteredData = response?.leaves.filter((el) => {
        console.log(el);
        return (
          (el.employee_id === user?.employee_id &&
            el.employee
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase())) ||
          el.status
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.leave_type
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        );
      });
    } else if (
      user?.job_role?.toLowerCase().includes("hod") ||
      user?.job_role?.toLowerCase() === "hop"
    ) {
      filteredData = response?.leaves.filter((el) => {
        return (
          (el.department?.toLowerCase() === user?.department?.toLowerCase() &&
            el.employee
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase())) ||
          el.status
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.leave_type
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        );
      });
    } else {
      filteredData = response?.leaves.filter((el) => {
        return (
          el.employee
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.status
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.leave_type
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        );
      });
    }

    setList(filteredData);
  }

  useEffect(() => {
    async function sortData() {
      if (!status) {
        return;
      } else {
        try {
          const response = await getLeaves();
          let filteredLeave;
          if (
            user.job_role?.toLowerCase() === "lawyer" ||
            user.job_role?.toLowerCase() === "user"
          ) {
            filteredLeave = response?.leaves
              ?.filter((el) => {
                return (
                  el.employee_id === user?.employee_id &&
                  el.status?.toLowerCase() === status
                );
              })
              .reverse();
          } else if (
            user?.job_role?.toLowerCase().includes("hod") ||
            user?.job_role?.toLowerCase() === "hop"
          ) {
            filteredLeave = response?.leaves
              ?.filter((el) => {
                return (
                  el?.department?.toLowerCase() ===
                    user?.department?.toLowerCase() &&
                  el.status?.toLowerCase() === status
                );
              })
              .reverse();
          } else {
            filteredLeave = response?.leaves
              ?.filter((el) => {
                return el.status?.toLowerCase() === status;
              })
              .reverse();
          }

          setList(filteredLeave);
        } catch (error) {
          console.log(error);
        }
      }
    }
    sortData();
  }, [status, user]);

  function allLeave() {
    setStatus("");
  }
  function pendingLeave() {
    setStatus("pending");
  }
  function approvedLeave() {
    setStatus("approved");
  }
  function deniedLeave() {
    setStatus("deny");
  }

  function withdrawnLeave() {
    setStatus("withdrawn");
  }

  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Leave"
          text="Manage your employees leave request here"
          btnText="Request for Leave"
          show={true}
          icon={<AddIcon />}
          onClick={onOpen}
        />
        <ReusableFilter
          onSearch={searchHandler}
          menu={[
            { text: "All", onClick: allLeave },
            { text: "Pending", onClick: pendingLeave },
            { text: "Approved", onClick: approvedLeave },
            { text: "Denied", onClick: deniedLeave },
            { text: "Withdrawn", onClick: withdrawnLeave },
          ]}
        />
        <LeaveTitle />
        <LeaveList list={list} loading={loading} />
        <ReusableFooter
          total={total}
          itemPerPage={perPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
      <RequestLeave isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}
