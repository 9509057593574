import React, { useContext } from "react";
import ViewQuery from "../../query/ViewQuery.js/ViewQuery";
import { AuthContext } from "../../../util/context";
import LoginPage from "../LoginPage";
import { getStoredItem } from "../../../util/lib";
import Dashboard from "../../dashboard/Dashboard";

export default function ViewQueryPage() {
  let view;
  const { isAuthenticated } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  if (userType === "employee") {
    view = <ViewQuery />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated ? view : <LoginPage />}</>;
}
