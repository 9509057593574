import { Flex, useDisclosure } from "@chakra-ui/react";
import { Fragment, useState, useEffect, useContext } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";
import ReusableFilter from "../UI/ReusableFilter";
import RequisitionTitle from "./RequisitionTitle";
import RequisitionList from "./RequisitionList";
import CreateRequisition from "./CreateRequisition";
import ReusableFooter from "../UI/ReusableFooter";
import { getRequisitions } from "../../util/http";
import { AuthContext } from "../../util/context";

export default function RequisitionContainer({
  list,
  total,
  perPage,
  currentPage,
  setCurrentPage,
  setList,
  loading,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function sortData() {
      if (!status) {
        return;
      } else {
        try {
          const res = await getRequisitions();

          let response;

          if (
            user?.job_role?.toLowerCase().includes("hod") ||
            user?.job_role?.toLowerCase() === "hop"
          ) {
            response = res?.requisitions
              ?.filter(
                (el) =>
                  el.brief_type?.toLowerCase() ===
                    user?.department?.toLowerCase() && el?.deleted_at === null
              )
              .reverse();
          } else if (user.job_role?.toLowerCase() === "lawyer") {
            response = res?.requisitions
              ?.filter(
                (el) =>
                  +el.initiator?.initiator_id === +user?.id &&
                  el?.deleted_at === null
              )
              .reverse();
          } else {
            response = res?.requisitions?.reverse();
          }

          const filteredRequisitions = response
            ?.filter((el) => el.status === status && el.deleted_at === null)
            .reverse();

          if (status === "all" || !status) {
            setList(response?.filter((el) => el.deleted_at === null).reverse());
          } else if (status === "archived") {
            setList(response?.filter((el) => el.deleted_at !== null).reverse());
          } else {
            setList(filteredRequisitions);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    sortData();
  }, [status]);

  function allReq() {
    setStatus("all");
  }

  function pendingReq() {
    setStatus("0");
  }
  function HOCReq() {
    setStatus("1");
  }
  function HODReq() {
    setStatus("2");
  }
  function approvedReq() {
    setStatus("3");
  }

  function rejectedReq() {
    setStatus("4");
  }

  function archivedReq() {
    setStatus("5");
  }

  async function searchHandler(e) {
    const value = e.target.value.trim().toLowerCase();
    const response = await getRequisitions();
    let filteredData;
    if (
      user?.job_role?.toLowerCase().includes("hod") ||
      user?.job_role?.toLowerCase() === "hop"
    ) {
      filteredData = response?.requisitions
        ?.filter(
          (el) =>
            el.brief_type?.toLowerCase() === user?.department?.toLowerCase() &&
            el?.brief_title?.toLowerCase()?.includes(value)
        )
        .reverse();
    } else if (user.job_role?.toLowerCase() === "lawyer") {
      filteredData = response?.requisitions
        ?.filter(
          (el) =>
            +el.initiator?.initiator_id === +user?.id &&
            el?.brief_title?.toLowerCase()?.includes(value)
        )
        .reverse();
    } else {
      filteredData = response?.requisitions
        ?.filter((el) => el?.brief_title?.toLowerCase()?.includes(value))
        .reverse();
    }

    setList(filteredData);
  }

  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Requisitions"
          text="Manage your requisition and their details here"
          btnText="Create Requisition"
          show={user?.job_role?.toLowerCase() !== "accountant"}
          icon={<AddIcon />}
          onClick={onOpen}
        />
        <ReusableFilter
          menu={[
            { text: "All", onClick: allReq },
            { text: "Pending", onClick: pendingReq },
            { text: "Approved", onClick: approvedReq },
            { text: "Approved By HOD", onClick: HODReq },
            { text: "Approved by HOC", onClick: HOCReq },
            { text: "Rejected", onClick: rejectedReq },
            { text: "Archived", onClick: archivedReq },
          ]}
          onSearch={searchHandler}
        />
        <RequisitionTitle />
        <RequisitionList loading={loading} list={list} />
        <ReusableFooter
          total={total}
          itemPerPage={perPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
      <CreateRequisition isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}
