import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function generateDayWiseTimeSeries(baseval, count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = baseval;
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([x, y]);
    baseval += 86400000;
    i++;
  }
  return series;
}

export default function AreaChart({ data, name }) {
  const [chart, setChart] = useState({
    series: [
      {
        name: "Data",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
    ],
    options: {
      chart: {
        id: "fb",
        group: "social",
        type: "line",
        height: 160,
      },
      colors: ["#008FFB"],
    },

    optionsArea: {
      chart: {
        id: "yt",
        group: "social",
        type: "area",
        height: 160,
      },
      colors: ["#00E396"],
    },
  });

  useEffect(() => {
    setChart({
      series: [
        {
          name: name,
          data: data,
        },
      ],
    });
  }, [data, name]);

  return (
    <Box w="100%">
      <ReactApexChart
        options={chart.options}
        series={chart.series}
        type="area"
        height={350}
        width="100%"
      />
    </Box>
  );
}
