import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import PersonalDetail from "./PersonalDetail";
import PasswordContainer from "./password/PasswordContainer";
import Notification from "./notification/Notification";
import { useContext } from "react";
import { AuthContext } from "../../util/context";
import { getStoredItem } from "../../util/lib";
import Updates from "./updates/Updates";

export default function ProfileTab() {
  const ctx = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  return (
    <Tabs w="100%" variant="soft-rounded" colorScheme="gray">
      <TabList>
        <Tab>My Details</Tab>
        {userType === "employee" && <Tab>My Updates</Tab>}
        <Tab>Password</Tab>
        {userType === "employee" && false && <Tab>Notifications</Tab>}
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box w="full">
            <PersonalDetail user={ctx?.user} />
          </Box>
        </TabPanel>
        {userType === "employee" && (
          <TabPanel>
            <Updates
              id={ctx?.user?.id}
              name={`${ctx?.user?.first_name} ${ctx?.user?.last_name}`}
              designation={ctx?.user?.designation}
              department={ctx?.user?.department}
            />
          </TabPanel>
        )}
        <TabPanel>
          <PasswordContainer />
        </TabPanel>
        {userType === "employee" && false && (
          <TabPanel>
            <Notification />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
