import { Flex } from "@chakra-ui/react";
import React from "react";
import FileItem from "./FileItem";

export default function FileList({ setShow, list }) {
  return (
    <Flex align="start" direction="column" gap="1rem" w="100%">
      {list?.length > 0 ? (
        <>
          {list?.map((item, i) => (
            <FileItem key={i} item={item} />
          ))}
        </>
      ) : (
        <Flex w="100%" align="center" justify="center" h="300px">
          No file found...
        </Flex>
      )}
    </Flex>
  );
}
