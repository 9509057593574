import { Flex, Card, Text, CardBody, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { getKPI } from "../../../../util/http";
import KPIHistory from "./KPIHistory";

export default function KPI() {
  const [kpi, setKpi] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getKPI();

        setKpi(response);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {show && <KPIHistory show={setShow} />}
      <Card
        style={{
          boxShadow:
            "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
        }}
        w={{ lg: "30%", md: "45%", base: "90%" }}
      >
        <CardBody>
          <Flex w="100%" align="start" direction="column" gap="0.5rem">
            <Flex w="100%" align="center" justify="space-between">
              <Text>KPI</Text>
              <Heading size="md" color="#000">
                <AiOutlineArrowDown
                  onClick={() => setShow(true)}
                  fontSize={24}
                  cursor="pointer"
                />
              </Heading>
            </Flex>
            <Flex w="100%" align="center" justify="space-between">
              <Heading size="md" color="green.600">
                {kpi?.positive_points}
              </Heading>
              <Heading size="md" color="red.600">
                {kpi?.negative_points}
              </Heading>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </>
  );
}
