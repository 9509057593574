import { Flex, Text } from "@chakra-ui/react";
import Cleave from "cleave.js/react";

export default function ReusableAmountInput({
  onChange,
  placeholder,
  label,
  onBlur,
  type = true,
  value,
}) {
  return (
    <Flex w="100%" align="start" direction="column" gap={label ? "8px" : ""}>
      <Text className="">{label}</Text>
      <Flex
        align="center"
        border="1px solid #ccc"
        borderRightRadius={type ? "" : "0.5rem"}
        w="100%"
        borderRadius={type ? "8px" : ""}
        justify="center"
        padding={type ? "0.5rem" : "0.45rem"}
      >
        <Cleave
          placeholder={placeholder}
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
          }}
          style={{
            border: "none",
            outline: "none",
            width: "100%",
            background: "transparent",
          }}
          onChange={(e) => onChange(e)}
          onKeyUp={!type ? onBlur : () => {}}
          value={value}
        />
      </Flex>
    </Flex>
  );
}
