import { Flex, useToast } from "@chakra-ui/react";
import { MdOutlineFileUpload } from "react-icons/md";
import { AiOutlineFolder } from "react-icons/ai";
import { HiOutlineUsers } from "react-icons/hi";
import { getStorageCapacity, uploadDocument } from "../../util/http";
import { useState } from "react";
import Spinner from "../UI/Spinner";
import ReusableText from "../UI/ReusableText";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function DocumentSide({ setShow, setFile }) {
  const toast = useToast();
  const [active, setActive] = useState("folder");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getStorageCapacity();
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <Flex
        align="start"
        direction="column"
        borderRight="1px solid #ccc"
        w="20%"
        justify="space-between"
        pr="1rem"
        h="75vh"
        mt="2rem"
      >
        <Flex w="100%" align="start" direction="column" gap="1rem">
          <label
            htmlFor="file"
            style={{
              width: "100%",
              padding: "0.6rem",
              border: "1px solid #364273",
              color: "#364273",
              borderRadius: "8px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <MdOutlineFileUpload />
            <input
              onChange={async (e) => {
                setFile(e.target.files[0]);
                setShow(true);
                try {
                  setLoading(true);
                  await uploadDocument({
                    "documents[]": e.target.files[0],
                  });

                  setLoading(false);
                  toast({
                    title: `Document uploaded`,
                    description: ``,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                  });
                } catch (error) {
                  setLoading(false);
                  // toast({
                  //   title: `Error`,
                  //   description: `${
                  //     error?.response?.data.message || "something went wrong"
                  //   }`,
                  //   status: "error",
                  //   duration: 3000,
                  //   isClosable: true,
                  //   position: "top-right",
                  // });
                }
              }}
              type="file"
              id="file"
              style={{ display: "none" }}
            />
            Upload Document
          </label>
          <p
            onClick={() => setActive("folder")}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "0.5rem",
              cursor: "pointer",
              borderRadius: active === "folder" ? "8px" : "",
              backgroundColor: active === "folder" ? "#eee" : "",
              width: "100%",
            }}
            to="/document/folder"
          >
            <AiOutlineFolder />
            Folder
          </p>
          <p
            onClick={() => {
              setActive("shared");
              navigate(`/document/shared-documents`);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
              padding: "0.5rem",
              borderRadius: active === "shared" ? "8px" : "",
              backgroundColor: active === "shared" ? "#eee" : "",
              width: "100%",
            }}
          >
            <HiOutlineUsers />
            Shared with me
          </p>
        </Flex>
        <Flex w="100%" direction="column" gap="1rem" align="start">
          <Flex
            w="100%"
            h="5px"
            border="1px solid #ccc"
            bg="transparent"
            borderRadius="1rem"
          >
            <Flex w="40%" bg="brand.900"></Flex>
          </Flex>
          <Flex w="100%" align="center" gap="1rem">
            <ReusableText text="Available Storage:" weight="normal" />
            <ReusableText text="1GIG" weight="normal" />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
