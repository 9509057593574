import { Flex, useDisclosure } from "@chakra-ui/react";
import { Fragment, useContext } from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import ReusableFilter from "../../UI/ReusableFilter";
import InvoiceList from "./InvoiceList";
import ReusableFooter from "../../UI/ReusableFooter";
import DeleteModal from "../../UI/DeleteModal";
import InvoiceTitle from "./InvoiceTitle";
import { useState } from "react";
import { useEffect } from "react";
import { getInvoices } from "../../../util/http";
import Loader from "../../UI/Loader";
import { getStoredItem } from "../../../util/lib";
import { AuthContext } from "../../../util/context";

export default function AllInvoiceContainer({
  list,
  setList,
  total,
  perPage,
  currentPage,
  setCurrentPage,
  loading,
}) {
  const userType = getStoredItem("user-type");
  const { user } = useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function sortInvoiceHandler() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);

        const res = await getInvoices(status);

        let response;

        if (
          user?.job_role?.toLowerCase().includes("hod") ||
          user?.job_role?.toLowerCase() === "hop"
        ) {
          response = res?.invoices
            ?.filter(
              (el) =>
                el.deleted_at === null &&
                String(el?.user_id) === String(user?.id)
            )
            .reverse();
        } else {
          response = res?.invoices
            ?.filter((el) => el.deleted_at === null)
            .reverse();
        }

        if (status === "archived") {
          setList(
            response?.invoices?.filter((el) => el.deleted_at !== null).reverse()
          );
        }

        const filteredInvoice = response
          ?.filter((el) => el.status.toLowerCase() === status)
          .reverse();

        setList(status ? filteredInvoice : response);

        setProgress(80);
        setProgress(100);
      } catch (error) {
        setProgress(100);
        console.log(error);
      }
    }
    sortInvoiceHandler();
  }, [status, user]);

  function pendingInvoice() {
    setStatus("pending");
  }

  function partialInvoice() {
    setStatus("partial");
  }

  function paidInvoice() {
    setStatus("paid");
  }

  function allInvoice() {
    setStatus("");
  }

  function archivedInvoice() {
    setStatus("archived");
  }

  async function searchHandler(e) {
    const response = await getInvoices();
    let filteredData;
    if (
      user?.job_role?.toLowerCase().includes("hod") ||
      user?.job_role?.toLowerCase() === "hop"
    ) {
      filteredData = response?.invoices.filter((el) => {
        const match =
          el.brief_title
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.client
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.invoice_id
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase());
        return match && String(el?.user_id) === String(user?.id);
      });
      setList(filteredData);
    } else {
      filteredData = response?.invoices.filter(
        (el) =>
          el.brief_title
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.client
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.invoice_id
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
      );
      setList(filteredData);
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Invoice"
          text="Manage your invoice and their details here"
          showMenu={userType === "employee" ? true : false}
          btnText="Create"
          icon={userType === "employee" && <AddIcon />}
          menu={[
            {
              name: "Retainership",
              link: `/invoicing/create/retainer`,
            },
            {
              name: "Invoice",
              link: `/invoicing/create/invoice`,
            },
          ]}
        />
        <ReusableFilter
          menu={[
            { text: "All", onClick: allInvoice },
            { text: "Pending", onClick: pendingInvoice },
            { text: "Partial", onClick: partialInvoice },
            { text: "Paid", onClick: paidInvoice },
            { text: "Archived", onClick: archivedInvoice },
          ]}
          onSearch={searchHandler}
        />
        <Flex w="100%" direction="column">
          <InvoiceTitle />
          <InvoiceList loading={loading} list={list} onOpen={onOpen} />
          <ReusableFooter
            total={total}
            itemPerPage={perPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Flex>
      </Flex>
      <DeleteModal
        title="Delete Invoice?"
        text="Are you sure you would like to delete “Invoice ID 2303/0085”?  "
        modalText="Invoice Deleted"
        modalTitle="You have successfully deleted “Invoice ID 2303/0085”"
        onClose={onClose}
        isOpen={isOpen}
      />
    </Fragment>
  );
}
