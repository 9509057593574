import { CloseButton, Heading, Flex, Button, useToast } from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import SelectComponent from "../UI/Select";
import TextareaComponent from "../UI/Textarea";
import { suspendEmployee } from "../../util/http";
import { useState } from "react";

export default function SuspendEmployee({ show, name, id }) {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const toast = useToast();

  function closeModal() {
    show(false);
  }

  async function suspendHandler() {
    if (!reason) {
      toast({
        title: `Select reason for suspension`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await suspendEmployee(id);
      window.location.reload();
      toast({
        title: `Successfully suspended ${name}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            size="md"
            color="#333"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Suspend {name}
          </Heading>
          <CloseButton onClick={closeModal} colorScheme="red" />
        </Flex>
        <SelectComponent
          onChange={(e) => setReason(e.value)}
          label="Select Reason"
          options={[]}
        />
        <TextareaComponent
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          label="Add comment"
        />
        <Button
          isLoading={loading}
          loadingText="Submitting"
          onClick={suspendHandler}
          bg="brand.900"
          color="#fff"
          size="md"
          w="100%"
        >
          Suspend
        </Button>
      </Flex>
    </Modal>
  );
}
