import { Flex } from "@chakra-ui/react";
import React from "react";
import DashboardSide from "../dashboard/DashboardSide";

export default function DashboardReusable({ children }) {
  return (
    <Flex
      h="100vh"
      position="fixed"
      align="center"
      overflow="scroll"
      w="100%"
      bg="#101828"
      pt="1rem"
    >
      <DashboardSide />
      <Flex
        bg="white"
        overflow="scroll"
        height="100vh"
        // mt="8rem"
        p="1rem"
        borderLeftRadius="2rem"
        flex="1"
      >
        {children}
      </Flex>
    </Flex>
  );
}
