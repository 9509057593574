import { Flex } from "@chakra-ui/react";
import React from "react";
import InvoiceTitle from "./InvoiceTitle";
import InvoiceList from "./InvoiceList";

export default function Invoices({ invoices, brief }) {
  return (
    <Flex w="100%" align="center" direction="column">
      <InvoiceTitle />
      <InvoiceList brief={brief} invoices={invoices} />
    </Flex>
  );
}
