import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import LoginPage from "./components/pages/LoginPage";
import CompleteSignUpPage from "./components/pages/CompleteSignupPage";
import VerifyLoginPage from "./components/pages/VerifyLoginPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
import CheckEmailPage from "./components/pages/CheckEmailPage";
import NewPasswordPage from "./components/pages/NewPasswordPage";
import PasswordResetSuccessPage from "./components/pages/PasswordResetSuccessPage";
import DashboardPage from "./components/pages/DashboardPage";
import EmployeePage from "./components/pages/employee/EmployeePage";
import CreateEmployeePage from "./components/pages/employee/CreateEmployeePage";
import ViewEmployeePage from "./components/pages/employee/ViewEmployeePage";
import SettingPage from "./components/pages/setting/SettingPage";
import CreateRolePage from "./components/pages/setting/CreateRolePage";
import ClientPage from "./components/pages/client/ClientPage";
import CreateClientPage from "./components/pages/client/CreateClientPage";
import ViewClientPage from "./components/pages/client/ViewClientPage";
import BriefPage from "./components/pages/brief/BriefPage";
import CreateBriefPage from "./components/pages/brief/CreateBriefPage";
import ViewBriefPage from "./components/pages/brief/ViewBriefPage";
import TaskPage from "./components/pages/tasks/TaskPage";
import DocumentPage from "./components/pages/document/DocumentPage";
import ReportPage from "./components/pages/report/ReportPage";
import QueryPage from "./components/pages/query/QueryPage";
import InvoicePage from "./components/pages/invoice/InvoicePage";
import CreateInvoicePage from "./components/pages/invoice/CreateInvoicePage";
import ViewInvoicePage from "./components/pages/invoice/ViewInvoicePage";
import ProfilePage from "./components/pages/profile/ProfilePage";
import EditProfilePage from "./components/pages/profile/EditProfilePage";
import RequisitionPage from "./components/pages/requisition/RequisitionPage";
import ViewRequisitionPage from "./components/pages/requisition/ViewRequisitionPage";
import LeavePage from "./components/pages/leave/LeavePage";
import LeaveDetailPage from "./components/pages/leave/LeaveDetailPage";
import ViewTaskPage from "./components/pages/tasks/ViewTask";
import EditEmployeePage from "./components/pages/employee/EditEmployeePage";
import ViewQueryPage from "./components/pages/query/ViewQueryPage";
import CalenderPage from "./components/pages/calender/CalenderPage";
import ViewDocumentPage from "./components/pages/document/ViewDocumentPage";
import AppraisalPage from "./components/pages/appraisal/AppraisalPage";
import ViewAppraisal from "./components/pages/appraisal/ViewAppraisal";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/complete-signup?" element={<CompleteSignUpPage />} />
      <Route path="/verify-login" element={<VerifyLoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/check-email" element={<CheckEmailPage />} />
      <Route path="/new-password" element={<NewPasswordPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/employees" element={<EmployeePage />} />
      <Route path="/employees/create" element={<CreateEmployeePage />} />
      {false && (
        <Route path="/employees/:id/edit" element={<EditEmployeePage />} />
      )}
      <Route path="/employees/:id" element={<ViewEmployeePage />} />
      <Route path="/settings" element={<SettingPage />} />
      <Route path="/settings/create-role" element={<CreateRolePage />} />
      <Route path="/client" element={<ClientPage />} />
      <Route path="/client/create/:type" element={<CreateClientPage />} />
      <Route
        path="/password-reset-success"
        element={<PasswordResetSuccessPage />}
      />
      <Route path="/appraisal" element={<AppraisalPage />} />
      <Route path="/appraisal/:id" element={<ViewAppraisal />} />
      <Route path="/client/:id" element={<ViewClientPage />} />
      <Route path="/brief" element={<BriefPage />} />
      <Route path="/brief/:id" element={<ViewBriefPage />} />
      <Route path="/brief/create" element={<CreateBriefPage />} />
      <Route path="/task" element={<TaskPage />} />
      <Route path="/task/:id" element={<ViewTaskPage />} />
      <Route path="/document" element={<DocumentPage />} />
      <Route path="/document/:id" element={<ViewDocumentPage />} />
      <Route path="/reports" element={<ReportPage />} />
      <Route path="/query" element={<QueryPage />} />
      <Route path="/query/:id" element={<ViewQueryPage />} />
      <Route path="/invoicing" element={<InvoicePage />} />
      <Route path="/invoicing/:id" element={<ViewInvoicePage />} />
      <Route path="/invoicing/create/:type" element={<CreateInvoicePage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/profile/edit" element={<EditProfilePage />} />
      <Route path="/requisitions" element={<RequisitionPage />} />
      <Route path="/requisitions/:id" element={<ViewRequisitionPage />} />
      <Route path="/leave" element={<LeavePage />} />
      <Route path="/leave/:id" element={<LeaveDetailPage />} />
      <Route path="/calendar" element={<CalenderPage />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
