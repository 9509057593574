import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

export default function PerformanceAndReward() {
  return (
    <Flex border="1px solid #ccc" w="100%" align="start" direction="column">
      <Flex
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        justify="space-between"
        p="0.6rem"
      >
        <ReusableText
          w="50%"
          text="PERFORMANCE SCORE"
          color="#7F7E7E"
          size="18px"
          weight="bold"
        />
        <ReusableText
          w="50%"
          text="REWARD"
          color="#7F7E7E"
          size="18px"
          weight="bold"
        />
      </Flex>

      <Flex
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        justify="space-between"
        p="0.4rem"
      >
        <ReusableText
          w="50%"
          text="85-100%"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
        <ReusableText
          w="50%"
          text="Commendation, Salary Review, Promotion & Performance Bonus"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
      </Flex>

      <Flex
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        justify="space-between"
        p="0.4rem"
      >
        <ReusableText
          w="50%"
          text="75 – 84.9%"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
        <ReusableText
          w="50%"
          text="Salary Review and Promotion"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
      </Flex>

      <Flex
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        justify="space-between"
        p="0.4rem"
      >
        <ReusableText
          w="50%"
          text="60 – 74.9%"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
        <ReusableText
          w="50%"
          text="Salary Review and/or Promotion"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
      </Flex>

      <Flex
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        justify="space-between"
        p="0.4rem"
      >
        <ReusableText
          w="50%"
          text="40 - 59.9%"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
        <ReusableText
          w="50%"
          text="Needs improvement"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
      </Flex>

      <Flex
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        justify="space-between"
        p="0.4rem"
      >
        <ReusableText
          w="50%"
          text="Below 39.9% "
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
        <ReusableText
          w="50%"
          text="Probation / Advise to Resign after 2 consecutive poor performances"
          color="#7F7E7E"
          size="16px"
          weight="medium"
        />
      </Flex>
    </Flex>
  );
}
