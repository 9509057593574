import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableDownloadDoc from "../../UI/ReusableDownloadDoc";

export default function Documents({ docs }) {
  return (
    <Flex
      py="1rem"
      borderBottom="1px solid #ccc"
      w="100%"
      align="center"
      gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
      direction={{ lg: "row", md: "column", base: "column" }}
    >
      <Flex
        w={{ lg: "30%", md: "100%", base: "100%" }}
        height={{ lg: "100px" }}
      >
        <ReusableText
          text="Documents"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          color="#515050"
          weight="bold"
        />
      </Flex>
      <ReusableDownloadDoc docs={docs} />
    </Flex>
  );
}
