import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function QueryItem({ onOpen, item }) {
  const navigate = useNavigate();
  return (
    <Flex
      px="0.5rem"
      py="1rem"
      borderBottom="1px solid #ccc"
      align="center"
      w="100%"
      justify="center"
    >
      <ReusableText
        text={item?.query_id}
        w="10%"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        align="center"
        onClick={() => navigate(`/query/${item?.query_id}`)}
      />
      <ReusableText
        text={new Date(item?.date_initiated)?.toLocaleDateString()}
        w="10%"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        align="center"
        onClick={() => navigate(`/query/${item?.query_id}`)}
      />
      <ReusableText
        text={item?.initiator?.slice(0, 20)}
        w="20%"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        align="center"
        transform={true}
        onClick={() => navigate(`/query/${item?.query_id}`)}
      />
      <ReusableText
        text={item?.description?.slice(0, 20)}
        title={item?.description}
        w="20%"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        align="center"
        transform={true}
        onClick={() => navigate(`/query/${item?.query_id}`)}
      />
      <ReusableText
        text={item?.assignee?.slice(0, 20)}
        w="20%"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        align="center"
        transform={true}
        onClick={() => navigate(`/query/${item?.query_id}`)}
      />
      <ReusableText
        text={item?.decision}
        w="10%"
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        align="center"
        transform={true}
        onClick={() => navigate(`/query/${item?.query_id}`)}
      />
      <Flex
        fontSize={{ lg: "14px", md: "12px", base: "6px" }}
        align="center"
        w="10%"
        justify="space-evenly"
      >
        <AiOutlineEye
          cursor="pointer"
          onClick={() => navigate(`/query/${item?.query_id}`)}
        />
      </Flex>
    </Flex>
  );
}
