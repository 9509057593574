import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function InvoiceItem({ onOpen, path, item, title, brief }) {
  const navigate = useNavigate();
  return (
    <Flex
      w="100%"
      align="center"
      px="1rem"
      py="1rem"
      cursor="pointer"
      _hover={{ opacity: "80%" }}
      borderBottom="1px solid #333"
    >
      <ReusableText
        w="20%"
        size={{ lg: "14px", md: "12px", base: "5px" }}
        text={item?.invoice_id}
        align="center"
      />
      <ReusableText
        size={{ lg: "14px", md: "12px", base: "5px" }}
        color="#515050"
        w="25%"
        text={brief?.client}
        transform={true}
      />
      <ReusableText
        size={{ lg: "14px", md: "12px", base: "5px" }}
        color="#515050"
        w="15%"
        text={title?.slice(0, 20)}
        transform={true}
      />
      <ReusableText
        size={{ lg: "14px", md: "12px", base: "5px" }}
        color="#515050"
        align="center"
        w="20%"
        text={`₦${new Intl.NumberFormat().format(item?.grand_total)}.00`
          .toString()
          .slice(0, 20)}
      />
      <Flex w="15%" align="center" justify="center" gap="0.5rem">
        <Box
          w="0.4rem"
          height="0.4rem"
          borderRadius="100%"
          bg={
            item?.status?.toLowerCase() === "pending"
              ? "#333"
              : item?.status?.toLowerCase() === "partial"
              ? "yellow.500"
              : "#1FA159"
          }
        ></Box>
        <ReusableText
          align="center"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          color={
            item?.status?.toLowerCase() === "pending"
              ? "#333"
              : item?.status?.toLowerCase() === "partial"
              ? "yellow.500"
              : "#1FA159"
          }
          text={item?.status}
          transform={true}
        />
      </Flex>

      <Flex
        size={{ lg: "14px", md: "12px", base: "5px" }}
        align="center"
        w="5%"
        justify="center"
      >
        <AiOutlineEye
          onClick={() => navigate(`/invoicing/${item?.invoice_id}`)}
        />
      </Flex>
    </Flex>
  );
}
