import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { Fragment, useState } from "react";
import { BiFile } from "react-icons/bi";
import ReusableText from "../UI/ReusableText";
import ShareDocument from "./ShareDocument";
import { BsThreeDotsVertical } from "react-icons/bs";
import { deleteDocument } from "../../util/http";

export default function FileItem({ item }) {
  const newArr = item?.path?.split("/");
  newArr?.splice(0, 3);
  const newPath = newArr?.join("/");

  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState({
    id: "",
    origin: "",
  });
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  async function deleteHandler() {
    const data = {
      filename: item?.file_name,
      origin: item?.origin,
    };

    try {
      setLoading(true);
      await deleteDocument(data);
      toast({
        title: `${item?.file_name} deleted`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      {show && <ShareDocument detail={detail} show={setShow} />}

      <Flex
        borderBottom="1px solid #ccc"
        w="100%"
        align="center"
        justify="space-between"
        py="1rem"
        cursor="pointer"
      >
        <Flex align="center" gap="1rem">
          <BiFile cursor="pointer" fontSize="30px" color="#333" />
          <ReusableText
            text={item?.file_name}
            size="16px"
            color="#3F434A"
            weight="medium"
            title={item?.file_name}
          />
        </Flex>
        <Menu>
          <MenuButton bg="transparent" as={Button} cursor="pointer">
            <BsThreeDotsVertical />
          </MenuButton>
          <MenuList>
            <MenuItem>
              <a
                href={`https://${newPath}`}
                download={item?.file_name}
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDetail({ id: item?.id, origin: item.origin });
                setShow(true);
              }}
            >
              Share
            </MenuItem>
            <MenuItem onClick={deleteHandler}>Delete</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Fragment>
  );
}
