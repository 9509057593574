import React from "react";
import Invoice from "../../invoice/Invoice";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";
import { getStoredItem } from "../../../util/lib";

export default function CreateInvoicePage() {
  let view;
  const { isAuthenticated, user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const viewInvoice =
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    userType === "client" ||
    user?.job_role?.toLowerCase() === "seed";

  if (viewInvoice) {
    view = <Invoice />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated ? view : <Login />}</>;
}
