import { Button, Divider, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import Overlay from "../overlay/OverLay";
import InputComponent from "../UI/Input";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";

export default function CreateAppraisal({ show }) {
  const [title, setTitle] = useState("");
  const [due_date, setDueDate] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  function closeMenu() {
    show(false);
  }

  async function createHandler() {
    const data = {
      title,
      due_date,
    };

    try {
      setLoading(true);

      closeMenu();
      toast({
        title: `Appraisal Created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="#333">
              Create a new Appraisal
            </Heading>
            <Button variant="ghost" onClick={closeMenu} colorScheme="red">
              <AiOutlineClose />
            </Button>
          </Flex>
          <Divider />
          <InputComponent
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder="Enter title"
            label="Appraisal Title"
          />
          <InputComponent
            onChange={(e) => setDueDate(e.target.value)}
            value={due_date}
            type="datetime-local"
            label="Due Date"
          />
          <Flex w="100%" align="start">
            <Button
              isLoading={loading}
              loadingText=""
              onClick={createHandler}
              variant="solid"
              bg="brand.900"
              color="white"
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
