import { Flex, Button, Divider, Heading } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Modal from "../../overlay/Modal";
import { useEffect, useState } from "react";
import { getBriefs } from "../../../util/http";
import NewBriefChart from "../charts/NewBriefChart";
import { filterDate } from "../../../util/lib";

function briefFilter(data, type) {
  return data?.filter((el) => el.brief_type?.toLowerCase() === type).length;
}

export default function BriefReportDetail({ show }) {
  const [depts, setDepts] = useState([]);
  const [days, setDays] = useState(0);
  const [filter, setFilter] = useState("weekly");

  useEffect(() => {
    if (filter?.toLowerCase() === "daily") {
      setDays(1);
    } else if (filter?.toLowerCase() === "weekly") {
      setDays(7);
    } else if (filter?.toLowerCase() === "monthly") {
      setDays(30);
    } else {
      setDays("");
    }
  }, [filter]);

  function closeModal() {
    show(false);
  }

  const [department, setDepartment] = useState({
    administration: 0,
    departmentOfPractice: 0,
    businessDevelopment: 0,
    corporateCommercial: 0,
    disputeManagement: 0,
    employment: 0,
    ict: 0,
    maritime: 0,
    propertyLaw: 0,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBriefs();
        let briefs;
        if (!filter) {
          briefs = response?.briefs;
        } else {
          briefs = response?.briefs?.filter((el) => {
            if (!days) {
              return el;
            }
            const milliSec = +days * 24 * 60 * 60 * 1000;
            const startDate = filterDate(milliSec);
            const createdDate = new Date(el?.created_at);
            return startDate < createdDate;
          });
        }

        setDepartment({
          administration: briefFilter(briefs, "administration"),
          departmentOfPractice: briefFilter(briefs, "department of practice"),
          businessDevelopment: briefFilter(briefs, "business development"),
          corporateCommercial: briefFilter(briefs, "corporate and commercial"),
          disputeManagement: briefFilter(
            briefs,
            "dispute management and resolution"
          ),
          employment: briefFilter(briefs, "employment and labour law"),
          ict: briefFilter(briefs, "ict and media"),
          maritime: briefFilter(briefs, "maritime and aviation"),
          propertyLaw: briefFilter(briefs, "property law"),
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [days, filter]);

  useEffect(() => {
    setDepts(Object.values(department));
  }, [department]);

  return (
    <Modal hide={() => closeModal()} title="Client">
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="#222" fontWeight={600}>
          Brief Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent
              onChange={(e) => setFilter(e.value)}
              label="Briefs"
              options={["Daily", "Weekly", "Monthly", "All Time"]}
            />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
          >
            Generate Report
          </Button>
        </Flex>

        <NewBriefChart data={depts} />
      </Flex>
    </Modal>
  );
}
