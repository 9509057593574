import React from "react";
import DashboardReusable from "../UI/DashboardReusable";
import SettingContainer from "./SettingContainer";

export default function Setting() {
  return (
    <DashboardReusable>
      <SettingContainer />
    </DashboardReusable>
  );
}
