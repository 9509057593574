import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { BiFile } from "react-icons/bi";
import ReusableText from "./ReusableText";
import { AiOutlineDownload } from "react-icons/ai";

export default function ReusableDownloadDoc({ docs }) {
  const [file, setFile] = useState("");

  return (
    <>
      <Flex w="100%" align="start" direction="column" gap="1rem">
        {docs?.map((item, i) => {
          const newArr = item?.path?.split("/");
          newArr.splice(0, 3);
          const newPath = newArr.join("/");

          return (
            <Flex
              key={i}
              align="center"
              w={{ lg: "70%", md: "100%", base: "100%" }}
              justify="space-between"
            >
              <Flex
                align="center"
                fontSize={{ lg: "16px", md: "14px", base: "10px" }}
                gap="1rem"
              >
                <BiFile color="#333" />
                <ReusableText
                  text={item.file_name.slice(0, 60)}
                  color="#515050"
                  weight="medium"
                  size={{ lg: "16px", md: "14px", base: "10px" }}
                  transform={true}
                />
              </Flex>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.2rem",
                  cursor: "pointer",
                }}
                href={`https://${newPath}`}
                download={item?.file_name}
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineDownload
                  onClick={() => setFile(`https://${newPath}`)}
                  cursor="pointer"
                  fontSize="22px"
                />
              </a>
            </Flex>
          );
        })}
      </Flex>
    </>
  );
}
