import { Divider, Flex, Button } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import Overlay from "../../overlay/OverLay";
import ReusableText from "../../UI/ReusableText";
import ReusableDownloadDoc from "../../UI/ReusableDownloadDoc";
import { AiOutlineClose } from "react-icons/ai";

export default function UpdateDetail({ show, detail }) {
  function closePopup() {
    show(false);
  }
  return (
    <>
      <Overlay hide={() => closePopup()} />
      <Modal hide={() => closePopup()}>
        <Flex
          w="100%"
          align="start"
          direction="column"
          py="1.5rem"
          gap="1.5rem"
        >
          <Flex w="100%" align="center" justify="space-between">
            <ReusableText
              text={detail?.update_type}
              color="#333"
              weight="bold"
              size="18px"
            />
            <Button variant="ghost" onClick={closePopup} colorScheme="red">
              <AiOutlineClose />
            </Button>
          </Flex>
          <Divider />
          <Flex w="100%" align="center" justify="space-between">
            <Flex w="50%" align="start" direction="column" gap="0.3rem">
              <ReusableText text="Update Type" size="16px" weight="medium" />
              <ReusableText
                text={detail?.update_type}
                size="14px"
                weight="normal"
              />
            </Flex>
            <Flex w="50%" align="start" direction="column" gap="0.3rem">
              <ReusableText text="Date" size="16px" weight="medium" />
              <ReusableText
                text={new Date(detail?.created_at).toLocaleDateString()}
                size="14px"
                weight="normal"
              />
            </Flex>
          </Flex>
          <Flex w="100%" align="start" direction="column">
            <ReusableText text="Description" size="16px" weight="medium" />
            <ReusableText
              text={detail?.description}
              size="14px"
              weight="normal"
            />
          </Flex>
          <Flex w="100%" align="start" direction="column" gap="0.2rem">
            <ReusableText
              text="Download Attachment"
              size="16px"
              weight="medium"
            />
            <ReusableDownloadDoc
              docs={[{ file_name: detail?.file_name, path: detail?.path }]}
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
