import { Flex, Avatar } from "@chakra-ui/react";
import React, { Fragment, useContext, useState } from "react";
import ReusableText from "../UI/ReusableText";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineBlock } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../util/context";
import { TbCurrencyNaira } from "react-icons/tb";
import SalaryProgress from "./view employee/SalaryProgress";
import SuspendEmployee from "./Suspend";
import TerminateEmployee from "./TerminateEmployee";

export default function EmployeeItem({ item }) {
  const navigate = useNavigate();
  const [showSalary, setShowSalary] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [showTerminate, setShowTerminate] = useState(false);
  const [id, setId] = useState("");

  const { user } = useContext(AuthContext);

  return (
    <Fragment>
      {showTerminate && (
        <TerminateEmployee
          show={setShowTerminate}
          name={`${item.first_name} ${item?.last_name}`}
          id={id}
        />
      )}
      {showSuspend && (
        <SuspendEmployee
          id={id}
          show={setShowSuspend}
          name={`${item.first_name} ${item?.last_name}`}
        />
      )}
      {showSalary && (
        <SalaryProgress
          id={item?.id}
          name={`${item.first_name} ${item?.last_name}`}
          show={setShowSalary}
          designation={item?.designation}
          department={item?.department}
        />
      )}
      <Flex
        w="100%"
        align="center"
        px="1rem"
        py="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        borderBottom="1px solid #333"
      >
        <Flex display={{ lg: "flex", md: "none", base: "none" }} w="5%">
          <Avatar size="xs" name={`${item.first_name} ${item.last_name}`} />
        </Flex>
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          w="10%"
          text={item.employee_id}
          align="start"
        />
        <ReusableText
          w="15%"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          transform={true}
          onClick={() => navigate(`/employees/${item.id}`)}
          text={`${item.first_name} ${item.last_name}`}
          title={`${item.first_name} ${item.last_name}`}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          w="25%"
          text={item.email.slice(0, 25)}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          w="20%"
          text={item.phone_number}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          w="15%"
          text={
            item?.job_role?.toLowerCase() === "seed"
              ? "Management"
              : item?.job_role?.toLowerCase() === "hr"
              ? "Human Resources"
              : item?.job_role
          }
        />
        <Flex
          fontSize={{ lg: "14px", md: "12px", base: "12px" }}
          align="center"
          w="10%"
          justify="space-between"
        >
          <button
            size="sm"
            fontSize={{ lg: "14px", md: "12px", base: "12px" }}
            onClick={() => navigate(`/employees/${item.id}`)}
          >
            <AiOutlineEye />
          </button>
          <button
            size="sm"
            fontSize={{ lg: "14px", md: "12px", base: "12px" }}
            onClick={() => {
              setId(item?.id);
              setShowSuspend(true);
            }}
            disabled={
              item?.is_banned !== "0" ||
              item?.job_role?.toLowerCase() === "seed" ||
              item?.id === user?.id
            }
          >
            <MdOutlineBlock />
          </button>
          <button
            size="sm"
            fontSize={{ lg: "14px", md: "12px", base: "12px" }}
            onClick={() => setShowTerminate(true)}
            disabled={
              item?.deleted_at !== null ||
              item?.job_role?.toLowerCase() === "seed" ||
              item?.id === user?.id
            }
          >
            <AiOutlineDelete />
          </button>{" "}
          <button
            size="sm"
            fontSize={{ lg: "14px", md: "12px", base: "12px" }}
            onClick={() => setShowSalary(true)}
          >
            <TbCurrencyNaira />
          </button>
        </Flex>
      </Flex>
    </Fragment>
  );
}
