import { Avatar, Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../../UI/ReusableText";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function InvoiceItem({ item }) {
  const navigate = useNavigate();

  return (
    <Flex py="1rem" align="center" w="100%">
      <Box w="5%">
        <Avatar size="xs" name="invoice" />
      </Box>
      <ReusableText
        text={item?.invoice_id}
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="10%"
      />
      <ReusableText
        text={item?.client?.slice(0, 30)}
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="20%"
        transform={true}
      />
      <ReusableText
        text={item?.brief_title?.slice(0, 30)}
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="20%"
        transform={true}
      />
      <ReusableText
        text={item?.brief_title?.slice(0, 30)}
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="15%"
        transform={true}
      />
      <ReusableText
        text={`₦${new Intl.NumberFormat().format(item?.amount)}.00`}
        color="#515050"
        size={{ lg: "14px", md: "12px", base: "4px" }}
        weight="medium"
        w="10%"
      />
      <Flex align="center" w="10%" gap="0.5rem" borderRadius="1rem">
        <Box
          w="0.3rem"
          height="0.3rem"
          borderRadius="100%"
          bg={
            item?.status?.toLowerCase() === "pending"
              ? "#333"
              : item?.status?.toLowerCase() === "partial"
              ? "yellow.500"
              : "#1FA159"
          }
        ></Box>
        <ReusableText
          text={item?.status}
          color={
            item?.status?.toLowerCase() === "pending"
              ? "#333"
              : item?.status?.toLowerCase() === "partial"
              ? "yellow.500"
              : "#1FA159"
          }
          size={{ lg: "12px", md: "10px", base: "6px" }}
          weight="medium"
        />
      </Flex>
      <Flex
        size={{ lg: "14px", md: "12px", base: "6px" }}
        align="center"
        w="10%"
        gap="1rem"
        justify="center"
      >
        <AiOutlineEye
          onClick={() => navigate(`/invoicing/${item.invoice_id}`)}
        />
      </Flex>
    </Flex>
  );
}
