import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Flex,
  Box,
} from "@chakra-ui/react";
import ReusableText from "./ReusableText";

export default function FileComponent({ onChange, border, p, title }) {
  return (
    <FormControl>
      <FormLabel>{title ? title : "Brief Documents"}</FormLabel>
      <FormLabel
        cursor="pointer"
        w="100%"
        htmlFor="file"
        fontWeight="medium"
        color="#515050"
      >
        <Flex
          align="center"
          justify="center"
          direction="column"
          gap="0.5rem"
          w="100%"
          p="1rem"
          border="1px solid #ccc"
        >
          <Box w="2rem" height="2rem" bg="#ccc" borderRadius="100%"></Box>
          <ReusableText
            text="Click to upload or drag and drop"
            color="#7F7E7E"
            size="14px"
            weight="light"
          />
          <ReusableText
            text="DOCx, XLXs, PPT, PDF, PNG, JPEG, JPG"
            color="#7F7E7E"
            size="14px"
            weight="medium"
          />
        </Flex>
      </FormLabel>
      <Input
        id="file"
        display="none"
        type="file"
        name="document_upload"
        onChange={(e) => onChange(e)}
        border={border ? border : "1px solid #ccc"}
        p={p ? p : ""}
        multiple
        accept=""
      />
      {false && <FormHelperText>We'll never share your email.</FormHelperText>}
    </FormControl>
  );
}
