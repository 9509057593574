import { Flex, Heading, Text, useToast } from "@chakra-ui/react";
import AuthComponent from "../UI/AuthComponent";
import { Link, useNavigate } from "react-router-dom";
import InputComponent from "../UI/Input";
import PasswordInput from "../UI/PasswordInput";
import ButtonComponent from "../UI/Button";
import { Fragment, useContext, useState } from "react";
import { login } from "../../util/http";
import { AuthContext } from "../../util/context";
import Loader from "../UI/Loader";
import { storeItem } from "../../util/lib";

export default function Login() {
  const navigate = useNavigate();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const ctx = useContext(AuthContext);

  async function loginHandler(e) {
    e.preventDefault();

    const data = {
      email,
      password,
    };

    if (!email && !password) {
      toast({
        title: `Error`,
        description: `Login details cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!email) {
      toast({
        title: `Error`,
        description: `email cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!password) {
      toast({
        title: `Error`,
        description: `password cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await login(data);
      setProgress(80);
      setProgress(100);
      ctx.login(response.token);
      storeItem("token", response.token, 86400000);
      setLoading(false);
      navigate("/verify-login");
      toast({
        title: `Login Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      localStorage.setItem("login-email", email);
    } catch (error) {
      setLoading(false);
      setProgress(100);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent>
        <Heading
          textAlign="center"
          color="#3F434A"
          fontSize="24px"
          fontWeight="medium"
        >
          Login To Your Account
        </Heading>
        <Text
          fontSize="14px"
          color="#949493"
          fontWeight="light"
          textAlign="center"
        >
          Welcome back! Please enter your details
        </Text>
        <form
          style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
          onSubmit={loginHandler}
        >
          <InputComponent
            label="Email"
            placeholder="o.noah@loya.com"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <PasswordInput
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
          />
          <Flex w="100%" gap="0.5rem" align="center" justify="end">
            <Text color="#2E4A49" fontSize="16px" fontWeight="normal">
              Forgot Password?
            </Text>
            <Link
              style={{ color: "#364273", fontWeight: "semibold" }}
              to="/forgot-password"
            >
              Click here
            </Link>
          </Flex>
          <ButtonComponent
            loading={loading}
            type="submit"
            title="Log in"
            width="100%"
          />
        </form>
      </AuthComponent>
    </Fragment>
  );
}
