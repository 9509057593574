import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

export default function AppraisalHeader() {
  return (
    <Flex border="1px solid #ccc" w="100%" align="start" wrap="wrap">
      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText text="Name" color="#7F7E7E" size="18px" weight="medium" />
        <ReusableText
          text="John Doe"
          color="#7F7E7E"
          size="15px"
          weight="normal"
        />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Department"
          color="#7F7E7E"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text="Finance"
          color="#7F7E7E"
          size="15px"
          weight="normal"
        />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Position"
          color="#7F7E7E"
          size="18px"
          weight="medium"
        />
        <ReusableText text="HOD" color="#7F7E7E" size="15px" weight="normal" />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Supervisor"
          color="#7F7E7E"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text="Jane Doe"
          color="#7F7E7E"
          size="15px"
          weight="normal"
        />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Reporting Period"
          color="#7F7E7E"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text="Jane Doe"
          color="#7F7E7E"
          size="15px"
          weight="normal"
        />
      </Flex>
      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText text="Date" color="#7F7E7E" size="18px" weight="medium" />
        <ReusableText
          text="12/12/2030"
          color="#7F7E7E"
          size="15px"
          weight="normal"
        />
      </Flex>
    </Flex>
  );
}
