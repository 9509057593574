import { Flex, Card, Text, CardBody, Heading } from "@chakra-ui/react";

export default function StatItem({ title, amount, icon, bg }) {
  return (
    <Card boxShadow="lg" w={{ lg: "30%", md: "45%", base: "90%" }} mx="auto">
      <CardBody>
        <Flex w="100%" align="center">
          <Flex w="70%" align="start" direction="column">
            <Text>{title}</Text>
            <Heading size="md" color="#000">
              {amount}
            </Heading>
          </Flex>
          <Flex
            align="center"
            justify="center"
            w="20%"
            p="1rem"
            bg={bg}
            borderRadius="1rem"
          >
            {icon}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
