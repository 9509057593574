import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import Task from "../../tasks/Task";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function TaskPage() {
  let view;
  const { isAuthenticated, user } = useContext(AuthContext);

  const viewTasks =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "user";

  if (viewTasks) {
    view = <Task />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated ? view : <Login />}</>;
}
