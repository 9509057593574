import { Box, Flex, Heading } from "@chakra-ui/react";
import BriefChart from "../reports/charts/BriefChart";
import RequisitionRequest from "./requisition/RequisitionRequest";
import { useEffect, useState } from "react";
import { getBriefs } from "../../util/http";

function briefFilter(data, status) {
  return data?.filter((el) => el.status?.toLowerCase() === status).length;
}

export default function DashboardFooter() {
  const [active, setactive] = useState(0);
  const [pending, setPending] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [draft, setDraft] = useState(0);

  const [briefs, setBriefs] = useState([]);

  useEffect(() => {
    setBriefs([active, completed, pending, draft]);
  }, [active, pending, draft, completed]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBriefs();
        const briefs = response?.briefs;
        setactive(briefFilter(briefs, "active"));
        setPending(briefFilter(briefs, "pending"));
        setCompleted(briefFilter(briefs, "completed"));
        setDraft(briefFilter(briefs, "draft"));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Flex w="100%" wrap="wrap" align="center" justify="space-between">
      <Flex align="center" direction="column" w="90%" mx="auto">
        <Box w="100%" mx="auto">
          <BriefChart
            lebels={["Active", "Completed", "Pending", "Draft"]}
            data={briefs}
          />
        </Box>
        <Heading as="h3" size="md">
          Briefs
        </Heading>
      </Flex>
      {false && <RequisitionRequest />}
    </Flex>
  );
}
