import { Button, Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import TextareaComponent from "../../UI/Textarea";
import PreviewDocument from "../create brief/PreviewDocument";
import { GrAttachment } from "react-icons/gr";
import { useState } from "react";

function ReusableReport({ report }) {
  return (
    <Flex w="100%" align="start" gap="1rem">
      <ReusableText
        text={`${new Date().toLocaleDateString()} \n${new Date().toLocaleTimeString()}`}
        color="#333"
        size="14px"
        weight="normal"
      />
      <ReusableText text="Went to court today and we do so so so and so...., even had a mini issue that took quite some time to resolve and it cost us #50,000, but in the end the judget postponed the case till next year  (JOHN DOE)" />
    </Flex>
  );
}

export default function BriefReport() {
  const [report, setReport] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  async function addReportHandler() {}

  return (
    <Flex
      py="1rem"
      borderBottom="1px solid #ccc"
      w="100%"
      align="center"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
    >
      <Flex
        w={{ lg: "30%", md: "100%", base: "100%" }}
        height={{ lg: "600px", md: "100%", base: "100%" }}
      >
        <ReusableText
          text="Brief Report"
          size={{ lg: "16px", md: "14px", base: "12px" }}
          color="#515050"
          weight="bold"
        />
      </Flex>
      <Flex
        height={{ lg: "600px", md: "100%", base: "100%" }}
        w="100%"
        align="start"
        direction="column"
        gap="2rem"
      >
        <Flex
          w="100%"
          p="1rem"
          bg="#eee"
          boxShadow="lg"
          overflow="scroll"
          h="400px"
          align="start"
          direction="column"
          gap="1rem"
        >
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
          <ReusableReport />
        </Flex>
        <Flex
          border="1px solid #ccc"
          w={{ lg: "70%", md: "100%", base: "100%" }}
          p="1rem"
          align="start"
          direction="column"
          gap="1rem"
        >
          <TextareaComponent
            border="none"
            required={false}
            label=""
            placeholder="What did you do today?"
            onChange={(e) => setReport(e.target.value)}
          />
          <PreviewDocument setFiles={setFiles} files={files} />
          <Flex align="center" justify="space-between" w="100%">
            <Flex align="center" gap="1rem">
              <input
                onChange={(e) =>
                  setFiles((prev) => [e.target.files[0], ...prev])
                }
                type="file"
                id="file"
                multiple
                style={{ display: "none" }}
              />
              <label htmlFor="file">
                <GrAttachment cursor="pointer" fontSize="22px" />
              </label>
            </Flex>
            <Button
              onClick={addReportHandler}
              variant="solid"
              bg="brand.900"
              color="white"
              isLoading={loading}
              loadingText="Processing"
            >
              Send
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
