import { AddIcon } from "@chakra-ui/icons";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { Flex, useDisclosure } from "@chakra-ui/react";
import ReusableFilter from "../UI/ReusableFilter";
import QueryTitle from "./QueryTitle";
import QueryList from "./QueryList";
import ReusableFooter from "../UI/ReusableFooter";
import { Fragment, useContext, useEffect, useState } from "react";

import CreateQuery from "./CreateQuery";
import { getQueries } from "../../util/http";
import { AuthContext } from "../../util/context";

export default function QueryContainer({
  list,
  total,
  perPage,
  currentPage,
  setCurrentPage,
  setList,
  loading,
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [status, setStatus] = useState("");

  const { user } = useContext(AuthContext);

  const isAllowed =
    user?.job_role?.toLowerCase() === "hr" ||
    // user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase() === "partner" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop";

  useEffect(() => {
    async function filterData() {
      if (!status) {
        return;
      }
      try {
        const response = await getQueries();
        if (
          user.job_role?.toLowerCase() === "lawyer" ||
          user.job_role?.toLowerCase() === "user"
        ) {
          const filteredQueries = response?.queries?.filter((el) => {
            return (
              el.assignee_id === user.employee_id &&
              el.decision?.toLowerCase() === status
            );
          });
          setList(filteredQueries);
        } else if (
          user?.job_role?.toLowerCase().includes("hod") ||
          user?.job_role?.toLowerCase() === "hop"
        ) {
          setList(
            response?.queries
              ?.filter(
                (el) =>
                  String(el.initiator_id) === String(user.id) &&
                  el.decision?.toLowerCase() === status
              )
              .reverse()
          );
        } else {
          const filteredQueries = response?.queries?.filter((el) => {
            return el.decision?.toLowerCase() === status;
          });
          setList(filteredQueries);
        }
      } catch (error) {
        console.log(error);
      }
    }
    filterData();
  }, [status]);

  function allFilter() {
    setStatus("");
  }

  function pendingFilter() {
    setStatus("pending");
  }

  function approvedFilter() {
    setStatus("approved");
  }

  async function searchHandler(e) {
    const response = await getQueries();
    let filteredData;
    if (
      user.job_role?.toLowerCase() === "lawyer" ||
      user.job_role?.toLowerCase() === "user"
    ) {
      filteredData = response?.queries.filter((el) => {
        return (
          (el.assignee_id === user.employee_id &&
            el.initiator
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase())) ||
          el.assignee
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.description
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        );
      });
    } else if (
      user?.job_role?.toLowerCase().includes("hod") ||
      user?.job_role?.toLowerCase() === "hop"
    ) {
      filteredData = response?.queries
        ?.filter((el) => {
          const match =
            el.initiator
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase()) ||
            el.assignee
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase()) ||
            el.description
              ?.toLowerCase()
              .includes(e.target.value.trim().toLowerCase());
          return String(el.initiator_id) === String(user.id) && match;
        })
        .reverse();
    } else {
      filteredData = response?.queries.filter((el) => {
        return (
          el.initiator
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.assignee
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          el.description
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        );
      });
    }
    setList(filteredData);
  }
  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Query"
          text="Manage your employees query details here"
          btnText="Initiate a query"
          icon={isAllowed && <AddIcon />}
          show={isAllowed}
          onClick={isAllowed && onOpen}
        />
        <ReusableFilter
          menu={[
            { text: "All", onClick: allFilter },
            { text: " Pending", onClick: pendingFilter },
            { text: "Approved", onClick: approvedFilter },
          ]}
          onSearch={searchHandler}
        />
        <Flex
          w="100%"
          border="1px solid #ccc"
          borderTop="none"
          direction="column"
        >
          <QueryTitle />
          <QueryList loading={loading} list={list} />
          <ReusableFooter
            total={total}
            itemPerPage={perPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Flex>
      </Flex>
      <CreateQuery isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}
