import { Button, Flex, useToast } from "@chakra-ui/react";
import { Fragment } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import SelectTab from "./SelectTab";
import ClientInvoiceDetails from "./ClientDetail";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputComponent from "../UI/Input";

export default function CreateRetainerShip({
  setClient,
  setTitle,
  setDueDate,
  setRetAmount,
  setMax,
  createHandler,
  loading,
  setBriefList,
  max,
}) {
  const [clientId, setClientId] = useState("");
  const toast = useToast();

  const navigate = useNavigate();

  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p="2rem"
      >
        {false && (
          <ReusableEmployeeHeader
            title="Create Retainership"
            text="Manage your invoice and their details here"
          />
        )}
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
          onSubmit={createHandler}
        >
          <SelectTab
            setClientId={setClientId}
            setClient={setClient}
            setTitle={setTitle}
            setAmount={setRetAmount}
          />
          {clientId && (
            <ClientInvoiceDetails
              setBriefList={setBriefList}
              clientId={clientId}
            />
          )}
          <Flex align="center" gap="1rem" w="100%" justify="space-between">
            <InputComponent
              label="Due Date"
              type="date"
              onChange={(e) => setDueDate(e.target.value)}
            />
            <InputComponent
              label="Maximum Spend"
              type="number"
              placeholder="20%"
              value={max}
              onChange={(e) => {
                if (e.target.value.length > 3 || +e.target.value > 100) {
                  toast({
                    title: "Maximum spend cannot be greater than 100",
                    description: "",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                  });
                  setMax("");
                } else {
                  setMax(e.target.value);
                }
              }}
            />
          </Flex>
          <Flex pb="3rem" align="center" justify="space-between" w="100%">
            <Button
              onClick={() => navigate("/invoicing")}
              variant="outline"
              colorScheme="red"
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              loadingText="Processing"
              type="submit"
              variant="solid"
              color="white"
              bg="brand.900"
            >
              Create Retainership
            </Button>
          </Flex>
        </form>
      </Flex>
    </Fragment>
  );
}
