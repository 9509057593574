import {
  Flex,
  Avatar,
  useToast,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import React, { Fragment, useState } from "react";
import ReusableText from "../UI/ReusableText";

import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../UI/DeleteModal";
import { deleteBrief } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/context";
import { getStoredItem } from "../../util/lib";

export default function BriefItem({ item }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const { user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  async function deleteBriefHandler() {
    if (item?.status?.toLowerCase() !== "draft") {
      onClose();
      toast({
        title: `Brief is active and cannot be deleted`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      await deleteBrief(item?.brief_id);
      window.location.reload();
      onClose();
      toast({
        title: `Brief deleted`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Flex
        w="100%"
        align="center"
        px="1rem"
        py="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        borderBottom="1px solid #333"
      >
        <Flex display={{ lg: "flex", md: "none", base: "none" }} w="5%">
          <Avatar size="xs" name={item?.client} />
        </Flex>
        <ReusableText
          w="10%"
          color="#2BA2DA"
          size={{ lg: "14px", md: "12px", base: "8px" }}
          weight="normal"
          decoration={true}
          text={item?.brief_id}
          onClick={() => navigate(`/brief/${item?.brief_id}`)}
          align="start"
        />
        <ReusableText
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "8px" }}
          weight="medium"
          w="30%"
          onClick={() => navigate(`/brief/${item?.brief_id}`)}
          text={item?.brief_title.slice(0, 20)}
          transform={true}
        />
        <ReusableText
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "8px" }}
          weight="medium"
          w="20%"
          onClick={() => navigate(`/brief/${item?.brief_id}`)}
          text={
            userType === "employee"
              ? item?.client
              : `${user?.first_name} ${user?.last_name}`
          }
          transform={true}
        />
        <ReusableText
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "8px" }}
          weight="medium"
          w="15%"
          text={
            userType === "employee"
              ? `₦${new Intl.NumberFormat().format(
                  item?.maximum_spend_value
                )}.00`
              : `₦${new Intl.NumberFormat().format(item?.pending_balance)}.00`
          }
        />
        <Flex
          align="center"
          justify="center"
          // px="2px"
          py="4px"
          w="10%"
          borderRadius="8px"
          bg={
            item.status?.toLowerCase() === "pending"
              ? "gray.100"
              : item.status?.toLowerCase() === "completed"
              ? "green.100"
              : item.status?.toLowerCase() === "draft"
              ? "red.100"
              : "yellow.100"
          }
        >
          <ReusableText
            color={
              item.status?.toLowerCase() === "pending"
                ? "gray.700"
                : item.status?.toLowerCase() === "completed"
                ? "green.600"
                : item.status?.toLowerCase() === "draft"
                ? "red.800"
                : "yellow.600"
            }
            size={{ lg: "14px", md: "12px", base: "5px" }}
            weight="bold"
            transform={true}
            text={item?.status}
          />
        </Flex>
        <Flex
          fontSize={{ lg: "20px", md: "18px", base: "10px" }}
          align="center"
          w="15%"
          justify="space-evenly"
        >
          <AiOutlineEye onClick={() => navigate(`/brief/${item?.brief_id}`)} />

          {userType === "employee" && (
            <Button
              variant="ghost"
              isDisabled={item?.status?.toLowerCase() !== "draft"}
            >
              <AiOutlineDelete onClick={userType === "employee" && onOpen} />
            </Button>
          )}
        </Flex>
      </Flex>
      {userType === "employee" && (
        <DeleteModal
          title="Delete Brief?"
          text="Are you sure you would like to delete this brief?  Deleting the brief will remove all the associated content from the LOYA platform."
          modalTitle="Brief Deleted"
          modalText="You have successfully deleted “The Estate of John Doe’s” brief"
          isOpen={isOpen}
          onClose={onClose}
          onClick={deleteBriefHandler}
          onChange={(e) => setPassword(e.target.value)}
        />
      )}
    </Fragment>
  );
}
