import { Flex, Button, Divider, Heading } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { ChevronDownIcon, ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import ReusableText from "../../UI/ReusableText";
import Modal from "../../overlay/Modal";
import { BsDashLg } from "react-icons/bs";
import ReusableChart from "../../UI/ReusableChart";
import { useEffect, useState } from "react";
import {
  getClients,
  getIncome,
  getInvoices,
  getRequisitions,
} from "../../../util/http";
import { filterDate } from "../../../util/lib";
import ClientChart from "../charts/ClientChart";

function monthFilter(data, month) {
  return data?.filter((el) => {
    const createdMonth = new Date(el.created_at).getMonth() + 1;
    return +createdMonth === +month;
  });
}

export default function ClientReportDetail({ show }) {
  const [filter, setFilter] = useState("weekly");
  const [days, setDays] = useState(0);
  const [list, setList] = useState([]);
  const [client, setClient] = useState(list[0] || "");
  const [allClients, setAllClients] = useState([]);
  const [expenses, setExpenses] = useState(0);
  const [income, setIncome] = useState(0);
  const [expected, setExpected] = useState(0);

  const [expensesChart, setExpensesChart] = useState([]);
  const [incomeChart, setIncomeChart] = useState([]);
  const [expectedChart, setExpectedChart] = useState([]);

  const [data, setData] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });

  useEffect(() => {
    if (filter?.toLowerCase() === "daily") {
      setDays(1);
    } else if (filter?.toLowerCase() === "weekly") {
      setDays(7);
    } else if (filter?.toLowerCase() === "monthly") {
      setDays(30);
    } else {
      setDays("");
    }
  }, [filter]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getClients();
        setAllClients(
          response?.clients
            ?.filter((el) => el.deleted_at === null)
            .map((el) => {
              return { name: el.client_name, id: el.client_id };
            })
        );
        setList(
          response?.clients
            ?.filter((el) => el.deleted_at === null)
            ?.map((el) => el?.client_name)
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const client_id = allClients?.find((el) => el.name == client)?.id;
      try {
        const response = await getRequisitions();
        const requisitions = response?.requisitions?.filter((el) => {
          if (!days) {
            return el;
          }
          const milliSec = +days * 24 * 60 * 60 * 1000;
          const startDate = filterDate(milliSec);
          const createdDate = new Date(el?.created_at);
          return startDate < createdDate && el.client_id === client_id;
        });

        setExpensesChart({
          January: monthFilter(requisitions, 1)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          February: monthFilter(requisitions, 2)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          March: monthFilter(requisitions, 3)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          April: monthFilter(requisitions, 4)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          May: monthFilter(requisitions, 5)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          June: monthFilter(requisitions, 6)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          July: monthFilter(requisitions, 7)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          August: monthFilter(requisitions, 8)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          September: monthFilter(requisitions, 9)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          October: monthFilter(requisitions, 10)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          November: monthFilter(requisitions, 11)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          December: monthFilter(requisitions, 12)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [allClients, client, days]);

  useEffect(() => {
    async function fetchData() {
      const client_id = allClients?.find((el) => el.name == client)?.id;
      try {
        const response = await getIncome();
        const totalIncome = response?.receipts
          ?.filter(
            (el) => el?.deleted_at === null && el?.client_id === client_id
          )
          ?.map((el) => +el.amount)
          .reduce((acc, sum) => acc + sum, 0);

        const income = response?.receipts?.filter(
          (el) => el?.client_id === client_id && el?.deleted_at === null
        );

        setIncomeChart({
          January: monthFilter(income, 1)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          February: monthFilter(income, 2)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          March: monthFilter(income, 3)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          April: monthFilter(income, 4)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          May: monthFilter(income, 5)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          June: monthFilter(income, 6)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          July: monthFilter(income, 7)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          August: monthFilter(income, 8)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          September: monthFilter(income, 9)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          October: monthFilter(income, 10)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          November: monthFilter(income, 11)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          December: monthFilter(income, 12)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
        });

        setIncome(totalIncome);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [client]);

  useEffect(() => {
    async function fetchData() {
      const client_id = allClients?.find((el) => el.name == client)?.id;
      try {
        const response = await getInvoices();
        const expectedIncome = response?.invoices
          ?.filter((el) => el?.client_id === client_id)
          ?.map((el) => +el.amount)
          .reduce((acc, sum) => acc + sum, 0);

        const expected = response?.invoices?.filter(
          (el) => el?.client_id === client_id
        );

        setExpectedChart({
          January: monthFilter(expected, 1)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          February: monthFilter(expected, 2)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          March: monthFilter(expected, 3)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          April: monthFilter(expected, 4)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          May: monthFilter(expected, 5)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          June: monthFilter(expected, 6)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          July: monthFilter(expected, 7)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          August: monthFilter(expected, 8)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          September: monthFilter(expected, 9)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          October: monthFilter(expected, 10)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          November: monthFilter(expected, 11)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
          December: monthFilter(expected, 12)
            ?.map((el) => +el.amount)
            ?.reduce((acc, sum) => acc + sum, 0),
        });

        setExpected(expectedIncome);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [client]);

  function closeModal() {
    show(false);
  }

  useEffect(() => {
    const clientExpenses = Object.values(expensesChart);
    const totalExpenses = clientExpenses?.reduce((acc, sum) => acc + sum, 0);
    setExpenses(totalExpenses);
  }, [expensesChart]);

  return (
    <Modal hide={closeModal}>
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="#222" fontWeight={600}>
          Finance Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent
              label="Client"
              onChange={(e) => setClient(e.value)}
              options={["Select client here", ...list]}
            />
            <SelectComponent
              label="Duration"
              onChange={(e) => setFilter(e.value)}
              options={["Daily", "Weekly", "Monthly", "All Time"]}
            />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
          >
            Generate Report
          </Button>
        </Flex>
        <Flex w="100%" align="center" gap="2rem">
          <Flex align="center" gap="1rem">
            <Flex
              align="center"
              p="0.8rem"
              bg="rgba(255, 150, 93, 0.3)"
              justify="center"
              borderRadius="16px"
            >
              <ArrowUpIcon color="#FF965D" />
            </Flex>
            <Flex align="start" direction="column">
              <ReusableText
                text={`₦${new Intl.NumberFormat().format(expenses)}.00`}
                color="#3F434A"
                size="16px"
                weight="bold"
              />
              <ReusableText
                text="Total Expenses"
                color="#8A9099"
                size="14px"
                weight="light"
              />
            </Flex>
          </Flex>

          <Flex align="center" gap="1rem">
            <Flex
              align="center"
              p="0.8rem"
              bg="rgba(48, 79, 253, 0.3)"
              justify="center"
              borderRadius="16px"
            >
              <BsDashLg color="#304FFD" />
            </Flex>
            <Flex align="start" direction="column">
              <ReusableText
                text={`₦${new Intl.NumberFormat().format(expected)}.00`}
                color="#3F434A"
                size="16px"
                weight="bold"
              />
              <ReusableText
                text="Expected Income"
                color="#8A9099"
                size="14px"
                weight="light"
              />
            </Flex>
          </Flex>

          <Flex align="center" gap="1rem">
            <Flex
              align="center"
              p="0.8rem"
              bg="rgba(73, 201, 109, 0.3)"
              justify="center"
              borderRadius="16px"
            >
              <ArrowDownIcon color="#49C96D" />
            </Flex>
            <Flex align="start" direction="column">
              <ReusableText
                text={`₦${new Intl.NumberFormat().format(income)}.00`}
                color="#3F434A"
                size="16px"
                weight="bold"
              />
              <ReusableText
                text="Total Income"
                color="#8A9099"
                size="14px"
                weight="light"
              />
            </Flex>
          </Flex>
        </Flex>
        <ClientChart
          expenses={Object.values(expensesChart)}
          income={Object.values(incomeChart)}
          expected={Object.values(expectedChart)}
        />
      </Flex>
    </Modal>
  );
}
