import React from "react";
import EditProfile from "../../profile/edit-profile/EditProfile";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";

export default function EditProfilePage() {
  const ctx = useContext(AuthContext);
  return <>{ctx.isAuthenticated ? <EditProfile /> : <Login />}</>;
}
