import React from "react";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";
import SelectComponent from "../../UI/Select";
import { useState } from "react";
import { editClient } from "../../../util/http";
import Modal from "../../overlay/Modal";
import Overlay from "../../overlay/OverLay";
import ReusableText from "../../UI/ReusableText";
import { AiOutlineClose } from "react-icons/ai";

export default function EditClient({ show, client }) {
  const toast = useToast();
  const contact = client?.contact_person[0];
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(client?.client_name);
  const [email, setEmail] = useState(client?.email);
  const [phone, setPhone] = useState(client?.phone_number);
  const [type, setType] = useState(client?.client_type);
  const [contactFirstName, setContactFirstName] = useState(
    contact?.contact_first_name
  );
  const [contactLastName, setContactLastName] = useState(
    contact?.contact_last_name
  );
  const [contactEmail, setContactEmail] = useState(contact?.contact_email);
  const [contactPhone, setContactPhone] = useState(contact?.contact_phone);
  const [contactAddress, setContactAddress] = useState(
    contact?.contact_address
  );
  const [contactCity, setContactCity] = useState(contact?.contact_city);
  const [contactState, setContactState] = useState(contact?.contact_state);
  const [contactCountry, setContactCountry] = useState(
    contact?.contact_country
  );

  async function editHandler() {
    const data = {
      first_name: name
        ? name?.split(" ")[0]
        : client?.client_name?.split(" ")[0],
      last_name: name ? name?.slice(1) : client?.client_name.slice(1),
      email: email ? email : client?.email,
      phone_number: phone ? phone : client?.phone_number,
      client_type: type ? type : client?.client_type,
      contact_first_name: contactFirstName,
      contact_last_name: contactLastName,
      contact_phone: contactPhone,
      contact_email: contactEmail,
      contact_address: contactAddress,
      contact_city: contactCity,
      contact_state: contactState,
      contact_country: contactCountry,
      business_name:
        client?.client_type?.toLowerCase() === "individual" ? null : name,
    };

    try {
      setLoading(true);
      await editClient(client?.id, data);

      show(false);
      toast({
        title: `Update Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      show(false);
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={() => show(false)} />
      <Modal hide={() => show(false)} title="Edit Client">
        <Flex align="start" w="100%" direction="column" gap="1rem" py="2rem">
          <Box
            onClick={() => show(false)}
            position="absolute"
            top="0"
            right="0"
            p="1rem"
          >
            <AiOutlineClose fontSize={24} color="red" cursor="pointer" />
          </Box>
          <ReusableText
            text={`Edit ${client?.client_name} details`}
            align="start"
            size="24px"
            weight="bold"
            color="#333"
          />
          <Flex w="100%" align="center" gap="1rem">
            <InputComponent
              label="Client Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              // placeholder="Edit Name"
            />
            <InputComponent
              label="Client Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              // placeholder="Edit Email"
              readOnly={true}
            />
          </Flex>

          <Flex w="100%" align="center" gap="1rem">
            <InputComponent
              label="Client Phone Number"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              placeholder="Edit Phone Number"
            />
            <Flex align="start" w="100%" direction="column">
              <SelectComponent
                label="Client Type"
                onChange={(e) => setType(e.value)}
                options={["Select Options", "Individual", "Corporate"]}
              />
              <ReusableText
                text={`Current client Type: ${client?.client_type}`}
                align="start"
                size="14px"
                weight="medium"
                color="#333"
              />
            </Flex>
          </Flex>

          <Flex w="100%" align="center" gap="1rem">
            <InputComponent
              label="Contact First Name"
              onChange={(e) => setContactFirstName(e.target.value)}
              value={contactFirstName}
              // placeholder="Edit Name"
            />
            <InputComponent
              label="Contact Last Name"
              onChange={(e) => setContactLastName(e.target.value)}
              value={contactLastName}
            />
          </Flex>

          <Flex w="100%" align="center" gap="1rem">
            <InputComponent
              label="Contact Email"
              onChange={(e) => setContactEmail(e.target.value)}
              value={contactEmail}
              // placeholder="Edit Name"
            />
            <InputComponent
              label="Contact Phone Number"
              onChange={(e) => setContactPhone(e.target.value)}
              value={contactPhone}
              // placeholder="Edit Email"
              readOnly={true}
            />
          </Flex>

          <Flex w="100%" align="center" gap="1rem">
            <InputComponent
              label="Contact Address"
              onChange={(e) => setContactAddress(e.target.value)}
              value={contactAddress}
              // placeholder="Edit Name"
            />
            <InputComponent
              label="Contact City"
              onChange={(e) => setContactCity(e.target.value)}
              value={contactCity}
              // placeholder="Edit Email"
              readOnly={true}
            />
          </Flex>

          <Flex w="100%" align="center" gap="1rem">
            <InputComponent
              label="Contact State"
              onChange={(e) => setContactState(e.target.value)}
              value={contactState}
              // placeholder="Edit Name"
            />
            <InputComponent
              label="Contact City"
              onChange={(e) => setContactCountry(e.target.value)}
              value={contactCountry}
              // placeholder="Edit Email"
              readOnly={true}
            />
          </Flex>

          <Flex align="end" justify="end" w="100%">
            <Button
              variant="solid"
              bg="brand.900"
              color="white"
              isLoading={loading}
              loadingText="Processing"
              onClick={editHandler}
            >
              Save Update
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
