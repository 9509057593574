import { Flex } from "@chakra-ui/react";
import AppraisalItem from "./AppraisalItem";

export default function AppraisalList() {
  const data = [1, 1, 1, 1, 1, 1, 1, 1];
  return (
    <Flex
      align="start"
      border="1px solid #ccc"
      borderBottomRadius="0.5rem"
      w="100%"
      direction="column"
    >
      {data?.map((item, i) => (
        <AppraisalItem title="" path="invoicing" item={item} key={i} />
      ))}
    </Flex>
  );
}
