import React, { useEffect, useState, useContext } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import CalenderContainer from "./CalenderContainer";
import { getEvents, getUserEvents } from "../../util/http";
import { AuthContext } from "../../util/context";

export default function Calender() {
  const [list, setList] = useState([]);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        let response;
        if (user?.job_role?.toLowerCase() === "lawyer") {
          response = await getUserEvents(user.id);
        } else {
          response = await getEvents();
        }

        const eventList = response?.events?.map((el) => {
          return {
            id: el.id,
            title: el.title,
            start: el.start_time,
            end: el.end_time,
            display: "list-item",
            color: "#364273",
            allDay: false,
            overlap: false,
            description: el.description,
            event_url: el?.url,
          };
        });
        setList(eventList);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  return (
    <DashboardReusable>
      <CalenderContainer list={list} />
    </DashboardReusable>
  );
}
