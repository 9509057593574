import CompletedList from "./CompletedList";
import TaskWrapper from "../TaskWrapper";
import { useDrop } from "react-dnd";
import { useState } from "react";

export default function Completed({ list }) {
  // const [board, setBoard] = useState([1, 1, 1]);
  // const addImageToBoard = (id) => {
  //   const taskList = list.filter((item) => id === item.id);
  //   // setBoard((board) => [...board, taskList[0]]);
  //   setBoard((board) => [...board, 1]);
  // };

  // const [{ isOver }, drop] = useDrop(() => ({
  //   accept: "task",
  //   drop: (item) => addImageToBoard(item.id),
  //   collect: (monitor) => ({
  //     isOver: !!monitor.isOver(),
  //   }),
  // }));
  return (
    <TaskWrapper title="COMPLETED" total={list?.length}>
      <CompletedList list={list} />
    </TaskWrapper>
  );
}
