import { FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function ReusablePassword({ label, onChange, value }) {
  return (
    <FormControl py="1rem" borderBottom="1px solid #ccc" display="flex" w="70%">
      <FormLabel fontSize="14px" color="#344054" w="30%">
        {label}
      </FormLabel>
      <Input
        focusBorderColor="brand.900"
        type="password"
        placeholder="*************"
        onChange={onChange}
        value={value}
      />
    </FormControl>
  );
}
