import {
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import Overlay from "../overlay/OverLay";
import SelectComponent from "../UI/Select";
import { useContext, useState, useEffect } from "react";
import { AiOutlineShareAlt } from "react-icons/ai";
import { AuthContext } from "../../util/context";
import { getEmployees, shareDocument } from "../../util/http";

export default function ShareDocument({ show, detail }) {
  function closeMenu() {
    show(false);
  }

  const [list, setList] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        let employeeObj;

        employeeObj = response?.employees
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return {
              name: `${el.first_name} ${el.last_name}`,
              id: el.id,
            };
          });

        // employeeObj = response?.employees
        //   ?.filter((el) => el.deleted_at === null)
        //   .map((el) => {
        //     return {
        //       name: `${el.first_name} ${el.last_name}`,
        //       id: el.id,
        //     };
        //   });

        setAllEmployees(employeeObj);

        const employeeNames = employeeObj?.map((el) => el.name);
        setList(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  async function shareHandler() {
    const employee_id = allEmployees?.find(
      (el) => el.name?.toLowerCase() == employee?.toLowerCase()
    )?.id;
    const data = {
      assignee_id: employee_id,
      origin: detail.origin,
    };

    if (!employee) {
      toast({
        title: `Select a recepient`,
        description: "",
        status: "warning",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      const response = await shareDocument(detail.id, data);
      console.log(response);
      closeMenu();
      toast({
        title: `Document shared with ${employee}`,
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="#333" fontWeight="bold">
              Share file
            </Heading>
            <CloseButton colorScheme="red" onClick={closeMenu} />
          </Flex>
          <Divider />
          <SelectComponent
            options={list || []}
            label="Select Recepient"
            onChange={(e) => setEmployee(e.value)}
          />
          <Flex w="100%" align="center" justify="space-between">
            <Button onClick={closeMenu} variant="solid" colorScheme="red">
              Cancel
            </Button>
            <Button
              leftIcon={<AiOutlineShareAlt />}
              variant="solid"
              color="white"
              bg="brand.900"
              isLoading={loading}
              loadingText=""
              onClick={shareHandler}
            >
              Share
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
