import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";

export default function Roles({ roles, setRole, role }) {
  return (
    <Flex
      height="600px"
      overflow="scroll"
      align="start"
      w="20%"
      direction="column"
      gap="1rem"
    >
      <ReusableText text="Roles" size="20px" color="#19191A" weight="bold" />

      <Flex align="start" direction="column" gap="1rem">
        {roles?.map((item, i) => (
          <ReusableText
            key={i}
            text={item}
            transform={true}
            size="16px"
            color="#333"
            weight="normal"
            cursor="pointer"
            w="100%"
            bg={role === item && "#eee"}
            p={role === item && "8px"}
            onClick={() => {
              setRole(item);
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
}
