import React, { useContext } from "react";
import NavItem from "../UI/NavItem";

import guage from "../images/Gauge.png";
import brief from "../images/Base icons.png";
import task from "../images/Base icons (1).png";
import client from "../images/Base icons (2).png";
import employee from "../images/Base icons (3).png";
import documents from "../images/Base icons (4).png";
import calendar from "../images/Base icons (5).png";
import invoice from "../images/Base icons (6).png";
import requisition from "../images/Base icons (7).png";
import report from "../images/Base icons (8).png";
import bell from "../images/Bell.png";
import setting from "../images/setting.png";
import { Flex } from "@chakra-ui/react";
import { getStoredItem } from "../../util/lib";
import { AuthContext } from "../../util/context";

export default function Menu() {
  const userType = getStoredItem("user-type");
  const { user } = useContext(AuthContext);

  const viewEmployee =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewClient =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewInvoice =
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    userType === "client" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewRequisition =
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "seed";

  const viewSettings = user?.job_role?.toLowerCase() === "seed";

  const viewCalendar = true;
  // user?.job_role?.toLowerCase() === "lawyer" ||
  // user?.job_role?.toLowerCase().includes("hod") ||
  // user?.job_role?.toLowerCase() === "hop" ||
  // user?.job_role?.toLowerCase() === "hr" ||
  // user?.job_role?.toLowerCase() === "seed";

  const viewReport =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewDocument =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewTasks =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "user";

  const viewBrief =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    userType === "client";

  return (
    <Flex
      w="100%"
      h={userType === "client" ? "67vh" : ""}
      align="center"
      justify="space-between"
      direction="column"
    >
      <Flex align="start" w="100%" direction="column">
        <NavItem src={guage} title="Dashboard" link="" />
        {viewBrief && (
          <NavItem
            src={brief}
            title="Briefs "
            link={viewBrief ? "brief" : ""}
          />
        )}
        {userType === "employee" && viewTasks && (
          <NavItem
            src={task}
            title="Tasks "
            link={`${userType === "employee" && viewTasks ? "task" : ""}`}
          />
        )}
        {userType === "employee" && viewClient && (
          <NavItem
            src={client}
            title="Clients"
            link={`${userType === "employee" && viewClient ? "client" : ""}`}
          />
        )}
        {userType === "employee" && viewEmployee && (
          <NavItem
            src={employee}
            title="Employees"
            link={`${
              userType === "employee" && viewEmployee ? "employees" : ""
            }`}
          />
        )}
        {userType === "employee" && viewDocument && (
          <NavItem
            src={documents}
            title="Documents"
            link={`${
              userType === "employee" && viewDocument ? "document" : ""
            }`}
          />
        )}
        {userType === "employee" && viewCalendar && (
          <NavItem
            src={calendar}
            title="Calendar"
            link={`${
              userType === "employee" && viewCalendar ? "calendar" : ""
            }`}
          />
        )}
        {viewInvoice && (
          <NavItem
            src={invoice}
            title="Invoicing"
            link={`${viewInvoice ? "invoicing" : ""}`}
          />
        )}
        {userType === "employee" && viewRequisition && (
          <NavItem
            src={requisition}
            title="Requisitions"
            link={`${
              userType === "employee" && viewRequisition ? "requisitions" : ""
            }`}
          />
        )}
        <NavItem src={report} title="Appraisal" link="appraisal" />
        {userType === "employee" && viewReport && (
          <NavItem
            src={report}
            title="Reports"
            link={`${userType === "employee" && viewReport ? "reports" : ""}`}
          />
        )}
        {userType === "employee" && (
          <NavItem src={report} title="Leave" link="leave" />
        )}
        {userType === "employee" && (
          <NavItem src={report} title="Query" link="query" />
        )}
        {userType === "employee" && viewSettings && (
          <NavItem
            src={setting}
            title="Settings"
            link={`${
              userType === "employee" && viewSettings ? "settings" : ""
            }`}
          />
        )}
      </Flex>
      {false && (
        <NavItem src={bell} title="Notifications" link="notification" />
      )}
    </Flex>
  );
}
