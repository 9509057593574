export const reportData = [
  {
    type: "briefs",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "clients",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Staff",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Total Login Duration",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Total Assigned Task",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Finance",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Leave",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Query",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Invoice",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
  {
    type: "Requisition",
    description:
      "John Doe passed away on December 1, 2021, leaving behind a significant estate consisting of real property, ...",
  },
];
