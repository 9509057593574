import { Button, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { updateTask } from "../../../util/http";
import { useContext, useState } from "react";
import RequestExtension from "./RequestExtension";
import { AuthContext } from "../../../util/context";

export default function ViewTaskSubHeader({ task }) {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const twoDays =
    new Date(task?.due_date) - new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
  const validTwoDays = new Date(Date.now() + twoDays);
  const btnIsActive = new Date() <= validTwoDays;
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  async function completeHandler() {
    try {
      setLoading(true);
      await updateTask(task?.id, { status: "completed" });
      window.location.reload();
      setLoading(false);
      toast({
        title: `Status changed to completed`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      {true && (
        <Flex align="center" justify="space-between" w="100%">
          {user?.job_role?.toLowerCase() === "lawyer" && (
            <Button
              variant="solid"
              bg="brand.900"
              color="white"
              onClick={onOpen}
              isLoading={loading}
              loadingText="Processing"
              size="sm"
              isDisabled={btnIsActive}
            >
              Request extension
            </Button>
          )}
          {false && (
            <Flex
              p={{ lg: "24px", md: "14px", base: "12px" }}
              align="center"
              gap="1rem"
            >
              <AiOutlineClose
                onClick={() => navigate("/task")}
                cursor="pointer"
              />
            </Flex>
          )}
        </Flex>
      )}
      <RequestExtension isOpen={isOpen} onClose={onClose} task={task} />
    </>
  );
}
