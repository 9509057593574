import { useDisclosure } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import EditRole from "./EditRole";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function SettingHeader({ show, show1, show2, show3 }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useContext(AuthContext);

  function openDept() {
    show1(true);
  }

  function openDesig() {
    show2(true);
  }

  function openLeave() {
    show3(true);
  }

  const canView = user?.id === 2 || user?.id === 3;

  return (
    <>
      <ReusableEmployeeHeader
        title="Create Employee Role"
        text="Complete the form below and select the permissions that you would like the category to have."
        btnText="Create Appraisal"
        showDottedMenu={true}
        show={true}
        onClick={() => show(true)}
        menu={
          canView
            ? [
                {
                  name: "Edit Role",
                  icon: "",
                  navigation: false,
                  onClick: onOpen,
                },
                {
                  name: "Create Role",
                  icon: "",
                  navigation: true,
                  link: "/settings/create-role",
                },
                {
                  name: "Create Department",
                  icon: "",
                  navigation: false,
                  onClick: openDept,
                },
                {
                  name: "Create Designation",
                  icon: "",
                  navigation: false,
                  onClick: openDesig,
                },
                {
                  name: "Create Leave Type",
                  icon: "",
                  navigation: false,
                  onClick: openLeave,
                },
              ]
            : [
                {
                  name: "Edit Role",
                  icon: "",
                  navigation: false,
                  onClick: onOpen,
                },
                {
                  name: "Create Department",
                  icon: "",
                  navigation: false,
                  onClick: openDept,
                },
                {
                  name: "Create Designation",
                  icon: "",
                  navigation: false,
                  onClick: openDesig,
                },
              ]
        }
      />
      <EditRole isOpen={isOpen} onClose={onClose} />
    </>
  );
}
