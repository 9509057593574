import { Flex } from "@chakra-ui/react";
import StatItem from "./StatItem";
import { useEffect, useState } from "react";
import { getBriefs, getExpenses, getIncome, getTasks } from "../../util/http";
import Loader from "../UI/Loader";
import { TfiBriefcase } from "react-icons/tfi";
import { FaTasks } from "react-icons/fa";
import {
  BsFillArrowUpCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";

export default function Stats() {
  const [activeBrief, setActiveBrief] = useState(0);
  const [pendingBrief, setPendingBrief] = useState(0);
  const [completedBrief, setCompletedBrief] = useState(0);
  const [progress, setProgress] = useState(0);
  const [tasks, setTasks] = useState(0);
  const [income, setIncome] = useState("");
  const [expenses, setExpenses] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getBriefs();
        setProgress(80);
        setProgress(100);
        // active brief
        setActiveBrief(
          response?.briefs?.filter(
            (el) => el.status?.toLowerCase() === "active"
          )?.length
        );

        // pending brief
        setPendingBrief(
          response?.briefs?.filter(
            (el) => el.status?.toLowerCase() === "pending"
          ).length
        );

        // completed briefs
        setCompletedBrief(
          response?.briefs?.filter(
            (el) => el.status?.toLowerCase() === "completed"
          ).length
        );
      } catch (error) {
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getTasks();
        setTasks(
          response?.tasks?.filter(
            (el) => el.status?.toLowerCase() === "ongoing"
          )?.length
        );
        setProgress(80);
        setProgress(100);
      } catch (error) {
        setProgress(100);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getIncome();
        const totalIncome = response?.receipts
          ?.map((el) => +el?.amount)
          .reduce((acc, sum) => +acc + +sum, 0);
        setIncome(totalIncome);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getExpenses();
        setExpenses(response?.total_expenses);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        wrap="wrap"
        gap="1.5rem"
        align="center"
        justify="space-between"
        py="3rem"
        px="1rem"
        borderRadius="1rem"
        bg="#F7F7F7"
      >
        <StatItem
          icon={<BsFillArrowDownCircleFill color="green" fontSize={24} />}
          title="Total Income"
          amount={`₦${new Intl.NumberFormat().format(income)}.00`}
          bg="rgba(144, 238, 144, 0.4)"
        />
        <StatItem
          icon={<BsFillArrowUpCircleFill color="red" fontSize={24} />}
          title="Total Expenses"
          amount={`₦${new Intl.NumberFormat().format(expenses)}.00`}
          bg="red.100"
        />
        <StatItem
          title="Ongoing Tasks"
          icon={<FaTasks color="orange" fontSize={24} />}
          amount={tasks}
          bg="rgba(255, 213, 128, 0.4)"
        />
        <StatItem
          icon={<TfiBriefcase color="orange" fontSize={24} />}
          title="Active Briefs"
          amount={activeBrief}
          bg="rgba(255, 213, 128, 0.4)"
        />
        <StatItem
          icon={<TfiBriefcase color="#333" fontSize={24} />}
          title="Pending Briefs"
          amount={pendingBrief}
          bg="rgba(211, 211, 211, 0.4)"
        />
        <StatItem
          icon={<TfiBriefcase color="green" fontSize={24} />}
          bg="rgba(144, 238, 144, 0.4)"
          title="Completed Briefs"
          amount={completedBrief}
        />
      </Flex>
    </>
  );
}
