import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import Details from "./Details";
import ReusableNextOfKin from "../../UI/ReusableNextOfKin";
import Address from "./Address";

export default function PersonalDetail({
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setLeave,
  setSalary,
  setEmployeeId,
  setRole,
  setKinFirstName,
  setKinLastName,
  setKinEmail,
  setKinPhone,
  setKinAddress1,
  setKinAddress2,
  setKinCity,
  setKinState,
  setKinCountry,
  setKinCode,
  setImage,
  kinCountry,
  setDepartment,
  setStorage,
  role,
  setAddress1,
  setAddress2,
  setCity,
  setCode,
  setState,
  setCountry,
  country,
  setDesignation,
  setPrimaryRole,
  setSecondaryRoles,
  setPersonalEmail,
}) {
  return (
    <Flex direction="column" align="center" w="100%">
      <Flex
        align="center"
        height={{ base: "100%", lg: "900px", md: "100%" }}
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
        direction={{ lg: "row", md: "column", base: "column" }}
        gap={{ lg: "0px", base: "16px", md: "16px" }}
      >
        <Box
          w={{ lg: "30%", base: "100%", md: "100%" }}
          height={{ base: "100%", lg: "900px", md: "100%" }}
        >
          <ReusableText
            text="Personal Details"
            size="20px"
            weight="600"
            color="#19191A"
          />
        </Box>

        <Details
          setFirstName={setFirstName}
          setEmail={setEmail}
          setEmployeeId={setEmployeeId}
          setRole={setRole}
          setLastName={setLastName}
          setPhone={setPhone}
          setImage={setImage}
          setLeave={setLeave}
          setSalary={setSalary}
          setDepartment={setDepartment}
          setStorage={setStorage}
          role={role}
          setDesignation={setDesignation}
          setPrimaryRole={setPrimaryRole}
          setSecondaryRoles={setSecondaryRoles}
          setPersonalEmail={setPersonalEmail}
        />
      </Flex>
      <Address
        setAddress1={setAddress1}
        setAddress2={setAddress2}
        setCity={setCity}
        setCode={setCode}
        setCountry={setCountry}
        setState={setState}
        country={country}
      />
      <ReusableNextOfKin
        setKinAddress1={setKinAddress1}
        setKinAddress2={setKinAddress2}
        setKinCity={setKinCity}
        setKinCode={setKinCode}
        setKinCountry={setKinCountry}
        setKinEmail={setKinEmail}
        setKinFirstName={setKinFirstName}
        setKinLastName={setKinLastName}
        setKinPhone={setKinPhone}
        setKinState={setKinState}
        country={kinCountry}
      />
    </Flex>
  );
}
