import { Avatar, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineBlock } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { archiveClient, suspendClient } from "../../util/http";
import DeleteModal from "../UI/DeleteModal";

export default function ClientItem({ item }) {
  const navigate = useNavigate();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const toast = useToast();

  async function deleteClientHandler() {
    try {
      await archiveClient(item?.id);
      toast({
        title: `Successfully archived client`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function disableClientHandler() {
    try {
      await suspendClient(item?.id);
      navigate("/client");
      toast({
        title: `${item?.client_name} suspended`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  return (
    <Fragment>
      <Flex
        w="100%"
        align="center"
        px="1rem"
        py="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        borderBottom="1px solid #333"
      >
        <Flex display={{ lg: "flex", md: "none", base: "none" }} w="5%">
          <Avatar size="xs" name={item?.client_name} />
        </Flex>
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          w="10%"
          text={item?.client_id}
          align="start"
          onClick={() => navigate(`/client/${item.client_id}`)}
        />
        <ReusableText
          onClick={() => navigate(`/client/${item.client_id}`)}
          w="15%"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          text={item?.client_name?.slice(0, 15)}
          transform={true}
        />
        <ReusableText
          cursor="pointer"
          w="25%"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          text={item?.email?.slice(0, 25)}
          title={item?.email}
        />
        <ReusableText
          onClick={() => navigate(`/client/${item.client_id}`)}
          w="20%"
          text={item?.phone_number}
          size={{ lg: "14px", md: "12px", base: "6px" }}
        />
        <ReusableText
          onClick={() => navigate(`/client/${item.client_id}`)}
          w="15%"
          transform={true}
          text={item?.business_type}
          size={{ lg: "14px", md: "12px", base: "6px" }}
        />
        <Flex
          fontSize={{ lg: "14px", md: "12px", base: "12px" }}
          align="center"
          w="10%"
          justify={{
            lg: "space-evenly",
            md: "space-between",
            base: "space-between",
          }}
          gap={{ lg: "0rem", md: "8px", base: "8px" }}
        >
          <button
            size="sm"
            fontSize={{ lg: "14px", md: "12px", base: "6px" }}
            onClick={() => navigate(`/client/${item.client_id}`)}
          >
            <AiOutlineEye />
          </button>
          <button
            size="sm"
            fontSize={{ lg: "14px", md: "12px", base: "6px" }}
            onClick={disableClientHandler}
            disabled={item?.is_banned !== "0"}
          >
            <MdOutlineBlock />
          </button>
          <button
            size="sm"
            fontSize={{ lg: "14px", md: "12px", base: "6px" }}
            onClick={onOpen}
            disabled={item?.deleted_at !== null}
          >
            <AiOutlineDelete />
          </button>
        </Flex>
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClick={deleteClientHandler}
        onClose={onClose}
        title="Archive Client?"
        text={`Are you sure you want to delete ${item?.client_name}`}
        show={false}
      />
    </Fragment>
  );
}
