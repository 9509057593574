import React from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";

import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { updateEmployee } from "../../../util/http";
import { useToast } from "@chakra-ui/react";

export default function ViewEmployeeHeader({
  id,
  employee,
  show,
  showSalaryMenu,
  showUpdate,
  open,
}) {
  const navigate = useNavigate();
  const toast = useToast();

  function showSalary() {
    showSalaryMenu(true);
  }

  function updateSalary() {
    showUpdate(true);
  }

  function addUpdate() {
    open();
  }

  async function unArchiveHandler() {
    try {
      await updateEmployee(id, { deleted_at: null, is_banned: "0" });
      toast({
        title: `Successfully`,
        description: `You have successfully un-archived ${employee?.first_name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/employees");
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function unSuspendHandler() {
    try {
      await updateEmployee(id, { is_banned: "0" });
      toast({
        title: `Successfully un-suspended ${employee?.first_name}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/employees");
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableEmployeeHeader
      show={true}
      title="Employee"
      text="Manage your employees and their details here"
      btnText="Edit"
      icon={<FiEdit2 />}
      onClick={() => show(true)}
      showDottedMenu={true}
      menu={
        employee?.is_banned !== "0" && employee?.deleted_at == null
          ? [
              {
                name: "Un-suspend",
                icon: "",
                navigation: false,
                onClick: unSuspendHandler,
              },
              {
                name: "Salary Report",
                icon: "",
                navigation: false,
                onClick: showSalary,
              },
              {
                name: "Update Salary",
                icon: "",
                navigation: false,
                onClick: updateSalary,
              },
              {
                name: "Add Update",
                icon: "",
                navigation: false,
                onClick: addUpdate,
              },
            ]
          : employee?.is_banned !== "0" && employee?.deleted_at != null
          ? [
              {
                name: "Un-suspend",
                icon: "",
                navigation: false,
                onClick: unSuspendHandler,
              },
              {
                name: "Un-Archive",
                icon: "",
                navigation: false,
                onClick: unArchiveHandler,
              },
              {
                name: "Salary Report",
                icon: "",
                navigation: false,
                onClick: showSalary,
              },
              {
                name: "Update Salary",
                icon: "",
                navigation: false,
                onClick: updateSalary,
              },
              {
                name: "Add Update",
                icon: "",
                navigation: false,
                onClick: addUpdate,
              },
            ]
          : employee?.is_banned === "0" && employee?.deleted_at != null
          ? [
              {
                name: "Un-Archive",
                icon: "",
                navigation: false,
                onClick: unArchiveHandler,
              },
              {
                name: "Salary Report",
                icon: "",
                navigation: false,
                onClick: showSalary,
              },
              {
                name: "Update Salary",
                icon: "",
                navigation: false,
                onClick: updateSalary,
              },
              {
                name: "Add Update",
                icon: "",
                navigation: false,
                onClick: addUpdate,
              },
            ]
          : [
              {
                name: "Salary Report",
                icon: "",
                navigation: false,
                onClick: showSalary,
              },
              {
                name: "Update Salary",
                icon: "",
                navigation: false,
                onClick: updateSalary,
              },
              {
                name: "Add Update",
                icon: "",
                navigation: false,
                onClick: addUpdate,
              },
            ]
      }
    />
  );
}
