import React, { Fragment, useState } from "react";
import AuthComponent from "../UI/AuthComponent";
import { Flex, Heading, Text, useToast } from "@chakra-ui/react";
import PasswordInput from "../UI/PasswordInput";
import ButtonComponent from "../UI/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { resetPassword } from "../../util/http";
import Loader from "../UI/Loader";

export default function NewPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const toast = useToast();

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  const token = getQueryVariable("token");

  async function resetHandler() {
    const email = localStorage.getItem("reset-email");

    if (!email) {
      toast({
        title: `Error`,
        description: `email cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!password) {
      toast({
        title: `Error`,
        description: `password cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!passwordConfirm && password !== passwordConfirm) {
      toast({
        title: `Error`,
        description: `password do not match`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const data = {
      email,
      password,
      password_confirmation: passwordConfirm,
      reset_token: token,
    };

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await resetPassword(data);
      setProgress(80);
      setProgress(100);
      setLoading(false);
      navigate("/password-reset-success");
      toast({
        title: `Password reset successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      setProgress(100);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent>
        <Heading
          textAlign="center"
          color="#3F434A"
          fontSize="24px"
          fontWeight="medium"
        >
          Set New Password
        </Heading>
        <Text
          fontSize="14px"
          color="#949493"
          fontWeight="light"
          textAlign="center"
        >
          Your new password must be different to previously used passwords.
        </Text>
        <PasswordInput
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
        />
        <PasswordInput
          onChange={(e) => setPasswordConfirm(e.target.value)}
          label="Confirm Password"
        />
        <ButtonComponent
          onClick={resetHandler}
          title="Reset Password"
          width="100%"
          loading={loading}
        />
        <Flex align="center" justify="center" gap="0.5rem">
          <ArrowBackIcon color="#2BA2DA" />
          <Link
            style={{
              color: "#2BA2DA",
              fontWeight: "medium",
            }}
            to="/login"
          >
            Back to log in
          </Link>
        </Flex>
      </AuthComponent>
    </Fragment>
  );
}
