import React, { Fragment, useState } from "react";
import AuthComponent from "../UI/AuthComponent";
import { Text, Heading, Flex, useToast } from "@chakra-ui/react";
import InputComponent from "../UI/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../UI/Button";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { forgotPassword } from "../../util/http";
import Loader from "../UI/Loader";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  async function forgotHandler() {
    const data = { email };

    if (!email) {
      toast({
        title: `Error`,
        description: `email cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await forgotPassword(data);
      setProgress(80);
      setProgress(100);
      localStorage.setItem("reset-email", email);
      navigate("/check-email");
      toast({
        title: `Password reset email sent`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setProgress(100);
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent>
        <Heading
          textAlign="center"
          color="#3F434A"
          fontSize="24px"
          fontWeight="medium"
        >
          Forgot Password
        </Heading>
        <Text
          fontSize="14px"
          color="#949493"
          fontWeight="light"
          textAlign="center"
        >
          No worries, we’ll send your reset instructions.
        </Text>
        <InputComponent
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          placeholder="o.noah@loya.com"
          type="email"
        />
        <ButtonComponent
          onClick={forgotHandler}
          title="Reset Password"
          width="100%"
          loading={loading}
        />
        <Flex align="center" justify="center" gap="0.5rem">
          <ArrowBackIcon color="#2BA2DA" />
          <Link
            style={{
              color: "#2BA2DA",
              fontWeight: "medium",
            }}
            to="/login"
          >
            Back to log in
          </Link>
        </Flex>
      </AuthComponent>
    </Fragment>
  );
}
