import { NavLink } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import ImageComponent from "./Image";

export default function NavItem({ src, alt, height, link, title }) {
  return (
    <NavLink to={`/${link}`} className="menu">
      <Box w="16px">
        <ImageComponent src={src} alt={alt} height={height} />
      </Box>
      {title}
    </NavLink>
  );
}
