import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from "@chakra-ui/react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import CustomList from "./CustomList";
import AppraisalSubheader from "./AppraisalSubheader";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function AppraisalContainer() {
  const { user } = useContext(AuthContext);

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <ReusableEmployeeHeader
        title="Appraisals"
        text="Manage your appraisals and their details here"
      />
      <Tabs w="100%" variant="solid-rounded">
        <TabList p="0.2rem" borderRadius="8px" w="100%" bg="#E8E9EB">
          <Tab
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
          >
            EMPLOYEE
          </Tab>

          <Tab
            // isDisabled={
            //   !user?.job_role?.toLowerCase().includes("hod") ||
            //   user?.job_role?.toLowerCase() === "seed"
            // }
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
          >
            HOD
          </Tab>

          <Tab
            // isDisabled={
            //   user?.job_role?.toLowerCase() !== "hr" ||
            //   user?.job_role?.toLowerCase() === "seed"
            // }
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
          >
            HR
          </Tab>
          <Tab
            // isDisabled={
            //   user?.job_role?.toLowerCase() !== "hr" ||
            //   user?.job_role?.toLowerCase() === "seed"
            // }
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
          >
            MANAGEMENT
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader tab="employee" />
              <CustomList />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader tab="hod" />
              <CustomList />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader tab="hr" />
              <CustomList />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader tab="seed" />
              <CustomList />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
