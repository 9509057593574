import { Flex, useDisclosure } from "@chakra-ui/react";
import ViewEmployeeHeader from "./ViewEmployeeHeader";
import ViewEmployeeDetails from "./ViewEmployeeDetails";
import EditEmployee from "./EditEmployee";
import { useState } from "react";
import LoadingSkeleton from "../../UI/LoadingSkeleton";
import SalaryProgress from "./SalaryProgress";
import UpdateSalary from "./UpdateSalary";
import AddUpdate from "../../profile/updates/AddUpdate";

export default function ViewEmployeeContainer({ employee, loading }) {
  const [show, setShow] = useState(false);
  const [showSalary, setShowSalary] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex
      w="100%"
      align="center"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <AddUpdate id={employee?.id} isOpen={isOpen} onClose={onClose} />
      <ViewEmployeeHeader
        show={setShow}
        id={employee?.id}
        employee={employee}
        showSalaryMenu={setShowSalary}
        showUpdate={setShowUpdate}
        open={onOpen}
      />
      {showUpdate && (
        <UpdateSalary
          name={`${employee?.first_name} ${employee?.last_name}`}
          salary={employee?.salary}
          show={setShowUpdate}
          id={employee?.id}
        />
      )}
      {showSalary && (
        <SalaryProgress
          name={`${employee?.first_name} ${employee?.last_name}`}
          id={employee?.id}
          show={setShowSalary}
          designation={employee?.designation}
          department={employee?.department}
        />
      )}
      {loading ? (
        <LoadingSkeleton lines={20} />
      ) : (
        <ViewEmployeeDetails employee={employee} />
      )}
      {show && <EditEmployee employee={employee} show={setShow} />}
    </Flex>
  );
}
