import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ReusableText from "../UI/ReusableText";
import UserCan from "./UserCan";
import UserCannot from "./UserCannot";

export default function Permission({ permitted, notPermitted, users }) {
  return (
    <>
      <Flex
        maxHeight="600px"
        overflow="scroll"
        align="start"
        direction="column"
        gap="1rem"
        w="80%"
      >
        <Flex align="start" direction="column" gap="1rem">
          <ReusableText
            text="Permissions"
            size="20px"
            color="#19191A"
            weight="bold"
          />{" "}
          <ReusableText
            text="This role grants users the permissions to manage everything on the dashboard except to move money out"
            size="14px"
            color="#19191A"
            weight="medium"
          />
        </Flex>

        <Flex align="center" gap="1rem">
          {users?.length > 0 && (
            <ReusableText
              text={`Users with this role (${users?.length}):`}
              color="#667085"
              size="14px"
              width="medium"
            />
          )}
          {users?.map((el) => (
            <ReusableText
              text={`${el?.first_name} ${el?.last_name}`}
              color="#667085"
              size="14px"
              width="medium"
            />
          ))}
        </Flex>
        <Flex w="100%" gap="1rem" align="center" justify="space-between">
          <UserCan permitted={permitted} />
          <UserCannot notPermitted={notPermitted} />
        </Flex>
      </Flex>
    </>
  );
}
