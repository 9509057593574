import { Flex } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import DocumentDetailCenter from "./DocumentDetailCenter";

export default function DocumentDetailContainer({ list }) {
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      h="100%"
      gap="1rem"
      p="2rem"
    >
      <ReusableEmployeeHeader
        title="Documents"
        text="Manage your documents and files here"
      />
      <DocumentDetailCenter list={list} />
    </Flex>
  );
}
