import { useEffect, useState } from "react";
import Employee from "./Employee";
import HRStat from "./HRStat";
import { getEmployees } from "../../../../util/http";
import Loader from "../../../UI/Loader";

export default function HR() {
  const [progress, setProgress] = useState(0);
  const [list, setList] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getEmployees();
        setProgress(80);
        setProgress(100);
        setList(response?.employees);
      } catch (error) {
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <HRStat employees={list} />
      <Employee list={list?.slice(0, 6)} />
    </>
  );
}
