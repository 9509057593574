import { Flex, Text, Heading, useToast } from "@chakra-ui/react";
import React, { Fragment, useState } from "react";

import InputComponent from "../UI/Input";
import PasswordInput from "../UI/PasswordInput";
import ButtonComponent from "../UI/Button";
import { Link, useNavigate } from "react-router-dom";
import AuthComponent from "../UI/AuthComponent";
import { completeSetup } from "../../util/http";
import Loader from "../UI/Loader";

export default function CompleteSignup() {
  const toast = useToast();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  const token = getQueryVariable("token");
  const queryEmail = getQueryVariable("email");

  async function setupHandler() {
    const data = {
      email: queryEmail,
      password,
      password_confirmation: passwordConfirm,
      token,
    };

    if (!queryEmail) {
      toast({
        title: `Error`,
        description: `email cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!password) {
      toast({
        title: `Error`,
        description: `password cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!passwordConfirm) {
      toast({
        title: `Error`,
        description: `password confirm cannot be empty`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (password !== passwordConfirm) {
      toast({
        title: `Error`,
        description: `password do not match`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await completeSetup(data);
      toast({
        title: `Account set-up complete, Procees to login`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/login");
      setProgress(80);
      setProgress(100);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setProgress(100);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent>
        <Heading
          textAlign="center"
          color="#3F434A"
          fontSize="24px"
          fontWeight="medium"
        >
          Complete Your Account Setup
        </Heading>
        <Text
          fontSize="14px"
          color="#949493"
          fontWeight="light"
          textAlign="center"
        >
          Complete the form below to create your Loya account.
        </Text>
        <InputComponent
          readOnly={true}
          label="Email"
          placeholder="o.noah@loya.com"
          type="email"
          // onChange={(e) => setEmail(e.target.value)}
          value={queryEmail}
        />
        <PasswordInput
          onChange={(e) => setPassword(e.target.value)}
          label="Create Password"
        />
        <PasswordInput
          onChange={(e) => setPasswordConfirm(e.target.value)}
          label="Confirm Password"
        />

        <ButtonComponent
          onClick={setupHandler}
          title="Complete Setup"
          width="100%"
          loading={loading}
        />
        <Flex mt="2rem" align="center" justify="center" gap="0.7rem">
          <Text color="#2E4A49" fontSize="16px" fontWeight="normal">
            Already have an account?
          </Text>
          <Link style={{ color: "#364273", fontWeight: "medium" }} to="/login">
            Log In
          </Link>
        </Flex>
      </AuthComponent>
    </Fragment>
  );
}
