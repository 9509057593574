import { Box, Flex, Button } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import { BiFile } from "react-icons/bi";
import { useEffect } from "react";

export default function PreviewDocument({ files, setFiles = () => {} }) {
  function handleDelete(i) {
    const deleteVal = [...files];
    deleteVal.splice(i, 1);
    setFiles(deleteVal);
  }
  return (
    <Flex align="center" w="100%" gap="1rem" wrap="wrap">
      {files?.map((el, i) => (
        <>
          <Flex
            key={i}
            align="center"
            position="relative"
            direction="column"
            gap="0.2rem"
            p="1rem"
          >
            <Box w="24.8px">
              <BiFile fontSize={24} />
            </Box>
            <ReusableText
              text={el?.name?.slice(0, 10)}
              size="14px"
              weight="light"
              color="#3F434A"
              align="center"
            />
            <ReusableText
              text={el?.size}
              size="14px"
              weight="light"
              color="#3F434A"
              align="center"
            />
            <Button
              onClick={() => {
                handleDelete(i);
              }}
              position="absolute"
              top="-4"
              colorScheme="red"
              variant="ghost"
              right="0"
              p="0.2rem"
            >
              X
            </Button>
          </Flex>
        </>
      ))}
    </Flex>
  );
}
