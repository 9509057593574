import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function Professional({ setProPersonalDevelopment }) {
  const [response, setResponse] = useState({
    keepsAbreast: 0,
    personalDevelopment: 0,
    concludes: 0,
    reflects: 0,
    reacts: 0,
  });

  useEffect(() => {
    setProPersonalDevelopment(() => [response]);
  }, [response]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" height="270px" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text="PROFESSIONAL & PERSONAL DEVELOPMENT (5%)"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          height="270px"
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex height="270px" w="90%" align="start" direction="column">
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, keepsAbreast: e.target.value })
            }
            title="Keeps abreast of new developments and major issues 
            in the field"
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, personalDevelopment: e.target.value })
            }
            title="Has undergone personal development relating to 
            career since last review
            "
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, concludes: e.target.value })
            }
            title="Concludes assigned training in record time and submits 
            reports as required
            "
          />
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, reflects: e.target.value })
            }
            title="Reflects newly acquired knowledge and skills in service 
            delivery
            "
          />{" "}
          <ReusableForm
            onChange={(e) =>
              setResponse({ ...response, reacts: e.target.value })
            }
            title="Reacts positively to criticism"
          />
        </Flex>
      </Flex>
      <Flex w="100%" height="40px" align="center">
        <ReusableText
          text="Total"
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
          height="40px"
        />
        <Flex w="90%" height="40px" border="1px solid #ccc"></Flex>
      </Flex>
    </Flex>
  );
}
