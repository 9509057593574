import { useEffect } from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import DocumentDetailContainer from "./DocumentDetailContainer";
import { useState } from "react";
import { getFolderFiles, sharedDocuments } from "../../../util/http";
import { useParams } from "react-router-dom";

export default function DocumentDetail() {
  const [list, setList] = useState([]);
  const params = useParams();
  useEffect(() => {
    async function fetchData() {
      try {
        let response;
        if (params.id === "shared-documents") {
          response = await sharedDocuments();
          setList(response?.folders);
        } else {
          response = await getFolderFiles({ folder: params.id });
          setList(response?.files);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [params]);

  return (
    <DashboardReusable>
      <DocumentDetailContainer list={list} />
    </DashboardReusable>
  );
}
