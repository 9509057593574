import { Flex } from "@chakra-ui/react";
import ClientHeader from "./ClientHeader";
import ClientSubheader from "./ClientSubheader";
import ClientTitle from "./ClientTitle";
import ClientList from "./ClientList";
import ClientFooter from "./ClientFooter";

export default function ClientContainer({
  onOpen,
  list,
  setList,
  setProgress,
  progress,
  total,
  perPage,
  setCurrentPage,
  currentPage,
  loading,
}) {
  return (
    <Flex
      w="100%"
      align="center"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <ClientHeader />
      <ClientSubheader
        setList={setList}
        setProgress={setProgress}
        progress={progress}
      />
      <ClientTitle />
      <ClientList list={list} loading={loading} onOpen={onOpen} />
      <ClientFooter
        total={total}
        perPage={perPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </Flex>
  );
}
